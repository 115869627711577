<template>
<div class="col-lg-8">
                            <div class="card">
                                <div class="card-body">
                                    <div class="service-header">
                                        <div class="service-inner">
                                            <h2>Car Repair Services</h2>
                                            <address class="service-location"><i class="fas fa-location-arrow"></i> Hanover, Maryland</address>
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star"></i>
                                                <span class="d-inline-block average-rating">(4)</span>
                                            </div>
                                            <div class="service-cate">
                                                <a href="javascript:void(0);">Automobile</a>
                                            </div>
                                        </div>
                                        <div class="service-amount">
                                            <span>$150</span>
                                        </div>
                                    </div>
                                    <div class="service-description">
                                        <div class="service-images service-carousel">
                                            <div class="images-carousel service-images testimonials-slider slider">
                                                <div class="item">
                                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                                </div>
                                                <div class="item">
                                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                                </div>
                                                <div class="item">
                                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <h5 class="card-title">Service Details</h5>
                                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>