<template>
  <div>

    <div class="row" v-if="hotels && hotels.success == false">
      <p class="alert alert-danger">
        {{ hotels.error.message }}
      </p>
    </div>

    <div class="search-results row" v-if="hotelDetails">
      <div class="col-sm-12" v-for="item in hotelDetails.data" :key="item.id">
        <div class="service-widget search-results__hotel">
          <router-link :to="{name: 'hotel', params: {id: item.id}}" class="search-results__hotel-image">
            <div class="service-img"
                 :style="`background: url( ${item.details.image ? item.details.image[0].url : '/images/service-01.jpg' });`">

              <div class="fav-btn">
                <a href="javascript:void(0)" class="fav-icon">
                  <i class="fas fa-heart"></i>
                </a>
              </div>
              <div class="item-info">
                <div class="service-user">
                  <a href="javascript:void(0);">
                    <!--														<img :src="loadImg1(item.img1)" alt="">-->
                  </a>
                </div>
                <div class="cate-list">
                  <router-link class="bg-yellow" to="/service-details">Premium</router-link>
                </div>
              </div>
            </div>
          </router-link>
          <div class="service-content search-results__hotel-details">

            <div class="search-results__hotel-details-top">
              <h3 class="title">
                <router-link :to="{name: 'hotel', params: {id: item.id}}">{{ item.details.name }}</router-link>
              </h3>
              <div class="rating mapgridrating1" v-if="item.rating && item.rating > 0">
                <i class="fas fa-star" :class="{ 'filled': item.details.starRating > 0 }"></i>
                <i class="fas fa-star" :class="{ 'filled': item.details.starRating > 1 }"></i>
                <i class="fas fa-star" :class="{ 'filled': item.details.starRating > 2 }"></i>
                <i class="fas fa-star" :class="{ 'filled': item.details.starRating > 3 }"></i>
                <i class="fas fa-star" :class="{ 'filled': item.details.starRating > 4 }"></i>
              </div>
              <div class="user-info">
                <div class="row">
													<span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i>
														<span>{{ item.details.phone }}</span>
													</span>
                  <span class="col ser-location">
														<span>{{ item.details.address.city }}, {{ item.details.address.country }}</span> <i
                      class="fas fa-map-marker-alt ms-1"></i>
													</span>
                </div>
              </div>
            </div>

            <div class="search-results__hotel-details-bottom">
              <!--                        <strong v-if="item.prices">{{ item.prices[0] }} zł</strong>-->
            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="pagination" v-if="hotels">
      <a href="#">&laquo;</a>
      <a href="#">{{ hotels.hotel.length }}</a>
      <a href="#" class="active">2</a>
      <a href="#">3</a>
      <a href="#">4</a>
      <a href="#">5</a>
      <a href="#">6</a>
      <a href="#">&raquo;</a>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from "axios";

export default {
  computed: {
    ...mapGetters({
      hotels: 'hotels/hotels',
      hotelDetails: 'hotels/hotelDetails',
    }),

    pages() {
      if (this.hotels && this.hotels.length > 10) {
        return Math.ceil(this.hotels.length / 10);
      }
      return 0;
    }
  },

  data() {
    return {
      loading: false,
      hotels2: null,
    }
  },

  mounted() {
    console.log();
    if (this.hotelDetails) {
      console.log(this.hotelDetails);
    }
  },

  methods: {},
}
</script>
