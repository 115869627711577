<template>
	<div>
		<div class="main-wrapper">
			<headertranslation></headertranslation>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
			<div class="content container-fluid">

				<!-- Page Header -->
				<div class="page-header">
					<div class="row">
						<div class="col">
							<h3 class="page-title">Translation - <span class="text-primary">English</span></h3>
						</div>
					</div>
				</div>
				<!-- /Page Header -->

				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="table-responsive">
									<table class="table custom-table mb-0 datatable" id="translation">
										<thead>
											<tr>
												<th>#</th>
												<th width="45%">Key</th>
												<th width="45%">Value</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>1</td>
												<td>Distance</td>
												<td>
													<input type="text" class="form-control" name="values[distance]" value="Distance">
												</td>
											</tr>
											<tr>
												<td>2</td>
												<td class="key">Close</td>
												<td>
													<input type="text" class="form-control" name="values[close]" value="Close">
												</td>
											</tr>
											<tr>
												<td>3</td>
												<td class="key">Auction Products Orders</td>
												<td>
													<input type="text" class="form-control" name="values[auction_products_orders]" value="Auction Products Orders">
												</td>
											</tr>
											<tr>
												<td>4</td>
												<td class="key">Reset Email ID</td>
												<td>
													<input type="text" class="form-control" name="values[reset_email]" value="Reset Email ID">
												</td>
											</tr>
											<tr>
												<td>5</td>
												<td class="key">Clear Location</td>
												<td>
													<input type="text" class="form-control" name="values[clear_location]" value="Clear Location">
												</td>
											</tr>
											<tr>
												<td>6</td>
												<td class="key">Set Location</td>
												<td>
													<input type="text" class="form-control" name="values[set_location]" value="Set Location">
												</td>
											</tr>
											<tr>
												<td>7</td>
												<td class="key">No Categories Found</td>
												<td>
													<input type="text" class="form-control" name="values[no_categories_found]" value="No Categories Found">
												</td>
											</tr>
											<tr>
												<td>8</td>
												<td class="key">Service Gallery</td>
												<td>
													<input type="text" class="form-control" name="values[service_gallery]" value="Service Gallery">
												</td>
											</tr>
											<tr>
												<td>9</td>
												<td class="key">Descriptions</td>
												<td>
													<input type="text" class="form-control" name="values[descriptions]" value="Descriptions">
												</td>
											</tr>
											<tr>
												<td>10</td>
												<td class="key">Details Information</td>
												<td>
													<input type="text" class="form-control" name="values[details_information]" value="Details Information">
												</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</diV>
	</div>
</template>
<script>
    import util from '../../../assets/utils/util'
	export default {
	mounted() {
        util.datatable('#translation')
	}
	}
	</script>
