<template>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <serviceview></serviceview>
                    <h4 class="card-title">Related Services</h4>
                    <popularservice></popularservice>
                </div>
                <servicesidebar></servicesidebar>
            </div>
        </div>
    </div>
	
	<layout-footer></layout-footer>

	</div>

</template>