<template>
    <!-- Popular Servides -->
		<section class="popular-services">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">

            <div class="row mb-5">

              <div class="col-md-7">
                <div class="viewall aos aos-init aos-animate" data-aos="fade-up">

                  <span class="double-color">Polecane hotele</span><br>
                  <p class="pt-2 pb-2">Zatrzymaj się w naszych wyjątkowych obiektach</p>
                </div>
              </div>
            </div>

						<div class="row service-carouselindex slider" v-if="hotelDetails">
							<div class="service-slider aos col-sm-4" data-aos="fade-up" v-for="(item, index) in hotelDetails" :key="index">
								<div class="service-widget">
									<div class="service-img" style="max-height: 240px;">
										<router-link :to="`/hotel-presentation/${item.hotel.id}`">
											<img class="img-fluid serv-img" alt="Service Image" :src="item.hotel.image[0].url">
                                        </router-link>
<!--										<div class="fav-btn">-->
<!--											<a href="javascript:void(0)" class="fav-icon">-->
<!--												<i class="fas fa-heart"></i>-->
<!--											</a>-->
<!--										</div>-->
										<div class="item-info">
											<div class="service-user d-none">
												<span class="service-price">100 zł</span>
											</div>
											<div class="cate-list">
												<router-link class="bg-yellow" to="#">{{item.hotel.name}}</router-link>
											</div>
										</div>
									</div>
									<div class="service-content">
										<h3 class="title">
											<router-link :to="`/hotel-presentation/${item.hotel.id}`">{{item.hotel.name }}</router-link>
										</h3>
										<div class="rating mapgridrating1">
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star"></i>
										</div>
										<div class="user-info">
											<div class="row">
												<span class="col ser-location">
													<span>{{item.hotel.city.name}}</span> <i class="fas fa-map-marker-alt ms-1"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Popular Servides -->
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    import axios from 'axios';
    import popular from '../../../../../assets/json/website/home/popular.json'
	const images = require.context('../../../../../assets/img/featured-hotels', false, /\.png$|\.jpg$/)
	export default {

        components: {
            Loading,
        },

        data() {
            return {
                popular: popular,
                hotelDetails: null,
            }
        },

        methods:{
            loadImg(imgPath) {
                return images('./' + imgPath).default
            },
            loadImg1(img1Path) {
                return images1('./' + img1Path).default
            },

            getHotelDetails() {
                axios.get('/api/settings/render-popular-hotels')
                    .then(res => {
                        this.hotelDetails = res.data;
                        console.log(this.hotelDetails);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
        },

        mounted() {
            this.getHotelDetails();

            if($('.service-carouselindex').length > 0) {
                $('.service-carouselindex').slick({
                    dots: true,
                    autoplay:false,
                    infinite: true,
                    prevArrow: false,
                    nextArrow: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                    ]
                });
            }
        },
	}
</script>
