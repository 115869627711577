<template>
<!-- /our app -->
<section class="app-set">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-12">
                <div class="col-md-12">
                    <div class="heading aos" data-aos="fade-up">
                        <h2>Download Our App</h2>
                        <span>Aliquam lorem ante, dapibus in, viverra quis</span>
                    </div>
                </div>
                <div class="downlaod-set aos" data-aos="fade-up">
                    <ul>
                        <li>
                            <a href="javascript:void(0);"><img src="/images/gp.png" alt="img"></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);"><img src="/images/ap.png" alt="img"></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="appimg-set aos" data-aos="fade-up">
                    <img src="/images/app.png" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /our app -->
</template>
