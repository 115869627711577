<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Download Section</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="download" class="check" type="checkbox" checked="">
                                                        <label for="download" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <div class="form-group">
                                                        <p class="settings-label">App Store (iOs)</p>
                                                        <div class="settings-btn">
                                                            <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                            <label for="file" class="upload">
                                                                <i class="fa fa-upload"></i>
                                                            </label>
                                                        </div>
                                                        <div class="upload-images ">
                                                            <img src="/images/app1.png" class="my-4 w-auto" alt="Image">
                                                            <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                                <i class="fa fa-times"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <div class="form-group">
                                                        <label>App Link</label>
                                                        <input type="text" class="form-control" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <div class="form-group">
                                                        <p class="settings-label">Google Play Store</p>
                                                        <div class="settings-btn">
                                                            <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                            <label for="file" class="upload">
                                                                <i class="fa fa-upload"></i>
                                                            </label>
                                                        </div>
                                                        <div class="upload-images ">
                                                            <img src="/images/googlepay.png" class="my-4 w-auto" alt="Image">
                                                            <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                                <i class="fa fa-times"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <div class="form-group">
                                                        <label>App Link</label>
                                                        <input type="text" class="form-control" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
</template>
