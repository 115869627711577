<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <!-- Breadcrumb -->
        <!--		<breadcrumb5></breadcrumb5>-->
        <!-- /Breadcrumb -->

        <div class="content">
            <div class="container">

                <div class="row" v-if="offerGenerateView">
                    <div class="col-lg-12">

                        <a href="#" class="mt-3 mb-3" @click.prevent="toggleOfferGenerateView"><i class="fas fa-arrow-circle-left"></i> Powrót</a>

                        <div class="search-results row d-flex flex-column" ref="download" style="max-width: 700px;margin: 0 auto;" v-if="hotelDetails">
                            <div class="col-sm-12">
                                <h2>Wybrane oferty:</h2>
                                <ul>
                                    <li>Data przyjazdu: <strong>{{ payload.checkin }}</strong></li>
                                    <li>Data wyjazdu: <strong>{{ payload.checkout }}</strong></li>
                                    <li>Liczba nocy: <strong>{{ noOfDays }}</strong></li>
                                    <li>Dorośli: <strong>{{ payload.adults }}</strong></li>
                                    <li>Dzieci: <strong>{{ payload.children }}</strong></li>
                                </ul>
                                <hr>
                            </div>

                            <div
                                v-for="(item, index) in filteredHotelDetails"
                                :key="item.id"
                                class="col-sm-12 d-flex pb-3 mb-3"
                                style="border-bottom: 1px solid #e1e1e1"
                            >
                                <div
                                    class="search-results__hotel w-100"
                                >
                                    <div class="service-content search-results__hotel-details">

                                        <div class="search-results__hotel-details-top">
                                            <h3 class="title">
                                                <div >
                                                    {{ item.details.name }}
                                                </div>
                                            </h3>
                                            <div class="row px-3">

                                                <div class="col-sm-12 px-0 mb-2 user-info">
                                                    <div class="row">
                                                      <span class="col ser-location">
                                                        <i class="fas fa-map-marker-alt ms-1 mr-2"></i>
                                                        <span>{{ item.details.address.city }}, {{ item.details.address.country }}</span>
                                                      </span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 rating mapgridrating1"
                                                     v-if="item.details.starRating && item.details.starRating > 0">
                                                    <i class="fas fa-star"
                                                       :class="{ 'filled': item.details.starRating > 0 }"></i>
                                                    <i class="fas fa-star"
                                                       :class="{ 'filled': item.details.starRating > 1 }"></i>
                                                    <i class="fas fa-star"
                                                       :class="{ 'filled': item.details.starRating > 2 }"></i>
                                                    <i class="fas fa-star"
                                                       :class="{ 'filled': item.details.starRating > 3 }"></i>
                                                    <i class="fas fa-star"
                                                       :class="{ 'filled': item.details.starRating > 4 }"></i>
                                                </div>

                                            </div>

                                        </div>

                                        <div class="search-results__hotel-details-middle">
                                            <div class="w-100" v-if="item.channel.room[0]" v-for="room in item.channel.room" :key="room.id">
                                                <p class="search-results__hotel-details-middle__room  mb-3" v-if="offersToGenerate[item.id] && roomsToGenerate[item.id][room.id]">
                                                    <span>
                                                        Pokój: {{ room.roomType.name }} | Wyżywienie: {{ room.boardType.name }}<br>
                                                        Cena za noc: <strong>{{ (room.price * rate).toFixed(2) }}</strong> PLN<br>
                                                        Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(room.cancellationPolicy.cxlDate) }}</strong>
                                                    </span>
                                                </p>
                                            </div>

                                            <div class="w-100 mb-3" v-else>
                                                <p class="search-results__hotel-details-middle__room  mb-3" v-if="offersToGenerate[item.id] && roomsToGenerate[item.id][item.channel.room.id]">
                                                    <span>
                                                        Pokój: {{ item.channel.room.roomType.name }} | Wyżywienie: {{ item.channel.room.boardType.name }}<br>
                                                        Cena za noc: <strong>{{ (item.channel.room.price * 1.10 * rate).toFixed(2)  }}</strong> PLN<br>
                                                        Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(item.channel.roo.cancellationPolicy.cxlDate) }}</strong>
                                                    </span>
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="mt-5">
                            <button class="btn btn-primary pl-5 pr-5 btn-block get_services w-100" @click="download">Generuj ofertę PDF</button>
                        </div>


                    </div>
                </div>

                <div class="row" v-else>


                    <div class="col-lg-3 theiaStickySidebar">
                        <div class="stickyside">
                            <div class="card filter-card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Wyszukaj hotel</h4>
                                    <hr>
                                    <form id="search_form">
                                        <div class="filter-widget">

                                            <div class="filter-list">
                                                <h4 class="filter-title">Pobyt:</h4>

                                                <VueDatePicker
                                                    v-model="date"
                                                    :min-date="new Date()"
                                                    :clearable="false"
                                                    model-type="yyyy-MM-dd"
                                                    format="dd MMM"
                                                    range
                                                    auto-apply
                                                    multi-calendars
                                                    :format-locale="lang"
                                                    :enable-time-picker="false"
                                                ></VueDatePicker>

                                            </div>

                                            <div class="filter-list" v-show="false">
                                                <h4 class="filter-title">Przyjazd</h4>
                                                <input type="date" v-model="payload.checkin" class="form-control"
                                                       placeholder="Checkin">
                                            </div>
                                            <div class="filter-list" v-show="false">
                                                <h4 class="filter-title">Wyjazd</h4>
                                                <input type="date" v-model="payload.checkout" class="form-control"
                                                       placeholder="Checkout">
                                            </div>

                                            <div class="filter-list" v-if="countries" v-show="false">
                                                <h4 class="filter-title">Kraj</h4>
                                                <select v-model="country" @change="selectCountry"
                                                        class="form-control selectbox select form-select">
                                                    <option value="0" selected>-- Wybierz --</option>
                                                    <option value="169">Polska</option>
                                                    <option v-for="country in countries" disabled :value="country.id">
                                                        {{ country.name }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="filter-list">
                                                <h4 class="filter-title">Miasto</h4>
                                                <simple-typeahead
                                                    id="typeahead_id"
                                                    placeholder="Dokąd jedziesz?"
                                                    :items="searchItems"
                                                    class="form-control"
                                                    :minInputLength="1"
                                                    :itemProjection="itemProjectionFunction"
                                                    @selectItem="selectItemEventHandler"
                                                >
                                                </simple-typeahead>
                                            </div>


                                            <div class="filter-list row">

                                                <div class="col-sm-12 col-md-6 pl-md-0">
                                                    <h4 class="filter-title">Dorośli</h4>
                                                    <input type="number" step="1" max="6" v-model="payload.adults"
                                                           class="form-control">
                                                </div>


                                                <div class="col-sm-12 col-md-6 pr-md-0">
                                                    <h4 class="filter-title">Dzieci</h4>
                                                    <input type="number" step="1" max="3" @change="resetChildrenAge"
                                                           v-model="payload.children"
                                                           class="form-control">
                                                </div>

                                            </div>


                                            <div class="filter-list row" v-if="payload.children > 0">

                                                <div class="col-sm-12 px-0 ">
                                                    <h4 class="filter-title">Wiek dzieci:</h4>
                                                </div>

                                                <div class="col-sm-12 col-md-6 col-lg-4 px-1 children-count"
                                                     v-for="(count, index) in payload.children" :key="index">

                                                    <input
                                                        v-model="payload.childrenAge[count - 1]"
                                                        type="number"
                                                        step="0"
                                                        max="14"
                                                        class="form-control"
                                                    >
                                                </div>

                                            </div>
                                        </div>

                                        <hr>

                                        <button class="btn btn-primary pl-5 pr-5 btn-block get_services w-100"
                                                @click.prevent="search"
                                                type="button">Szukaj
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div class="card filter-card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Generowanie ofert</h4>
                                    <hr>
                                    <div class="status-toggle d-flex">
                                        <input id="remember"  class="check me-4"
                                               type="checkbox" v-model="offerGenerator">
                                        <label for="remember" class="checktoggle">checkbox</label> <span
                                        class="ml-4"> Generowanie ofert </span>

                                    </div>

                                    <div class="mt-4 mb-3" v-if="offerGenerator">
                                        <p class="mb-3 d-none">Wybranych ofert: {{ selectedOffersCount }}</p>

                                        <button class="btn btn-primary pl-5 pr-5 btn-block get_services w-100"
                                                @click.prevent="toggleOfferGenerateView"
                                                type="button">Użyj wybranych
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-9">
                        <loading :active="loading"
                                 color="#db7015"
                                 :is-full-page="true"></loading>
                        <p
                            v-if="loading"
                            style="position: relative; top: 40%; text-align: center;"
                        >
                            <strong>Przeszukujemy właśnie dla Ciebie bazę hoteli z całego świata</strong>
                        </p>
                        <p
                            v-if="error"
                            style="position: relative; top: 40%; text-align: center; color: red; font-weight: bold"
                        >
                            <strong>Wystąpił błąd. Prosimy spróbować ponownie. Jeżeli błąd będzie się powtarzał, prosimy
                                spróbować ponownie później lub skontaktować się z naszym działem technicznym.</strong>
                        </p>
                        <div class="row align-items-center mb-4" >
                            <div class="col-md-6 col">
                                <h4 v-if="payload.search && searchName && hotelDetails"><span>Wyniki wyszukiwania: </span> {{ hotelDetails.length }} wyników </h4>
                                <h4 v-else-if="hotels"><span>Wyniki wyszukiwania: </span> {{ hotels.length }} wyników </h4>
                            </div>
                            <div class="col-md-6 col-auto">
                                <div class="view-icons">
                                    <a href="javascript:void(0);" class="grid-view active"><i
                                        class="fas fa-th-large"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>

                            <div class="row" v-if="hotels && hotels.success == false">
                                <p class="alert alert-danger">
                                    {{ hotels.error.message }}
                                </p>
                            </div>

                            <div class="search-results row" v-if="hotelDetails">
                                <div class="col-sm-1 d-flex align-items-center mb-3"><strong>Filtruj:</strong></div>
                                <div class="col-sm-5 d-flex flex-row align-items-center mb-3">
                                    <label class="mr-2" for="searchName">Nazwa hotelu: </label>
                                    <input class="form-control" type="text" @keyup.enter="search"
                                           name="searchName" id="searchName" v-model="searchName">

                                </div>
                                <div class="col-sm-4 d-flex flex-row align-items-center mb-3">
                                    <label class="mr-2" for="sort">Sortuj wg: </label>
                                    <select class="form-control" v-model="sort" name="sort" @change="search">
                                        <option value="">- Wybierz -</option>
                                        <option value="asc">Cena: Rosnąca</option>
                                        <option value="desc">Cena: Malejąco</option>
                                    </select>
                                </div>
                                <div class="col-sm-2 d-flex flex-row align-items-center mb-3">
                                    <a href="#" class="btn btn-primary py-2 px-4" @click.prevent="search">Szukaj</a>
                                </div>
                                <div class="col-sm-12 d-flex" v-for="(item, index) in hotelDetails" :key="item.id">
                                    <input
                                        v-if="offerGenerator"
                                        v-model="offersToGenerate[item.id]"
                                        :value="item.id"
                                        type="checkbox"
                                        class="mr-3"
                                        id=""
                                        name=""
                                    >
                                    <div
                                        :class="{
                                            'generate' : offerGenerator,
                                            'selected' : offersToGenerate[item.id],
                                        }"
                                        class="service-widget search-results__hotel w-100"
                                    >
                                        <router-link :to="{name: 'hotel-presentation', params: {id: item.id}}"
                                                     class="search-results__hotel-image">
                                            <div class="service-img"
                                                 :style="`background: url( ${item.details.image ? ((item.details.image[0]) ? item.details.image[0].url : item.details.image.url) : '/images/service-01.jpg' });`">

                                                <div class="fav-btn" style="display:none;">
                                                    <a href="javascript:void(0)" class="fav-icon">
                                                        <i class="fas fa-heart"></i>
                                                    </a>
                                                </div>
                                                <div class="item-info">
                                                    <div class="service-user">
                                                        <a href="javascript:void(0);">
                                                            <!--<img :src="loadImg1(item.img1)" alt="">-->
                                                        </a>
                                                    </div>
                                                    <div class="cate-list">
                                                        <router-link class="bg-yellow" to="/service-details">Hotel
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                        <div class="service-content search-results__hotel-details">

                                            <div class="search-results__hotel-details-top">
                                                <h3 class="title">
                                                    <router-link :to="{name: 'hotel-presentation', params: {id: item.id}}">
                                                        {{ item.details.name }}
                                                    </router-link>
                                                </h3>
                                                <div class="row px-3">

                                                    <div class="col-sm-12 px-0 mb-2 user-info">
                                                        <div class="row">
                                                          <span class="col ser-location">
                                                            <i class="fas fa-map-marker-alt ms-1 mr-2"></i>
                                                            <span style="font-size: 13px; cursor: pointer" @click="openModal(item.details.coordinates)">
                                                            {{ item.details.address.city }}, {{ item.details.address.country }}<br>
                                                            {{ item.details.address.street1 }} {{ item.details.address.street2 }}, {{ item.details.address.zip }}
                                                            </span>
                                                          </span>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 rating mapgridrating1"
                                                         v-if="item.details.starRating && item.details.starRating > 0">
                                                        <i class="fas fa-star"
                                                           :class="{ 'filled': item.details.starRating > 0 }"></i>
                                                        <i class="fas fa-star"
                                                           :class="{ 'filled': item.details.starRating > 1 }"></i>
                                                        <i class="fas fa-star"
                                                           :class="{ 'filled': item.details.starRating > 2 }"></i>
                                                        <i class="fas fa-star"
                                                           :class="{ 'filled': item.details.starRating > 3 }"></i>
                                                        <i class="fas fa-star"
                                                           :class="{ 'filled': item.details.starRating > 4 }"></i>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="search-results__hotel-details-middle"  v-if="offerGenerator">
                                                <p class="search-results__hotel-details-middle__room" v-if="item.channel.room[0]" v-for="room in item.channel.room" :key="room.id">
                                                    <input
                                                        v-if="offersToGenerate[item.id]"
                                                        @change="roomToGenerateSelect({checked: $event.target.checked, hotel: item.id, room: room.id})"
                                                        :value="room.id"
                                                        type="checkbox"
                                                        class="mr-3"
                                                        name=""
                                                    >
                                                    <span >
                                                        Pokój: {{ room.roomType.name }} | Wyżywienie: {{ room.boardType.name }} - <strong>{{ (room.price * 4.60).toFixed(2) }} PLN</strong>
                                                    </span>

                                                </p>

                                                <p class="search-results__hotel-details-middle__room" v-else>
                                                    <input
                                                        v-if="offersToGenerate[item.id]"
                                                        @change="roomToGenerateSelect({checked: $event.target.checked, hotel: item.id, room: item.channel.room.id})"
                                                        :value="item.channel.room.id"
                                                        type="checkbox"
                                                        class="mr-3"
                                                        name=""
                                                    >
                                                    <span >
                                                        Pokój: {{ item.channel.room.roomType.name }} | Wyżywienie: {{ item.channel.room.boardType.name }} - <strong>{{ (item.channel.room.price * 4.60).toFixed(2) }} PLN</strong>
                                                    </span>

                                                </p>
                                            </div>

                                            <div class="search-results__hotel-details-bottom">

                                                <div v-if="item.price" class="search-results__hotel-days">{{ noOfDays }}
                                                    noce, {{ payload.adults }} dorośli
                                                </div>

                                                <div v-if="item.price" class="search-results__hotel-price">
                                                    od <strong>{{ Math.floor(item.price * 1.10 * rate) }} PLN</strong> / noc
                                                </div>

                                                <router-link
                                                    class="btn btn-primary mt-3"
                                                    :to="{
                                                        name: 'hotel',
                                                        params: {
                                                                id: item.id,
                                                                checkin: payload.checkin,
                                                                checkout: payload.checkout,
                                                                adults: payload.adults,
                                                                children: payload.children,
                                                                ageOne: payload.ageOne,
                                                                ageTwo: payload.ageTwo,
                                                                ageThree: payload.ageThree,
                                                            }
                                                        }"
                                                >
                                                    Sprawdź dostępność
                                                </router-link>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="pagination" v-if="hotels && hotelDetails && hotels.length > 10">

                                <button
                                    @click.prevent="goToPage(1)"
                                    class="btn"
                                >&laquo;
                                </button>

                                <span v-if="startRange > 1">...</span>

                                <button
                                    v-for="page in visiblePages"
                                    @click.prevent="goToPage(page)"
                                    :key="'visible-' + page"
                                    :class="{ 'current-page': page === currentPage }"
                                    class="btn"
                                >
                                    {{ page }}
                                </button>

                                <span v-if="endRange < totalPages">...</span>

                                <button
                                    @click.prevent="goToPage(totalPages)"
                                    class="btn"
                                >&raquo;
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <layout-footer></layout-footer>

        <div id="fullscreenMapModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-fullscreen" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; top: 3rem; right: 3rem;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <iframe
                            :src="mapSrc"
                            style="border:0; width: 100%; height: 90vh;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import {useRoute} from "vue-router";
import {onMounted} from 'vue';
import {ref} from 'vue';
import {pl} from 'date-fns/locale';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import SimpleTypeahead from "vue3-simple-typeahead";
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

export default {

    components: {
        Loading,
        VueDatePicker,
        SimpleTypeahead,
    },


    data() {
        return {

            country: 0,
            city: null,

            hotels: null,
            hotelDetails: null,
            count: null,
            error: null,

            searchName: '',
            sort: '',

            noOfDays: null,

            lang: null,

            offerGenerator: false,
            offersToGenerate: [],
            roomsToGenerate: [],
            offerGenerateView: false,

            page: 1,

            rate: 4.60,

            countries: [],
            cities: null,
            loading: false,

            searchItems: [
                {
                    title: 'Zakopane | Polska',
                    id: 30420
                } ,
                {
                    title: 'Kraków | Polska',
                    id: 30385
                },
                {
                    title: 'Szczawnica | Polska',
                    id: 30474
                }
            ],

            payload: {
                countryId: null,
                cityId: null,
                checkin: '2023-05-15',
                checkout: '2023-05-20',
                adults: 1,
                children: 0,
                childrenAge: [],
                ageOne: 0,
                ageTwo: 0,
                ageThree: 0,
                page: '',
                search: '',
                sort: '',
            },

            coordinates: null,

            imageData: null,
            newData: null
        }
    },

    watch: {
        offerGenerator(val) {
            if(!val) {
                this.offersToGenerate = [];
            }
        },
    },

    computed: {
        hotelsCount() {
            if (this.hotels) {
                return this.hotels.length;
            }
            return 0;
        },

        selectedOffersCount() {
            if(this.offersToGenerate) {
                return this.offersToGenerate.length;
            }
            return 0;
        },


        currentPage() {
            return parseInt(this.$route.query.page) || 1;
        },
        totalPages() {
            if (this.hotels && this.hotels.length > 30) {
                return Math.ceil(this.hotels.length / 30);
            }
            return 0;
        },
        startRange() {
            return Math.min(3, this.totalPages);
        },
        endRange() {
            return Math.max(this.totalPages - 2, 1);
        },

        filteredHotelDetails() {
            if(this.offerGenerateView && this.offersToGenerate.length > 0) {
                const list = this.hotelDetails;
                return list.reduce((acc, item) => {
                    if(this.offersToGenerate[item.id]) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
            }
            return this.hotelDetails;
        },

        visiblePages() {
            const start = Math.max(this.currentPage - 2, 1);
            const end = Math.min(this.currentPage + 2, this.totalPages);
            const range = [];

            for (let i = start; i <= end; i++) {
                range.push(i);
            }

            return range;
        },

        mapSrc() {
            if(this.coordinates) {
                return `https://maps.google.com/maps?q=${this.coordinates.latitude},${this.coordinates.longitude}&z=15&output=embed`;
            }
            return `https://maps.google.com/maps?q=&z=15&output=embed`;
        },
    },

    methods: {

        setLoading(value) {
            this.loading = value;
        },

        getEurPlnRate() {
            this.loading = true;
            this.$emit('loading', true);
            axios.get('/api/settings/eur-pln-rate')
                .then(res => {
                    this.rate = res.data.rate;
                    this.effectiveDate = res.data.effectiveDate;
                    console.log(this.rate);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    // this.loading = false;
                });
        },


        selectItemEventHandler(item) {
            this.city = item.id;
            this.payload.cityId = item.id;
        },

        itemProjectionFunction(item) {
            return item.title;
        },

        roomToGenerateSelect(data) {
            if(!this.roomsToGenerate[data.hotel]) {
                this.roomsToGenerate[data.hotel] = [];
                this.roomsToGenerate[data.hotel][data.room] = data.checked;
            } else {
                this.roomsToGenerate[data.hotel][data.room] = data.checked;
            }
        },

        download() {
            let pdfRef = this.$refs.download;

            html2canvas(pdfRef, { allowTaint: false, useCORS: true }).then(canvas => {
                let pdfImage = canvas.toDataURL();

                // this.imageData = canvas.toDataURL("image/jpg");
                // this.newData = this.imageData.replace(/^data:image\/jpg/, "data:application/octet-stream");

                let pdf = new jsPDF();
                pdf.addImage(pdfImage, 'PNG', 10, 10);
                pdf.save("Oferta.pdf");
            })
        },

        goToPage(page) {
            this.page = page;
            this.$router.replace({path: this.$route.path, query: {search: this.searchName, sort: this.sort, page: this.page}});
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.getHotelDetails(page);
        },

        selectOfferToGenerate(id) {
            if(this.offerGenerator) {
                if(this.offersToGenerate[id]) {
                    this.offersToGenerate[id] = false;
                } else {
                    this.offersToGenerate[id] = true;
                }
                console.log(this.offersToGenerate);
            }
        },

        toggleOfferGenerateView() {
            this.offerGenerateView = !this.offerGenerateView;
        },

        getCountries() {
            axios.get('/api/countries')
                .then(res => {
                    this.countries = res.data;
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getCities(countryId) {
            axios.get(`/api/cities/${countryId}`)
                .then(res => {
                    this.cities = res.data;
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        selectCountry() {
            console.log(this.country);
            // this.getCities(this.country);
            this.payload.countryId = this.country;
        },

        searchByName() {
            if (this.searchName || (this.$route.query.search && !this.searchName)) {
                this.$router.replace({path: this.$route.path, query: {search: this.searchName, sort: this.sort, page: this.page}});
            }
        },

        sortByValue() {
            if (this.sort || (this.$route.query.sort && !this.sort)) {
                this.$router.replace({path: this.$route.path, query: {search: this.searchName, sort: this.sort, page: this.page}});
            }
        },

        selectCity() {
            console.log(this.city);
            this.payload.cityId = this.city;
        },

        resetChildrenAge() {
            this.payload.childrenAge = [];
        },

        search() {
            this.error = null;
            this.payload.checkin = this.date[0];
            this.payload.checkout = this.date[1];
            this.payload.sort = this.sort;

            this.payload.search = this.searchName;
            this.searchByName();
            this.sortByValue();


            this.payload.childrenAge = [
                this.payload.ageOne,
                this.payload.ageTwo,
                this.payload.ageThree,
            ];

            const diffInMs = new Date(this.date[1]) - new Date(this.date[0])
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            this.noOfDays = diffInDays;

            this.$store.commit('hotels/setHotels', null)
            this.loading = true;
            this.$emit('loading', true);
            axios.post('/api/search/hotels', this.payload)
                .then(res => {
                    this.hotels = res.data;
                    this.$store.commit('hotels/setHotels', res.data)
                    this.getHotelDetails();
                })
                .catch(error => {
                    this.error = error.response.data.error;
                    this.loading = false;
                    this.$emit('loading', false);
                })

        },

        getHotelDetails(page = 1) {
            this.$store.commit('hotels/setHotelDetails', null)
            this.loading = true;
            this.$emit('loading', true);
            axios.post('/api/search/hotel-details', {page: page, hotels: this.hotels})
                .then(res => {
                    this.hotelDetails = res.data;
                    sessionStorage.setItem('details', JSON.stringify(res.data));
                    this.$store.commit('hotels/setHotelDetails', res.data)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },

        getDestinations() {
            axios.get(`/api/destinations`)
                .then(res => {
                    this.searchItems = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        openModal(coordinates) {
            this.coordinates = coordinates;
            $('#fullscreenMapModal').modal('show');
        },
    },

    created() {
        this.getDestinations();
    },

    mounted() {
        this.getEurPlnRate();
        this.lang = pl;
        if (
            this.$route.params.destination
            && this.$route.params.adults
            && this.$route.params.children
        ) {
            this.country = 169;
            this.payload.countryId = 169;
            this.city = this.$route.params.destination;
            this.payload.cityId = this.$route.params.destination;
            this.payload.adults = this.$route.params.adults;
            this.payload.children = Number(this.$route.params.children);
            this.payload.ageOne = this.$route.params.ageOne;
            this.payload.ageTwo = this.$route.params.ageTwo;
            this.payload.ageThree = this.$route.params.ageThree;
            if (this.$route.query.search) {
                this.searchName = this.$route.query.search;
                this.payload.search = this.$route.query.search;
            }
            if (this.$route.query.sort) {
                this.sort = this.$route.query.sort;
                this.payload.sort = this.$route.query.sort;
            }
            if (this.$route.query.page) {
                this.page = this.$route.query.page;
                this.payload.page = this.$route.query.page;
            }
            // const searchDetails = sessionStorage.getItem('details');
            // if (searchDetails) {
            //     this.hotelDetails = JSON.parse(searchDetails);
            // } else {
            //     this.search();
            // }
            this.search();

            this.payload.childrenAge = [
                this.payload.ageOne,
                this.payload.ageTwo,
                this.payload.ageThree,
            ];
        }
    },


    setup() {
        const date = ref();
        const route = useRoute()

        onMounted(() => {
            if (route.params.checkin && route.params.checkout) {
                const startDate = new Date(route.params.checkin);
                const endDate = new Date(route.params.checkout);
                date.value = [route.params.checkin, route.params.checkout];
            }
        })

        return {
            date
        }
    }

}
</script>

<style scoped>
    .generate {
        cursor: pointer;
    }
    .search-results__hotel.selected {
        background: #a6ffa6;
    }

    .modal-fullscreen {
        width: 96vw;
        max-width: none;
        height: 96vh;
        margin: 0;
        padding: 0;
        margin: 0 auto;
        margin-top: 2rem;
    }
    .modal-fullscreen .modal-content {
        height: 90vh;
        border: none;
        border-radius: 0;
    }
    .modal-fullscreen .modal-body {
        padding: 0;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        cursor: pointer;
    }

</style>
