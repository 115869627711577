<template>
  <div class="col-lg-3 theiaStickySidebar">
    <div class="stickyside">
      <div class="card filter-card">
        <div class="card-body">
          <h4 class="card-title mb-4">Szukaj</h4>
          <form id="search_form">
            <div class="filter-widget">

              <div class="filter-list">
                <h4 class="filter-title">Pobyt:</h4>
                <VueDatePicker v-model="date" model-type="yyyy-MM-dd"
                               format="dd MMM" range auto-apply multi-calendars
                               :format-locale="lang"></VueDatePicker>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Przyjazd</h4>
                <input type="date" v-model="payload.checkin" class="form-control" placeholder="Checkin">
              </div>
              <div class="filter-list">
                <h4 class="filter-title">Wyjazd</h4>
                <input type="date" v-model="payload.checkout" class="form-control" placeholder="Checkout">
              </div>
              <div class="filter-list" v-if="countries">
                <h4 class="filter-title">Kraj</h4>
                <select v-model="country" @change="selectCountry" class="form-control selectbox select form-select">
                  <option value="0" selected>-- Wybierz --</option>
                  <option value="169">Polska</option>
                  <option v-for="country in countries" disabled :value="country.id">{{ country.name }}</option>
                </select>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Miasto</h4>
                <select v-model="city" @change="selectCity" class="form-control selectbox select form-select">
                  <option value="0" selected>-- Wybierz --</option>
                  <option value="30420">Zakopane</option>
                  <option value="30385">Kraków</option>
                  <option value="30474">Szczawnica</option>
<!--                  <option v-for="city in cities" :value="city.id">{{ city.name }}</option>-->
                </select>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Osoby dorosłe</h4>
                <input type="number" step="1" max="3" v-model="payload.adults" class="form-control">
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Dzieci</h4>
                <input type="number" step="1" max="3" @change="resetChildrenAge" v-model="payload.children"
                       class="form-control">
              </div>

              <div class="filter-list" v-if="payload.children > 0">

                <h4    class="filter-title">Wiek dzieci:</h4>

                <input
                    v-for="index in payload.children"
                    v-model="payload.childrenAge[index - 1]"
                    :key="index"
                    type="number"
                    step="0"
                    class="form-control"
                >

              </div>
            </div>

            <button class="btn btn-primary pl-5 pr-5 btn-block get_services w-100" @click.prevent="search"
                    type="button">Szukaj
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import {ref} from 'vue';
import { pl } from 'date-fns/locale';
import axios from 'axios';

export default {

  components: {
    VueDatePicker,
  },

  data() {
    return {
      country: null,
      city: null,
      date: null,

      hotels: null,
      count: null,

      lang: null,

      countries: [],
      cities: null,
      loading: false,

      payload: {
        countryId: null,
        cityId: null,
        checkin: '2023-05-15',
        checkout: '2023-05-20',
        adults: 1,
        children: 0,
        childrenAge: [],
      },
    }
  },

  computed: {
    hotelsCount() {
      if (this.hotels) {
        return this.hotels.length;
      }
      return 0;
    }
  },

  methods: {
    getCountries() {
      axios.get('/api/countries')
          .then(res => {
            this.countries = res.data;
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          });
    },

    getCities(countryId) {
      axios.get(`/api/cities/${countryId}`)
          .then(res => {
            this.cities = res.data;
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          });
    },

    selectCountry() {
      console.log(this.country);
      // this.getCities(this.country);
      this.payload.countryId = this.country;
    },

    selectCity() {
      console.log(this.city);
      this.payload.cityId = this.city;
    },

    resetChildrenAge() {
      this.payload.childrenAge = [];
    },

    search() {
      const date = ref(new Date());
      console.log(this.date[0]);
      console.log(this.date[1]);

      this.payload.checkin = this.date[0];
      this.payload.checkout = this.date[1];

      this.$store.commit('hotels/setHotels', null)
      this.loading = true;
      this.$emit('loading', true);
      axios.post('/api/search/hotels', this.payload)
          .then(res => {
            console.log(res.data);
            this.hotels = res.data;
            this.$store.commit('hotels/setHotels', res)
            this.getHotelDetails();
          })
          .catch(error => {
            console.log(error);
          })

    },

    getHotelDetails() {
      this.$store.commit('hotels/setHotelDetails', null)
      this.loading = true;
      this.$emit('loading', true);
      axios.post('/api/search/hotel-details', this.hotels)
          .then(res => {
            console.log(res.data);
            this.$store.commit('hotels/setHotelDetails', res)
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
            this.$emit('loading', false);
          });
    }
  },

  mounted() {
    this.lang = pl;
    // this.getCountries();
  },

  setup() {
    const date = ref();

    return {
      date
    }
  }
}
</script>
