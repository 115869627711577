<template>
    <div>
        <table id="user" class="table table-hover table-center mb-0 datatable" v-if="users">
            <thead>
            <tr>
                <th>#</th>
                <th>Nazwa</th>
                <th>E-mail</th>
                <th>Telefon</th>
                <th>Status</th>
                <th>Utworzono</th>
                <th>Akcja</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in pendingUsers" :key="item.id">
                <td>{{ item.id }}</td>
                <td>
                    <h2 class="table-avatar">
                        <a href="javascript:void(0);">{{ item.firstname }} {{ item.lastname }}</a>
                    </h2>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>
                <span
                    class="badge inv-badge"
                    :class="{ 'bg-warning': item.status === 'pending', 'bg-success': item.status === 'active' }"
                >{{ (item.status === 'active') ? 'Aktywny' : 'Oczekujący' }}</span>
                </td>
                <td>{{ $filters.formatDate(item.created_at)  }}</td>
                <td>
                    <router-link :to="`/admin/user/activate/${item.id}`" v-if="item.status === 'pending'" class="btn btn-sm bg-warning-light"><i class="far fa-eye me-1"></i> Aktywuj </router-link>
                    <a href="javascript:void(0);" data-toggle="modal" data-target="#deleteConfirmModal" v-if="item.status === 'active'" class="btn px-3 py-2 btn-sm bg-danger-light" @click.prevent="deleteUser(item.id)"><i class="fas fa-times me-1"></i> Usuń </a>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz usunąć użytkownika?</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="deleteUserWithId" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from "axios";

export default {
    props: {
        users: {
            type: Object,
            required: true,
        },
    },

    computed: {
        pendingUsers() {
            if(this.users) {
                const users = this.users;
                return users.reduce((acc, item) => {
                    if(item.status === 'pending') {
                        acc.push(item);
                    }
                    return acc;
                }, [])
            }
            return this.users;
        },
    },

    data() {
        return {
            userToDelete: null,
        }
    },

    methods: {
        deleteUser(userId) {
            this.userToDelete = userId;
        },

        deleteUserWithId() {
            this.loading = true;
            axios.post(`/api/admin/user/delete/${this.userToDelete}`, {})
                .then(res => {
                    this.success = true;
                    $('#deleteConfirmModal').modal('hide');
                    this.userToDelete = null;
                    this.$emit('reloadUsers');
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        }
    },

    mounted() {
    },
}
</Script>

<style scoped>

</style>
