<template>

    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Panel informacyjny - <small>Umowy i aneksy</small></h3>
                            </div>
                            <div class="col-auto text-center d-flex justify-content-between " v-if="isSuperadmin || isClient">
                                <button class="btn btn-primary mr-3" data-toggle="modal" data-target="#addFilesModal">Dodaj</button>

                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table id="information-files" class="table table-hover table-center mb-0 datatable" v-if="files && files.length > 0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Typ</th>
                                                <th>Nazwa</th>
                                                <th>Oryginalna nazwa</th>
                                                <th>Pobierz</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(file, index) in files" :key="file.id">
                                                <td>{{ file.id }}</td>
                                                <td class="text-capitalize fw-bold">{{ file.type }}</td>
                                                <td>{{ file.title }}</td>
                                                <td>{{ file.file.file_name }}</td>
                                                <td><a :href="file.file.file_path" target="_blank">Pobierz</a></td>
                                                <td>
                                                    <button class="btn btn-danger mr-3" data-toggle="modal" data-target="#deleteConfirmModal" @click="deleteFile(file.id)">Usuń</button>
                                                    <button class="btn btn-warning" data-toggle="modal" data-target="#editFilesModal" @click="updateFile(file.id)">Aktualizuj</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div class="text-center py-5" v-else>
                                            Brak plików
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

        <div class="modal fade" id="addFilesModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Dodaj nową pozycję</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <file-upload
                            endpoint="/api/admin/information/files"
                            @success="getAll"
                            :additional-fields="additionalFields"
                        ></file-upload>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editFilesModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Edytuj plik - wgraj</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <file-upload
                            endpoint="/api/admin/information/files"
                            :file-to-update="fileToUpdate"
                            action="update"
                            :additional-fields="[
                                {
                                    type: 'select',
                                    label: 'Typ pliku',
                                    key: 'type',
                                    value: {umowa: 'Umowa', aneks: 'Aneks', faktura: 'Faktura'}
                                }
                            ]"
                            @success="fileUpdated"
                        ></file-upload>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz usunąć plik?</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="deleteFileWithId" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import AdminInformationFiles from "./AdminInformationFiles";
import AdminInformationPrices from "./AdminInformationPrices";
import AdminInformationUsers from "./AdminInformationUsers";

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            files: [],
            fileToDelete: null,
            fileToUpdate: null,

            clients: null,
        }
    },

    computed: {
        additionalFields() {
            let fields =  [
                {
                    type: 'select',
                    label: 'Typ pliku',
                    key: 'type',
                    value: {umowa: 'Umowa', aneks: 'Aneks', faktura: 'Faktura'}
                }
            ];
            if(this.clients) {
                const clients = this.clients.reduce((acc, item) => {
                    acc[item.id] = `${item.id}. ${item.firstname} ${item.lastname}`
                    return acc;
                }, {});

                if(this.isSuperadmin) {
                    fields.push({
                        type: 'select',
                        label: 'Użytkownik',
                        key: 'user',
                        value: clients,
                    });
                }
            }

            return fields;
        },
    },

    methods: {
        deleteFile(id) {
            this.fileToDelete = id;
        },

        updateFile(id) {
            this.fileToUpdate = id;
        },

        fileUpdated() {
            $('#editFilesModal').modal('hide');
            this.getAll();
        },

        getClientList() {
            axios.get('/api/admin/users/clients')
                .then(response => {
                    this.clients = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        deleteFileWithId() {
            this.loading = true;
            axios.delete(`/api/admin/information/files/${this.fileToDelete}`)
                .then(() => {
                    this.files = this.files.filter(file => file.id !== this.fileToDelete);
                    $('#deleteConfirmModal').modal('hide');
                    this.fileToDelete = null;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getAll() {
            this.loading = true;
            axios.get('/api/admin/information/files')
                .then(response => {
                    this.files = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.getAll();
        if(this.isSuperadmin) {
            this.getClientList();
        }
    }
}
</script>

<style scoped>

</style>
