<template>
  <div class="search-results row">
    <div class="col-sm-12" v-if="hotelDetails">
      <div class="service-widget search-results__hotel">
        <router-link :to="{name: 'hotel', params: {id: hotelDetails.id}}" class="search-results__hotel-image">
          <div class="service-img"
               :style="`background: url( ${hotelDetails.image ? hotelDetails.image[0].url : '/images/service-01.jpg' });`">

            <div class="fav-btn" style="display:none;">
              <a href="javascript:void(0)" class="fav-icon">
                <i class="fas fa-heart"></i>
              </a>
            </div>
            <div class="item-info">
              <div class="service-user">
                <a href="javascript:void(0);">
                  <!--														<img :src="loadImg1(hotelDetails.img1)" alt="">-->
                </a>
              </div>
            </div>
          </div>
        </router-link>
        <div class="service-content search-results__hotel-details">

          <div class="search-results__hotel-details-top">
            <div class="d-flex justify-content-left center">
                <h3 class="title">
                    <router-link :to="{name: 'hotel', params: {id: hotelDetails.id}}">{{ hotelDetails.name }}</router-link>
                </h3>
                <div class="ml-2 rating mapgridrating1 w-auto" v-if="hotelDetails.starRating && hotelDetails.starRating > 0">
                    <i class="fas fa-star filled" v-if="hotelDetails.starRating > 0"></i>
                    <i class="fas fa-star filled" v-if="hotelDetails.starRating > 1"></i>
                    <i class="fas fa-star filled" v-if="hotelDetails.starRating > 2"></i>
                    <i class="fas fa-star filled" v-if="hotelDetails.starRating > 3"></i>
                    <i class="fas fa-star filled" v-if="hotelDetails.starRating > 4"></i>
                </div>
            </div>

            <div class="row px-3">

              <div class="col-sm-12 px-0 mb-2 user-info">
                <div class="row">
                  <span class="col ser-location">
                    <i class="fas fa-map-marker-alt ms-1 mr-2"></i>
                    <span>
                        {{ hotelDetails.address.street1 }}
                      {{ (hotelDetails.address.street2 !== '') ? ', ' : '' }}
                      {{ hotelDetails.address.street2 }}
                    <br>
                    {{ hotelDetails.address.zip }} {{ hotelDetails.address.city }}, {{ hotelDetails.address.country }}</span>
                  </span>
                </div>
              </div>

            </div>

          </div>

          <div class="search-results__hotel-details-bottom">

            <div v-if="hotelDetails.price" class="search-results__hotel-days"></div>
            <div v-if="hotelDetails.price" class="search-results__hotel-price"></div>

          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  components: {
    Loading,
  },

  props: {
    hotelDetails: {
      type: Object,
      required: true,
    },
  },
}
</script>
