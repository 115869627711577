<template>
    <div class="col-xl-3 col-md-4 theiaStickySidebar">
        <div class="stickyside">
        <div class="mb-4" v-if="currentUser">
            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                <img alt="profile image" src="/images/user.jpg" class="avatar-lg rounded-circle">
                <div class="ml-3 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                    <h6 class="mb-0 ml-3">{{ currentUser.firstname }} {{ currentUser.lastname }}</h6>
                    <p class="text-muted mb-0"></p>
                </div>
            </div>
        </div>
        <div class="widget settings-menu">
            <ul role="tablist" class="nav nav-tabs">
                <li class="nav-item current">
                    <router-link to="/user-dashboard" class="nav-link">
                        <i class="fas fa-chart-line"></i> <span>Pulpit</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/user-settings" class="nav-link">
                        <i class="far fa-user"></i> <span>Moje dane</span>
                    </router-link>
                </li>
                <li class="nav-item current">
                    <router-link to="/user-bookings" class="nav-link">
                        <i class="far fa-calendar-check"></i> <span>Moje rezerwacje</span>
                    </router-link>
                </li>
                <li class="nav-item current">
                    <router-link to="/user/travel-policy" class="nav-link">
                        <i class="fas fa-clipboard-check"></i> <span>Polityka podróży</span>
                    </router-link>
                </li>
                <li class="nav-item current">
                    <router-link to="/user/information" class="nav-link">
                        <i class="fas fa-address-card"></i> <span>Informacje</span>
                    </router-link>
                </li>
<!--                <li class="nav-item current">-->
<!--                    <router-link to="/favourites" class="nav-link">-->
<!--                        <i class="fas fa-heart"></i> <span>Favourites</span>-->
<!--                    </router-link>-->
<!--                </li>-->

<!--                <li class="nav-item">-->
<!--                    <router-link to="/user-wallet" class="nav-link">-->
<!--                        <i class="far fa-money-bill-alt"></i> <span>Wallet</span>-->
<!--                    </router-link>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                    <router-link to="/user-reviews" class="nav-link">-->
<!--                        <i class="far fa-star"></i> <span>Reviews</span>-->
<!--                    </router-link>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                    <router-link to="/user-payment" class="nav-link">-->
<!--                        <i class="fas fa-hashtag"></i> <span>Payment</span>-->
<!--                    </router-link>-->
<!--                </li>-->
            </ul>
        </div>
    </div>
</div>
</template>

<script>

import {mapGetters} from "vuex";

export default {

    // props: {
    //     user: {
    //         type: Object,
    //         required: true,
    //     }
    // },

    data() {
        return {

        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    }
}
</script>
