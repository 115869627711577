<template>

    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Panel informacyjny - <small>Cennik</small></h3>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table id="information-prices" class="table table-hover table-center mb-0 datatable" v-if="prices && prices.length > 0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>Cena</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(price, index) in prices" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ price.name }}</td>
                                                <td>{{ price.price }}</td>

                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import AdminInformationFiles from "./AdminInformationFiles";
import AdminInformationPrices from "./AdminInformationPrices";
import AdminInformationUsers from "./AdminInformationUsers";

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            prices: [],
        }
    },

    methods: {
        getAll() {
            this.loading = true;
            axios.get(`/api/admin/information/prices/${this.globalUserId}`)
                .then(response => {
                    this.prices = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.getAll();
    }
}
</script>

<style scoped>

</style>
