<template>
<!-- Invoice Item -->
								<div class="invoice-item invoice-table-wrap">
									<div class="row">
										<div class="col-md-12">
											<div class="table-responsive">
												<table class="invoice-table table table-center mb-0">
													<thead>
														<tr>
															<th>Description</th>
															<th>Category</th>
															<th>Rate/Item</th>
															<th>Quantity</th>
															<th>Discount (%)</th>
															<th class="text-end">Amount</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="item in viewinvoice" :key="item.id">
															<td>{{item.name}}</td>
															<td>{{item.name1}}</td>
															<td>{{item.rate}}</td>
															<th>{{item.amt}}</th>
															<th>{{item.rate1}}</th>
															<td class="text-end">{{item.rate2}}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<!-- /Invoice Item -->
</template>
<script>
	import viewinvoice from '../../../../../assets/json/admin/invoice/viewinvoice.json'
	export default {
	data() {
        return {
            viewinvoice: viewinvoice
        }
    }
	}
	</script>