<template>
    <div class="main-wrapper admin-reservation">
        <layout-headeradmin></layout-headeradmin>
        <layout-sidebaradmin></layout-sidebaradmin>
        <div class="page-wrapper">
            <div class="content container-fluid">
                Moja rezerwacja {{ bookingId }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bookingId: null,
        }
    },

    mounted() {
        this.bookingId = this.$route.params.id;
        console.log(this.bookingId);
    }
}
</script>

<style scoped>

</style>
