<template>

	<div class="main-wrapper">

	<layout-header></layout-header>

    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">

                    <bookinglist></bookinglist>

                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
