<template>
<div class="card transaction-table mb-0">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Date</th>
                                            <th>Wallet</th>
                                            <th>Credit</th>
                                            <th>Debit</th>
                                            <th>Txt amt</th>
                                            <th>Available</th>
                                            <th>Reason</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in userwallet" :key="item.id">
                                            <td>{{item.no}}</td>
                                            <td>{{item.date}}</td>
                                            <td>{{item.rate}}</td>
                                            <td>{{item.rate1}}</td>
                                            <td>{{item.rate2}}</td>
                                            <td>{{item.rate3}}</td>
                                            <td>{{item.rate4}}</td>
                                            <td>{{item.service}}</td>
                                            <td><span class="badge bg-danger-light">{{item.button}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
</template>
<script>
	import userwallet from '../../../../../assets/json/website/userwallet.json'
	export default {
	mounted() {
    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	data() {
        return {
            userwallet: userwallet
        }
    }
	}
	</script>