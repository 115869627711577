<template>

	<div class="main-wrapper">

	<layout-header></layout-header>

    <loading :active="loading"
             color="#db7015"
             :is-full-page="true"></loading>

    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar

                ></customersidebar>
                <div class="col-xl-9 col-md-8" style="min-height: 50vh">
                    <div class="tab-content pt-0">
                        <div class="tab-pane show active" id="user_profile_settings">
                            <div class="widget">
                                <h4 class="widget-title">Profil - ustawienia</h4>

                                <p class="w-100 alert alert-success" v-if="success">
                                    Profil użytkownika został pomyslnie edytowany
                                </p>
                                <p class="w-100 alert alert-danger" v-if="error">
                                    <strong>Błąd!</strong> Podczas edycji użytkownika wytąpił błąd. Skontaktuj się z administratorem
                                </p>

                                <form @submit.prevent="updateUser" v-if="user">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <h5 class="form-title">Twoje informacje</h5>
                                        </div>
                                        <div class="form-group col-xl-12" v-if="false">
                                            <div class="media align-items-center mb-3 d-flex">
                                                <img class="user-image" src="/images/user.jpg" alt="">
                                                <div class="media-body">
                                                    <h5 class="mb-0">Jeffrey Akridge</h5>
                                                    <p>Max file size is 20mb</p>
                                                    <div class="jstinput">	<a href="javascript:void(0);" class="avatar-view-btn browsephoto openfile">Browse</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 form-group">
                                            <label>Tytuł</label>
                                            <select class="form-control" name="title" id="title" v-model="user.title">
                                                <option value="MR">Pan</option>
                                                <option value="MS">Pani</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-5 form-group">
                                            <label>Imię</label>
                                            <input type="text" class="form-control" name="firstname"
                                                   v-model="user.firstname">
                                        </div>
                                        <div class="col-sm-5 form-group">
                                            <label>Nazwisko</label>
                                            <input type="text" class="form-control" name="lastname"
                                                   v-model="user.lastname">
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <label>E-mail</label>
                                            <input type="email" class="form-control" name="email"
                                                   v-model="user.email" disabled>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <label>Telefon</label>
                                            <input type="text" class="form-control" name="phone"
                                                   v-model="user.phone">
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <label>Adres</label>
                                            <input type="text" class="form-control" name="street"
                                                   v-model="user.street">
                                        </div>
                                        <div class="col-sm-3 form-group">
                                            <label>Kod pocztowy</label>
                                            <input type="text" class="form-control" name="postal_code"
                                                   v-model="user.postal_code">
                                        </div>
                                        <div class="col-sm-3 form-group">
                                            <label>Miasto</label>
                                            <input type="text" class="form-control" name="city"
                                                   v-model="user.city">
                                        </div>

                                        <div class="col-sm-12 mt-4 save-form">
                                            <button class="btn btn-primary save-btn" type="submit">Zapisz</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {

    components: {
        Loading,
    },

    data() {
        return {
            error: null,
            loading: false,
            success: false,

            user: null,
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    },

    methods: {
        getUser() {
            this.loading = true;
            axios.get(`/api/user/get/${this.globalUserId}`)
                .then(res => {
                    let user = res.data;
                    this.user = user;
                    this.$store.commit('user/setCurrentUser', user);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        updateUser() {
            this.loading = true;
            axios.post(`/api/user/update/${this.globalUserId}`, this.user)
                .then(res => {
                    let user = res.data.data;
                    console.log(user);
                    this.user.name = user.name;
                    this.user.email = user.email;

                    this.$store.commit('user/setCurrentUser', user);
                    this.success = true;
                })
                .catch(error => {
                    console.log(error);
                    this.error = true;
                }).finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        if(!this.currentUser) {
            this.getUser();
        } else {
            this.user = this.currentUser;
        }
        console.log(this.user);
    },
}
</script>
