<template>
<section class="feature-category dots-hover category-four">
    <div class="container">
        <div class="section-heading section-four-head text-center aos" data-aos="fade-up">
            <h2>Featured Categories</h2>
            <span>What do you need to find?</span>
        </div>
        <div class="service-carousel">
            <div class="categories-slider1 aos" data-aos="fade-up">
                <div class="categories-widget category-merge">
                    <div class="categories-img">
                        <div class="category-box">
                            <div class="work-img">
                                <img src="/images/category/category-16.jpg" alt="">
                            </div>
                            <div class="categories-img-box">
                                <router-link to="/search">
                                    <i class="fas fa-car"></i>
                                    <span>Car mechanism & Car Wash</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="categories-widget category-merge">
                    <div class="categories-img">
                        <div class="category-box">
                            <div class="work-img">
                                <img src="/images/category/category-17.jpg" alt="">
                            </div>
                            <div class="categories-img-box">
                                <router-link to="/search">
                                    <i class="fas fa-laptop"></i>
                                    <span>Computer service & Spares</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="categories-widget category-merge">
                    <div class="categories-img">
                        <div class="category-box">
                            <div class="work-img">
                                <img src="/images/category/category-18.jpg" alt="">
                            </div>
                            <div class="categories-img-box">
                                <router-link to="/search">
                                    <i class="fas fa-building"></i>
                                    <span>Building Construction & Paintings</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="categories-widget category-merge">
                    <div class="categories-img">
                        <div class="category-box">
                            <div class="work-img">
                                <img src="/images/category/category-19.jpg" alt="">
                            </div>
                            <div class="categories-img-box">
                                <router-link to="/search">
                                    <i class="fas fa-brush"></i>
                                    <span>Steam Car &  Wash</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="categories-widget category-merge">
                    <div class="categories-img">
                        <div class="category-box">
                            <div class="work-img">
                                <img src="/images/category/category-17.jpg" alt="">
                            </div>
                            <div class="categories-img-box">
                                <router-link to="/search">
                                    <i class="fas fa-building"></i>
                                    <span>Building Construction & Paintings</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
	export default {
	mounted() {
        if($('.categories-slider1').length > 0) {
		$('.categories-slider1').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>
