<template>
<!-- Category Section -->
		<section class="" style="    margin-top: 8rem;">
<!--		<section class="category-section">-->
			<div class="container">
				<div class="row">
					<div class="col-lg-12">

            <div class="row mb-5">

              <div class="col-md-7">
                <div class="viewall aos aos-init aos-animate" data-aos="fade-up">

                  <span class="double-color">Popularne kierunki</span><br>
                  <p class="pt-2 pb-2">Od tych popularnych kierunków podróży dzieli Cię zaledwie jedno kliknięcie</p>
                </div>
              </div>
            </div>

						<div class="catsec">
							<div class="row">
								<div class="col-lg-4 col-md-6" v-for="item in category" :key="item.id">
									<router-link to="#">
										<div class="cate-widget aos" data-aos="fade-up">
											<img :src="loadImg(item.img)" alt="">
											<div class="cate-title">
												<h3><span><i class="fas fa-circle"></i> {{item.name}}</span></h3>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Category Section -->
</template>
<script>
	import category from '../../../../../assets/json/website/home/destinations.json'
	const images = require.context('../../../../../assets/img/popular-destinations', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            category: category
        }
    }
	}
	</script>
