<template>
    <div class="main-wrapper admin-reservation">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>

            <communicator
                v-if="communicator"
                @close="closeCommunicator"
                :email="globalUser.email"
                :reservation="reservationNumber"
            ></communicator>

            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="totalreport" v-if="reservations">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Numer</th>
                                                <th>Przyjazd</th>
                                                <th>Wyjazd</th>
                                                <th>Rezerwacja</th>
                                                <th>Kwota</th>
                                                <th>Status</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody v-for="item in reservations" :key="item.id">
                                            <tr>
                                                <td>{{item.id}}</td>
                                                <td>
                                                    {{item.reservation_number}}<br>
                                                    <small>{{item.firstname}} {{item.lastname}}</small>
                                                </td>
                                                <td>
                                                    {{ item.check_in }}
                                                </td>
                                                <td>
                                                    {{ item.check_out }}
                                                </td>
                                                <td>{{ item.post_data.hotel.name }}<br> <small>{{ item.post_data.hotel.address }}</small></td>
                                                <td>{{item.price_netto_pln }}</td>
                                                <td>
                                                    <label class="badge badge-success">Potwierdzona</label>
                                                </td>
                                                <td>
                                                    <router-link :to="`/admin/reservation/edit/${item.id}`" title="Edytuj" class="mr-2"><i class="far fa-edit mr-1"></i></router-link>
                                                    <a href="#" @click.prevent="openCommunicator(item.reservation_number)" title="Wyślij wiadomość" class="mr-2"><i class="fas fa-comments mr-1"></i></a>
                                                    <a @click="toggleShowMore(item.id)" title="Show More" class="mr-2"><i class="fas fa-chevron-down mr-1"></i></a>
                                                </td>
                                            </tr>
                                            <tr v-show="showMore[item.id]">
                                                <td colspan="8">
                                                    <div v-if="isSuperadmin">
                                                        <div class="mb-3">
                                                            <textarea v-model="item.notice" class="form-control" rows="3" placeholder="Wprowadź notatkę"></textarea>
                                                        </div>
                                                        <button @click="saveNote(item)" class="btn btn-primary">Zapisz</button>
                                                    </div>
                                                    <div v-else>
                                                        <div class="mb-3">
                                                            <p class="border-1 m-2" v-html="item-notice"></p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import Communicator from "../../communicator/Communicator";
export default {

    components: {
        Loading,
        Communicator,
    },

    data() {
        return {
            loading: false,
            reservations: [],
            communicator: false,
            reservationNumber: null,
            showMore: {}, // object to manage visibility state of hidden rows
        }
    },

    computed: {

    },

    methods: {

        getAll() {
            this.loading = true;
            axios.get('/api/admin/reservation/get')
                .then(response => {
                    this.reservations = response.data.data;
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        toggleShowMore(id) {
            // Toggle the visibility state
            this.showMore = { ...this.showMore, [id]: !this.showMore[id] };
        },
        saveNote(item) {
            this.loading = true;
            axios.post(`/api/admin/reservation/notice`, {id: item.id, notice: item.notice})
                .then(res => {
                    this.getAll();
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        deleteReservation(id) {

        },

        closeCommunicator() {
            this.communicator = false;
            this.reservationNumber = null;
        },

        openCommunicator(reservationNumber) {
            this.reservationNumber = reservationNumber;
            this.communicator = true;
        },

    },

    created() {
        this.getAll();

    }
}
</script>

<style scoped>

</style>
