<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Polityka podróży - <small>Limity</small></h3>
                            </div>
                            <div class="col-auto text-center d-flex justify-content-between " v-if="isSuperadmin || isClient">
                                <button class="btn btn-primary mr-3" data-toggle="modal" data-target="#addItemModal">Dodaj</button>

                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table
                                            v-if="travelPolicyLevels"
                                            id="travel-policies"
                                            class="table table-hover table-center mb-0 datatable"
                                        >
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>Kwota limitu</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(level, index) in travelPolicyLevels" :key="level.id">
                                                <td>{{ level.id }}</td>
                                                <td>{{ level.title }}</td>
                                                <td>
                                                    <input v-model="level.amount" type="text" class="form-control" />
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteConfirmModal" @click="deletePolicyLevel(level.id)">Usuń</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <button class="btn btn-success mt-3" @click="saveAll">Edytuj</button>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

        <div class="modal fade" id="addItemModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Dodaj nowy limit</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table>
                            <tbody>
                            <tr>
                                <td class="w-75"><input class="form-control" type="text" name="title" placeholder="Nazwa limitu" v-model="newItemPayload.title" /> </td>
                                <td class="w-25"><input class="form-control" type="text" name="amount" placeholder="Kwota limitu" v-model="newItemPayload.amount" /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger si_accept_confirm" @click.prevent="addNew">Zapisz</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz usunąć pozycję?</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="deletePolicyLevelWithId" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
export default {

    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            travelPolicyLevels: [],
            newItemPayload: {
                title: null,
                amount: null,
            },
            policyLevelToDelete: null,

            customPolicyPayload: {},
        }
    },

    computed: {

    },

    methods: {
        deletePolicyLevel(id) {
            this.policyLevelToDelete = id;
        },

        deletePolicyLevelWithId() {
            this.loading = true;
            axios.delete(`/api/admin/travel-policy/level/${this.policyLevelToDelete}`)
                .then(() => {
                    this.getAll();
                    $('#deleteConfirmModal').modal('hide');
                    this.policyToDelete = null;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Adding new travel policy level from `#addItemModal` modal form
         */
        addNew() {
            this.loading = true;
            axios.post(`/api/admin/travel-policy/level`, this.newItemPayload)
                .then((response) => {
                    $('#addItemModal').modal('hide');
                    this.getAll();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Saves all regular travelPolicy records
         */
        saveAll() {
            this.loading = true;
            axios.put(`/api/admin/travel-policy/level/${this.globalUserId}`, this.travelPolicyLevels)
                .then((response) => {
                    console.log(response.data)
                    this.travelPolicyLevels = response.data
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Get all TravelPolicy records
         */
        getAll() {
            this.loading = true;

            axios.get(`/api/admin/travel-policy/level/${this.globalUserId}`)
                .then(response => {
                    this.travelPolicyLevels = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    created() {
        this.getAll();
    }
}
</script>

<style scoped>

</style>
