<template>
<section class="how-work work-standard">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="top-section-head popular-head aos" data-aos="fade-up">
                        <h2>How IT Works</h2>
                    </div>
                    <div class="section-heading section-heading-three text-center aos" data-aos="fade-up">
                        <span>Aliquam lorem ante, dapibus in, viverra quis</span>
                        <div class="section-three">
                            <h2>How It Works</h2>
                        </div>
                    </div>
                    <div class="howworksec aos" data-aos="fade-up">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork work-box">
                                    <div class="step-number">
                                        Step 1
                                    </div>
                                    <div class="iconround d-flex align-items-center justify-content-center">
                                        <img src="/images/work-icon-10.png" alt="">
                                    </div>
                                    <h3>Choose What To Do</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    <h2>01</h2>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork work-box hiw-arrow">
                                    <div class="step-number">
                                        Step 2
                                    </div>
                                    <div class="iconround d-flex align-items-center justify-content-center">
                                        <img src="/images/work-icon-11.png" alt="">
                                    </div>
                                    <h3>Find What You Want</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    <h2>02</h2>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork work-box">
                                    <div class="step-number">
                                        Step 3
                                    </div>
                                    <div class="iconround d-flex align-items-center justify-content-center">
                                        <img src="/images/work-icon-12.png" alt="">
                                    </div>
                                    <h3>Amazing Places</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                    <h2>03</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>