import { createStore, createLogger } from 'vuex'

import user from './modules/user'
import hotels from './modules/hotels'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        user,
        hotels,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
