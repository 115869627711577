<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Categories</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                                <router-link to="/admin/add-category" class="btn btn-primary add-button ms-3">
                                    <i class="fas fa-plus"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <!-- Search Filter -->
                    <categoryfilter></categoryfilter>
                    <!-- /Search Filter -->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="categories">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Category</th>
                                                    <th>Date</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in categories" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>
                                                        <img class="rounded service-img me-1" :src="loadImg(item.img)" alt="Category Image">{{item.name}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td class="text-end">
                                                        <router-link to="/admin/edit-category" class="btn btn-sm bg-success-light me-2">	<i class="far fa-edit me-1"></i> Edit</router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import categories from '../../../../assets/json/admin/categories.json'
    import util from '../../../../assets/utils/util'
	const images = require.context('../../../../assets/admin_img/category', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
        util.datatable('#categories')
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            categories: categories
        }
    }
	}
	</script>
