<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">Reviews</h4>
                    <div class="card review-card mb-0">
                        <div class="card-body">

                            <!-- Review -->
                            <div class="review-list" v-for="item in review" :key="item.id">
                                <div class="review-img">
                                    <img class="rounded img-fluid" :src="loadImg(item.img)" alt="">
                                </div>
                                <div class="review-info">
                                    <h5><a href="">{{item.name}}</a></h5>
                                    <div class="review-date">{{item.time}}</div>
                                    <p class="mb-2">{{item.name1}}</p>
                                    <div class="review-user">
                                        <img class="avatar-xs rounded-circle" :src="loadImg1(item.img1)" alt=""> {{item.name2}}
                                    </div>
                                </div>
                                <div class="review-count">
                                    <div class="rating mapgridrating1">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <span class="d-inline-block average-rating">{{item.rating}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- /Review -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import review from '../../../../../assets/json/website/review.json'
	const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../assets/img/customer', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            review: review
        }
    }
	}
	</script>