<template>
    <div style="min-height: 50vh">
        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="col-sm-12 mb-4" v-if="success">
            <p class="w-100 alert alert-success">
                {{ success }}
            </p>
        </div>

        <div class="row align-items-center mb-4">
            <div class="col">
                <h4 class="widget-title mb-0">Twoje rezerwacje</h4>
            </div>
            <div class="col-auto">
                <div class="sort-by">
                    <select class="form-control-sm custom-select" v-model="selectedStatus">
                        <option value="0">Wszystkie</option>
                        <option value="1">Zarezerwowane</option>
                        <option value="7">Anulowane</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="bookings" v-if="filteredBookings && filteredBookings.length > 0" v-for="item in filteredBookings" :key="item.id">
            <div class="booking-list">
                <div class="booking-widget">
                    <router-link to="/user-bookings" class="booking-img">
                        <img :src="item.post_data.hotel.image" alt="Hotel Image">
                    </router-link>
                    <div class="booking-det-info">
                        <h3>
                            <router-link to="/user-bookings">{{ item.post_data.hotel.name }}</router-link>
                        </h3>
                        <ul class="booking-details">
                            <li><span>Rezerwacja: </span> {{ item.reservation_number }}</li>
                            <li>
                                <span>Osoby: </span>
                                <ul>
                                    <li v-for="(room, roomIndex) in item.post_data.reservation.rooms" :key="roomIndex">
                                        <strong>Pokój {{ roomIndex + 1 }}: &nbsp;</strong>
                                        <ul>
                                            <li v-for="(person, personIndex) in room.persons" :key="personIndex">
                                                {{ person.firstname }} {{ person.lastname }}
                                            </li>
                                        </ul>
                    c                </li>
                                </ul>
                            </li>
                            <li><span>Pokoje: </span> {{ item.post_data.reservation.rooms.length }}</li>
                            <li><span>Liczba osób: </span> {{ item.post_data.reservation.rooms[0].persons.length }}</li>
                            <li v-if="item.booking_reservation_id && item.booking_reservation_id != '0'">
                                <span>Nr rezerwacji dostawcy: </span>
                                {{ item.booking_reservation_id }} &nbsp;
                                <span v-if="item.booking_data && item.booking_data.bookingDetails && item.booking_data.bookingDetails.hotel.rooms.room.bookingReference">
                                    ({{ item.booking_data.bookingDetails.hotel.rooms.room.bookingReference }})
                                </span>
                            </li>
                            <li>
                                <span>Status:</span>
                                <span
                                    :class="{'bg-danger': item.status7, 'bg-success': !item.status7}"
                                    class="badge ml-0 badge-pill badge-prof">
                                    {{ item.status7 ? 'Anulowana' : 'Zarezerwowany' }}
                                </span>
                            </li>
                            <li><span>Daty:</span> {{ item.check_in }} do {{ item.check_out }}</li>
                            <li><span>Suma: </span> {{ Math.ceil(item.price_brutto_pln) }} zł</li>
                            <li><span>Adres: </span> {{ item.post_data.hotel.address }}<br>{{ item.post_data.city }}, {{ item.post_data.country }}</li>
                        </ul>
                    </div>
                </div>
                <!-- Vouchers Section -->
                <div class="booking-action">
                    <!-- Voucher Buttons -->
                    <div class="w-75" v-if="item.vouchers && item.vouchers.length > 0">
                        <ul>
                            <li v-for="voucher in item.vouchers" :key="voucher.id">
                                <a href="#" @click.prevent="downloadVoucher(voucher.id, 'pl')" class="btn btn-sm bg-primary-light d-block mb-2">
                                    <i class="fas fa-file-pdf"></i> Pobierz voucher PL
                                </a>
                                <a href="#" @click.prevent="downloadVoucher(voucher.id, 'en')" class="btn btn-sm bg-light d-block mb-2">
                                    <i class="fas fa-file-pdf"></i> Download Voucher EN
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="w-75" v-if="item.invoices && item.invoices.length > 0">
                        <ul>
                            <li v-for="invoice in item.invoices" :key="invoice.id">
                                <a :href="`/invoice/${invoice.id}/download`" target="_blank"  class="btn btn-sm bg-success-light d-block mb-2">
                                    <i class="fas fa-file-pdf"></i> Pobierz fakturę
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- Cancel Booking Button -->
                    <a href="#" v-if="!item.status7" data-toggle="modal" data-target="#cancelConfirmModal"
                       @click="cancel(item.id)" class="btn btn-sm bg-danger-light w-75">
                        <i class="fas fa-times"></i> Anuluj rezerwację
                    </a>
                </div>
            </div>
        </div>

        <div v-else>
            <p class="text-muted">Brak rezerwacji do wyświetlenia</p>
        </div>


        <div class="modal fade" id="cancelConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz anulować rezerwację?</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="cancelReservation" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import bookinglist from '../../../../../assets/json/website/bookinglist.json'

const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
const images1 = require.context('../../../../../assets/img/provider', false, /\.png$|\.jpg$/)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {

    components: {
        Loading,
    },

    data() {
        return {
            bookinglist: bookinglist,

            bookings: null,
            selectedStatus: "0", // Default to "Wszystkie"
            error: null,
            loading: false,
            success: false,

            reservationToCancel: null,

            userId: 1,
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),

        filteredBookings() {
            if (this.bookings) {
                if (this.selectedStatus === "0") {
                    // Show all bookings
                    return this.bookings;
                } else if (this.selectedStatus === "1") {
                    // Show only "Zarezerwowane" (status7 == 0)
                    return this.bookings.filter(item => !item.status7);
                } else if (this.selectedStatus === "7") {
                    // Show only "Anulowane" (status7 == 1)
                    return this.bookings.filter(item => item.status7);
                }
            }
            return [];
        },
    },

    methods: {
        getReservations() {
            this.loading = true;
            axios.get(`/api/user/${this.globalUserId}/reservations`)
                .then(res => {
                    this.bookings = res.data.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },

        cancel(id) {
            this.reservationToCancel = id;
        },

        cancelReservation() {
            this.loading = true;
            axios.post('/api/reservation/cancel', {reservation: this.reservationToCancel})
                .then(res => {
                    console.log(res);
                    this.success = res.data;
                    $('#cancelConfirmModal').modal('hide');
                    this.reservationToCancel = null;
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                this.loading = false;
            })
        },

        downloadVoucher(voucherId, language) {
            this.loading = true;

            // Call the backend to generate and stream the PDF
            axios({
                method: 'get',
                url: `/api/voucher/${voucherId}/pdf?language=${language}`, // Pass the language as a query param
                responseType: 'blob', // Ensure we handle binary data
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Voucher-${voucherId}-${language}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    console.error('Error downloading voucher PDF:', error.response?.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        downloadInvoice(invoiceId) {
            this.loading = true;

            axios({
                method: 'get',
                url: `/api/invoice/${invoiceId}/download`, // Adjust the endpoint to match your backend
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Invoice-${invoiceId}.pdf`); // Customize the filename
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    console.error('Error downloading invoice:', error.response?.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },

    mounted() {
        this.getReservations();
        $('.fav-btn .fav-icon').on('click', function () {
            $(this).toggleClass('favourite');
        });
    },
}
</script>
