<template>

	<div class="main-wrapper">

	<layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">My Services</h4>
                    <ul class="nav nav-tabs menu-tabs">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/my-services">Active Services</router-link>
                        </li>
                        <li class="nav-item active">
                            <router-link class="nav-link" to="/my-services-inactive">Inactive Services</router-link>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 inactive-service" v-for="item in serviceinactive" :key="item.id">
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" :src="loadImg(item.img)">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <a href="javascript:void(0);">
                                                <img :src="loadImg1(item.img1)" alt="">
                                            </a>
                                            <span class="service-price ms-1">{{item.rate}}</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">{{item.name}}</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">{{item.name1}}</router-link>
                                    </h3>
                                    <div class="rating mapgridrating1">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <span class="d-inline-block average-rating">{{item.rating}}</span>
                                    </div>
                                    <div class="user-info">
                                        <div class="service-action">
                                            <div class="row">
                                                <div class="col">
                                                    <a href="javascript:void(0)" class="si-delete-inactive-service text-danger" data-id="149"><i class="far fa-trash-alt"></i> Delete</a>
                                                </div>
                                                <div class="col text-end">
                                                    <a href="javascript:void(0)" class="si-delete-active-service text-success" data-id="149"><i class="fas fa-info-circle"></i> Active</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <myservicemodal></myservicemodal>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import serviceinactive from '../../../../../assets/json/website/serviceinactive.json'
	const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../assets/img/provider', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            serviceinactive: serviceinactive
        }
    }
	}
	</script>