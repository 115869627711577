<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <wallettab></wallettab>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="wallethistory">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Current Amt</th>
                                                    <th>Credit Amt</th>
                                                    <th>Debit Amt</th>
                                                    <th>Fee Amt</th>
                                                    <th>Available Amt</th>
                                                    <th>Reason</th>
                                                    <th>Pay Type</th>
                                                    <th>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in wallethistory" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name}}</a>
                                                        </h2>
                                                    </td>
                                                    <td>{{item.amount}}</td>
                                                    <td>{{item.no1}}</td>
                                                    <td>{{item.rate}}</td>
                                                    <td>{{item.no2}}</td>
                                                    <td>{{item.amount1}}</td>
                                                    <td>{{item.description}}</td>
                                                    <td><span class="btn btn-sm btn-danger">{{item.button1}}</span>
                                                    </td>
                                                    <td>
                                                        <label class="badge badge-primary">{{item.button2}}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    import wallethistory from '../../../../assets/json/admin/wallet/wallethistory.json'
    import util from '../../../../assets/utils/util'
	const images = require.context('../../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
    import Vue from 'vue'
    export default {
        methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
      },
      data() {
      return {
      duration: ["Select Duration", "One Month", "3 Months", "6 Months", "One Year", "2 Years"],
      wallethistory: wallethistory
      }
      },
      mounted() {
        util.datatable('#wallethistory')
      },
    }
  </Script>