<template>

    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Panel informacyjny - <small>Pracownicy</small></h3>
                            </div>

                            <div class="col-auto text-center d-flex justify-content-between " v-if="isSuperadmin || isClient">
                                <router-link :to="{name: '/admin/information/users/create'}" class="btn btn-primary mr-3">Dodaj</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table id="information-prices" class="table table-hover table-center mb-0 datatable" v-if="users && users.length > 0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>E-mail</th>
                                                <th>Telefon</th>
                                                <th>Utworzono</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(user, index) in users" :key="index">
                                                <td>{{ user.id }}</td>
                                                <td>
                                                    <h2 class="table-avatar">
                                                        <a href="javascript:void(0);">{{ user.firstname }} {{ user.lastname }}</a>
                                                    </h2>
                                                </td>
                                                <td>{{ user.email }}</td>
                                                <td>{{ user.phone }}</td>
                                                <td>{{ $filters.formatDate(user.created_at)  }}</td>
                                                <td>
                                                    <router-link :to="`/admin/information/users/edit/${user.id}`" v-if="user.status === 'active'" class="btn btn-sm bg-info-light"><i class="far fa-eye me-1"></i> Edytuj </router-link>
                                                </td>

                                            </tr>
                                            </tbody>
                                        </table>

                                        <div class="text-center py-5" v-else>
                                            <p>Brak przypisanych pracowników</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import AdminInformationFiles from "./AdminInformationFiles";
import AdminInformationPrices from "./AdminInformationPrices";
import AdminInformationUsers from "./AdminInformationUsers";

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            users: [],
        }
    },

    methods: {
        getAll() {
            this.loading = true;
            axios.get(`/api/admin/information/users/${this.globalUserId}`)
                .then(response => {
                    this.users = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.getAll();
    }
}
</script>

<style scoped>

</style>
