<template>
    <!-- Hero Section -->
    <section class="hero-section">
        <div class="layer">
            <div class="home-banner"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="section-search aos" data-aos="fade-up">
                            <h3>Baza ponad 2 milionów <span>hoteli</span></h3>
                            <p>z całego świata!</p>
                            <div class="search-box">
                                <form>
                                    <div class="search-input">
                                        <i class="fas fa-location-arrow bficon"></i>
                                        <div class="form-group mb-0">
                                            <simple-typeahead
                                                id="typeahead_id"
                                                placeholder="Dokąd jedziesz?"
                                                :items="searchItems"
                                                :minInputLength="1"
                                                :itemProjection="itemProjectionFunction"
                                                @selectItem="selectItemEventHandler"
                                            >
                                            </simple-typeahead>
                                        </div>
                                    </div>

                                    <div class="search-input">
                                        <i class="fas fa-calendar bficon"></i>
                                        <div class="form-group mb-0">
                                            <VueDatePicker
                                                v-model="date"
                                                placeholder="Kiedy jedziesz?"
                                                :min-date="new Date()"
                                                model-type="yyyy-MM-dd"
                                                format="dd MMM"
                                                range
                                                auto-apply
                                                multi-calendars
                                                :format-locale="lang"
                                                :enable-time-picker="false"
                                            ></VueDatePicker>
                                        </div>
                                    </div>
                                    <div class="search-input">
                                        <i class="fas fa-user bficon"></i>
                                        <div class="form-group mb-0">
                                            <div class="form-guests">
                                                <span @click.prevent="openGuestsMenu" type="text" class=""><small>Dorośli: <strong>{{ adults }}</strong>, Dzieci: <strong>{{ children }}</strong></small></span>
                                            </div>

                                            <div class="search-input__guests-box" v-show="guestBox">
                                                <div class="guests-box-item">
                                                    <label for="adults">Dorośli</label>
                                                    <button class="" @click.prevent="decreaseAdults">-</button>
                                                    <input type="number" name="adults" v-model="adults" step="1" max="3" class="form-control">
                                                    <button class="" @click.prevent="increaseAdults">+</button>
                                                </div>

                                                <div class="guests-box-item">
                                                    <label for="adults">Dzieci</label>
                                                    <button class="" @click.prevent="decreaseChildren">-</button>
                                                    <input type="number" name="adults" v-model="children" step="1" max="3" class="form-control">
                                                    <button class="" @click.prevent="increaseChildren">+</button>
                                                </div>

                                                <hr>

                                                <div class="guests-box-item-age" v-if="children > 0">
                                                    <strong>Wiek dzieci: </strong> (wymagany)<br>
                                                    <select v-if="children > 0" name="ageOne" v-model="ageOne" id="">
                                                        <option v-for="(n, i) in 15" :selected="ageOne == i" :value="i">{{ i }}</option>
                                                    </select>
                                                    <select v-if="children > 1" name="ageTwo" v-model="ageTwo" id="">
                                                        <option v-for="(n, i) in 15" :selected="ageTwo == i" :value="i">{{ i }}</option>
                                                    </select>
                                                    <select v-if="children > 2" name="ageThree" v-model="ageThree" id="">
                                                        <option v-for="(n, i) in 15" :selected="ageThree == i" :value="i">{{ i }}</option>
                                                    </select>
                                                    <hr>
                                                </div>
                                                <button class="btn btn-primary" @click.prevent="openGuestsMenu" type="submit">Zastosuj</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="search-btn">
                                        <router-link
                                            v-if="destination && date"
                                            :to="{
                        name: 'search',
                        params: {
                          page: 1,
                          destination: destination,
                          checkin: date?.[0],
                          checkout: date?.[1],
                          adults: adults,
                          children: children,
                          ageOne: ageOne,
                          ageTwo: ageTwo,
                          ageThree: ageThree,
                        }
                      }"
                                            class="btn search_service"
                                        >
                                            Szukaj
                                        </router-link>
                                        <button v-else class="btn search_service" disabled title="Wybierz datę i destynację" type="submit">Szukaj</button>
                                    </div>
                                </form>
                            </div>
                            <div class="search-cat" v-if="false">
                                <i class="fas fa-circle"></i>
                                <span class="ms-1">Popular Searches</span>
                                <router-link to="/search">Electrical Works</router-link>
                                <router-link to="/search">Cleaning</router-link>
                                <router-link to="/search">AC Repair</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Hero Section -->
</template>

<script setup>
import { ref } from 'vue';
import { pl } from 'date-fns/locale';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; // Optional default CSS

const date = ref(null);
const lang = pl;
const adults = ref(1);
const children = ref(0);
const destination = ref(null);
const guestBox = ref(false);
const ageOne = ref(0);
const ageTwo = ref(0);
const ageThree = ref(0);
const searchItems = ref([
    {
        title: 'Zakopane | Polska',
        id: 30420
    },
    {
        title: 'Kraków | Polska',
        id: 30385
    },
    {
        title: 'Szczawnica | Polska',
        id: 30474
    }
]);

const increaseAdults = () => {
    if(adults.value < 6) {
        adults.value++;
    }
};

const decreaseAdults = () => {
    if (adults.value !== 1) {
        adults.value--;
    }
};

const increaseChildren = () => {
    if (children.value !== 3) {
        children.value++;
    }
};

const decreaseChildren = () => {
    if (children.value !== 0) {
        children.value--;
    }
};

const openGuestsMenu = () => {
    guestBox.value = !guestBox.value;
};

const selectItemEventHandler = (item) => {
    destination.value = item.id;
};

const itemProjectionFunction = (item) => {
    return item.title;
};

const getDestinations = () => {
    axios.get(`/api/destinations`)
        .then(res => {
            searchItems.value = res.data;
        })
        .catch(error => {
            console.log(error);
        });
};

getDestinations();
</script>
