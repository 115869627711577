<template>
    <div class="col-md-6 d-flex">

        <!-- Recent Bookings -->
        <div class="card card-table flex-fill">
            <div class="card-header">
                <h4 class="card-title">Nowe rezerwacje</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-center">
                        <thead>
                            <tr>
                                <th>Użytkownik</th>
                                <th>Od - Do</th>
                                <th>Hotel</th>
                                <th>Status</th>
                                <th>Kwota</th>
                            </tr>
                        </thead>
                        <tbody v-if="reservations">
                            <tr v-for="item in reservations" :key="item.id">
                                <td class="text-nowrap">
                                    {{item.firstname }} {{item.lastname }}
                                </td>
                                <td class="text-nowrap">{{item.check_in}} <br> {{item.check_out}}</td>
                                <td>{{item.post_data.hotel.name}}</td>
                                <td>
                                    <span class="badge bg-success inv-badge">Potwierdzona</span>
                                </td>
                                <td>
                                    <div class="font-weight-600">{{item.price_brutto_pln}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- /Recent Bookings -->

    </div>
</template>
<script>
	import list from '../../../../assets/json/admin/index/list.json'
    import util from '../../../../assets/utils/util'
    import axios from "axios";
	const images = require.context('../../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
	export default {
        data() {
            return {
                list: list,
                loading: false,
                reservations: null,
            }
        },
        methods:{
            latestReservations() {
                this.loading = true;
                axios.get('/api/admin/reservation/latest')
                    .then(response => {
                        this.reservations = response.data.data;
                        console.log(response);
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            loadImg(imgPath) {
                return images('./' + imgPath).default
            },
        },
        created() {

            this.latestReservations();
        }
	}
	</script>
