<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <user-header></user-header>

                    <div class="row" v-if="user">

                        <div class="col-md-12" v-if="success">

                            <div class="card">
                                <div class="card-body p-5 text-center">
                                    <h3 class="mt-3 mb-3 text-success">Uzytkownik edytowany</h3>
                                    <p class="text-success">Gratulacje! Ten użytkownik został edytowany.</p>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Edytuj użytkownika</h5>
                                </div>
                                <div class="card-body pt-4">
                                    <form  @submit.prevent="editUser">
                                        <div class="settings-form">
                                            <div class="row">

                                                <div class="col-md-12" v-if="user.client_id_admin_panel && role === 'superadmin'">
                                                    <div class="form-group">
                                                        <label>Klient <span class="star-red">*</span></label>
                                                        <select class="form-control" v-model="payload.client_id_admin_panel">
                                                            <option value="0">- Wybierz -</option>
                                                            <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2 form-group">
                                                    <label>Tytuł <span class="star-red">*</span></label>
                                                    <select name="title" v-model="payload.title" class="form-control" required>
                                                        <option value="MR" selected>Pan</option>
                                                        <option value="MRS">Pani</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-5 form-group">
                                                    <label>Imię <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.firstname" name="firstname" required>
                                                </div>
                                                <div class="col-sm-5 form-group">
                                                    <label>Nazwisko <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.lastname" name="lastname" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>E-mail <span class="star-red">*</span></label>
                                                    <input type="email" class="form-control" v-model="payload.email" name="email" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Telefon <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.phone" name="phone" required>
                                                </div>

                                                <div class="col-sm-12 form-group">
                                                    <label>Ulica <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.street" name="street" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Kod pocztowy <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.postal_code" name="postal_code" required>
                                                </div>
                                                <div class="col-sm-6 form-group">
                                                    <label>Miasto <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.city" name="city" required>
                                                </div>

                                                <div class="col-sm-4 form-group">
                                                    <label>Państwo <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.country" name="country" required>
                                                </div>

                                                <div class="col-sm-4 form-group">
                                                    <label>Status użytkownika <span class="star-red">*</span></label>
                                                    <select name="title" v-model="payload.status" class="form-control" required>
                                                        <option value="active" >Aktywny</option>
                                                        <option value="disabled">Nieaktywny</option>
                                                        <option value="blocked">Zablokowany</option>
                                                    </select>
                                                </div>

                                                <div class="col-sm-4 form-group">
                                                    <label>Zniżka <span class="star-red">*</span></label>
                                                    <input type="number" step="1" class="form-control" v-model="payload.discount" name="discount">
                                                </div>

                                                <div class="col-sm-12 my-4">
                                                    <hr>
                                                </div>


                                                <div class="col-sm-12 form-group">
                                                    <label>Nazwa firmy <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" name="company_name" v-model="payload.company_name" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>NIP <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_vat"  name="company_vat" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>E-mail - Firma <span class="star-red">*</span></label>
                                                    <input type="email" class="form-control" v-model="payload.company_email" name="company_email" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Telefon - Firma <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_phone" name="company_phone" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Kod pocztowy - Firma <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_postal_code" name="company_postal_code" required>
                                                </div>

                                                <div class="col-sm-12 form-group">
                                                    <label>Ulica - Firma <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_street" name="company_street" required>
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Miasto - Firma <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_city" name="company_city" required>
                                                </div>

                                                <div class="col-sm-4 form-group">
                                                    <label>Państwo - Firma <span class="star-red">*</span></label>
                                                    <input type="text" class="form-control" v-model="payload.company_country" name="company_country" required>
                                                </div>

                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="settings-btns">
                                                    <button class="btn btn-primary" type="submit">Edytuj</button>
                                                    <router-link :to="{name: '/admin/users'}" class="btn btn-grey" >Anuluj</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </diV>
    </div>
</template>
<script>

import axios from "axios";

export default {
    data() {
        return {
            user: null,
            loading: false,
            success: false,
            clients: [],
            payload: {
                client_id_admin_panel: 0,
                title: null,
                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                street: null,
                postal_code: null,
                city: null,
                country: null,
                company_vat: null,
                status: null,
                discount: null,
                company_name: null,
                company_street: null,
                company_city: null,
                company_postal_code: null,
                company_country: null,
                company_phone: null,
                company_email: null,
            },
        }
    },

    methods: {
        getUser() {
            this.loading = true;
            axios.get(`/api/user/get/${this.$route.params.id}`)
                .then(res => {
                    this.user = res.data;
                    this.setUser();
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        getClients() {
            this.loading = true;
            axios.get('/api/admin/clients/get')
                .then(res => {
                    this.clients = res.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        editUser() {
            this.loading = true;
            axios.post(`/api/admin/user/edit/${this.user.id}`, this.payload)
                .then(res => {
                    this.success = true;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        setUser() {
            if(this.user) {
                this.payload.client_id_admin_panel = this.user.client_id_admin_panel ?? null;
                this.payload.title = this.user.title;
                this.payload.firstname = this.user.firstname;
                this.payload.lastname = this.user.lastname;
                this.payload.email = this.user.email;
                this.payload.phone = this.user.phone;
                this.payload.street = this.user.street;
                this.payload.postal_code = this.user.postal_code;
                this.payload.city = this.user.city;
                this.payload.country = this.user.country;
                this.payload.company_vat = this.user.company_vat;
                this.payload.status = this.user.status;
                this.payload.discount = this.user.discount;
                this.payload.company_name = this.user.company_name;
                this.payload.company_street = this.user.company_street;
                this.payload.company_city = this.user.company_city;
                this.payload.company_postal_code = this.user.company_postal_code;
                this.payload.company_country = this.user.company_country;
                this.payload.company_phone = this.user.company_phone;
                this.payload.company_email = this.user.company_email;
            }
        },

        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },

    mounted() {
        this.getUser();
        this.getClients();
    },

}
</script>
