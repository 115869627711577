<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row pricing-box">
                        <div class="col-xl-4 col-md-6" v-for="item in subscription" :key="item.id">
                            <div class="card">
                                <div class="card-body">
                                    <div class="pricing-header">
                                        <h2>{{item.name}}</h2>
                                        <p>{{item.name1}}</p>
                                    </div>
                                    <div class="pricing-card-price">
                                        <h3 class="heading2 price">{{item.rate}}</h3>
                                        <p>{{item.name2}} <span>{{item.time}}</span></p>
                                    </div>
                                    <ul class="pricing-options">
                                        <li><i class="far fa-check-circle"></i> {{item.description}}</li>
                                        <li><i class="far fa-check-circle"></i> {{item.description1}}</li>
                                    </ul>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">{{item.button}}</a>
                                </div>
                            </div>
                        </div>
                        <enterprice></enterprice>
                    </div>
                    <plandetail></plandetail>
                    <h5 class="mb-4">Subscribed Details</h5>
                    <subscriptiontable></subscriptiontable>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import subscription from '../../../../../assets/json/website/subscription.json'
	export default {
	mounted() {
	},
	data() {
        return {
            subscription: subscription
        }
    }
	}
	</script>