<template>
<div class="col-lg-4 theiaStickySidebar">
    <div class="stickyside">
                    <div class="sidebar-widget widget">
                        <div class="service-amount">
                            <span>$150</span>
                        </div>
                        <div class="service-book">
                            <router-link to="/book-service" class="btn btn-primary"> Book Service </router-link>
                        </div>
                    </div>
                    <div class="card provider-widget clearfix">
                        <div class="card-body">
                            <h5 class="card-title">Service Provider</h5>
                            <div class="about-author">
                                <div class="about-provider-img">
                                    <div class="provider-img-wrap">
                                        <a href="javascript:void(0);">
                                            <img class="img-fluid rounded-circle" alt="" src="/images/provider/provider-01.jpg">
                                        </a>
                                    </div>
                                </div>
                                <div class="provider-details">
                                    <a href="javascript:void(0);" class="ser-provider-name">Thomas Herzberg</a>
                                    <p class="last-seen"><i class="fas fa-circle online"></i> Online</p>
                                    <p class="text-muted mb-1">Member Since Apr 2020</p>
                                </div>
                            </div>
                            <hr>
                            <div class="provider-info">
                                <p class="mb-1"><i class="far fa-envelope"></i> <a href="javascript:void(0);">thomasherzberg@example.com</a></p>
                                <p class="mb-0"><i class="fas fa-phone-alt"></i> xxxxxxxx33</p>
                            </div>
                        </div>
                    </div>
                    <div class="card available-widget">
                        <div class="card-body">
                            <h5 class="card-title">Service Availability</h5>
                            <ul>
                                <li><span>Monday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Tuesday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Wednesday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Thursday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Friday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Saturday</span>9:30 AM - 7:00 PM</li>
                                <li><span>Sunday</span>9:30 AM - 7:00 PM</li>
                            </ul>
                        </div>
                    </div>
    </div>
</div>
</template>