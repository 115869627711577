<template>
    <div>
        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="service-view" v-if="details">
            <div class="service-header">
                <div class="d-flex justify-content-between align-items-center">
                    <h1> {{ details.name }}</h1>
                    <div class="fav-btn fav-btn-big" v-show="false">
                        <a href="javascript:void(0)" class="fav-icon with-border">
                            <i class="fas fa-heart"></i>
                        </a>
                    </div>
                </div>
                <address class="service-location"><i class="fas fa-location-arrow"></i> {{ details.details.address.city }},
                    {{ details.details.address.country }}
                </address>

                <div class="col-sm-12 rating mapgridrating1"
                     v-if="details.details.starRating && details.details.starRating > 0">
                    <i class="fas fa-star" :class="{ 'filled': details.details.starRating > 0 }"></i>
                    <i class="fas fa-star" :class="{ 'filled': details.details.starRating > 1 }"></i>
                    <i class="fas fa-star" :class="{ 'filled': details.details.starRating > 2 }"></i>
                    <i class="fas fa-star" :class="{ 'filled': details.details.starRating > 3 }"></i>
                    <i class="fas fa-star" :class="{ 'filled': details.details.starRating > 4 }"></i>
                    <span class="d-inline-block average-rating">({{ details.details.starRating }})</span>
                </div>

                <div class="service-cate">
                    <router-link to="#">Hotel</router-link>
                </div>
            </div>

            <div class="service-images service-carousel-view slider" v-if="false">

                <div
                    v-if="details.details.image && details.details.image[0]"
                    v-for="image in details.details.image"
                    class="item"
                >
                    <img :src="image.url" alt="" class="img-fluid">
                </div>


            </div>

            <div id="hotel-details__gallery" class="carousel slide" data-ride="carousel" align="center">
                <!-- slides -->
                <div class="carousel-inner">
                    <div class="carousel-item"
                         v-if="details.details.image && details.details.image[0]"
                         v-for="(image, index) in details.details.image"
                         :class="{ 'active': index === 0 }"
                    >
                        <div class="carousel-item-photo" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                    </div>

                </div>

                <!-- Left right -->
                <a class="carousel-control-prev" href="#hotel-details__gallery" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#hotel-details__gallery" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>

                <!-- Thumbnails -->
                <ol class="carousel-indicators list-inline">
                    <li class="list-inline-item"
                        :class="{ 'active': index === 0 }"
                        v-if="details.details.image && details.details.image[0]"
                        v-for="(image, index) in details.details.image">
                        <a :id="`carousel-selector-${ index }`" class="selected" :data-slide-to="index"
                           data-target="#hotel-details__gallery">
                            <div class="carousel-item-thumb" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                        </a>
                    </li>
                </ol>
            </div>


            <div class="service-details mt-5">
                <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                           aria-controls="pills-home" aria-selected="true">Opis</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab"
                           aria-controls="pills-details" aria-selected="false">Szczegóły</a>
                    </li>

                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="card service-description">
                            <div class="card-body">
                                <h5 class="card-title">Opis</h5>
                                <p class="mb-0" v-html="details.details.descriptions.description._"></p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                        <div class="card service-description">
                            <div class="card-body">
                                <h5 class="card-title">Szczegóły</h5>
                                <ul class="pl-5" style="list-style: circle">
                                    <li v-for="feature in details.details.feature">{{ feature.name }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="hotel-rooms" class="bookings" v-if="details.channel.room && details.channel.room[0]"
                 v-for="(item, index) in details.channel.room" :key="index">
                <div class="booking-list">
                    <div class="booking-widget">
                        <div class="booking-det-info">
                            <h3>
                                <router-link to="/service-details">{{ item.roomType.name }}</router-link>
                            </h3>
                            <p v-if="$filters.isCancellationAvailable(item.cancellationPolicy.cxlDate)" class="text-success">
                                Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(item.cancellationPolicy.cxlDate) }}</strong>
                            </p>
                            <p v-else class="text-danger">
                                Brak możliwości bezpłatnego odwołania
                            </p>
                            <ul class="booking-details">
                                <li>
                                    <span>Wyżywienie</span> {{ item.boardType.name }} <span
                                    class="badge badge-pill badge-prof bg-primary"> Wolny </span>
                                </li>
                                <li v-if="rate"><span>Cena za noc</span> {{ (item.price * 1.10 * this.rate).toFixed(2) }} PLN</li>
                            </ul>
                        </div>
                    </div>
                    <div class="booking-action">
                        <router-link

                            :to="{
                    name: 'book',
                    params: {
                      hotelId: payload.hotelId,
                      roomId: item.id,
                      boardTypeId: item.boardType.id,
                      roomTypeId: item.roomType.id,
                      checkin: payload.checkin,
                      checkout: payload.checkout,
                      adults: payload.adults,
                      children: payload.children,
                      ageOne: payload.ageOne,
                      ageTwo: payload.ageTwo,
                      ageThree: payload.ageThree,
                     }
                    }"
                            class="btn btn-sm btn-primary"
                        >
                            Rezerwuj
                        </router-link>
                        <a href="#" v-if="false" class="btn btn-sm btn-primary">Rezerwuj</a>
                    </div>
                </div>
            </div>

            <div id="hotel-rooms" class="bookings" v-else>
                <div class="booking-list">
                    <div class="booking-widget">
                        <div class="booking-det-info">
                            <h3>
                                <router-link to="/service-details">{{ details.channel.room.roomType.name }}</router-link>
                            </h3>
                            <p v-if="$filters.isCancellationAvailable(details.channel.room.cancellationPolicy.cxlDate)" class="text-success">
                                Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(details.channel.room.cancellationPolicy.cxlDate) }}</strong>
                            </p>
                            <p v-else class="text-danger">
                                Brak możliwości bezpłatnego odwołania
                            </p>                            <ul class="booking-details">
                                <li>
                                    <span>Wyżywienie</span> {{ details.channel.room.boardType.name }} <span
                                    class="badge badge-pill badge-prof bg-primary"> Wolny </span>
                                </li>
                                <li v-if="rate"><span>Cena za noc</span> {{ (details.channel.room.price * 1.10 * this.rate).toFixed(2) }} PLN</li>
                            </ul>
                        </div>
                    </div>
                    <div class="booking-action">
                        <router-link

                            :to="{
                    name: 'book',
                    params: {
                      hotelId: payload.hotelId,
                      roomId: details.channel.room.id,
                      boardTypeId: details.channel.room.boardType.id,
                      roomTypeId: details.channel.room.roomType.id,
                      checkin: payload.checkin,
                      checkout: payload.checkout,
                      adults: payload.adults,
                      children: payload.children,
                      ageOne: payload.ageOne,
                      ageTwo: payload.ageTwo,
                      ageThree: payload.ageThree,
                     }
                    }"
                            class="btn btn-sm btn-primary"
                        >
                            Rezerwuj
                        </router-link>
                        <a v-if="false" href="#" class="btn btn-sm btn-primary">Rezerwuj</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {

    props: {
        details: Object,
        rate: Number,
    },

    data() {
        return {
            hotelId: '',
            checkin: null,
            checkout: null,
            adults: null,
            children: null,

            loading: false,

            ageOne: 0,
            ageTwo: 0,
            ageThree: 0,

            availability: null,
            bookingRemarks: null,

            payload: {
                hotelId: null,
                roomId: null,
                boardTypeId: null,
                roomTypeId: null,
                checkin: null,
                checkout: null,
                adults: null,
                children: null,
                ageOne: 0,
                ageTwo: 0,
                ageThree: 0,
            },

        }
    },

    methods: {

    },

    created() {
        if (this.$route.params.checkin && this.$route.params.checkout) {
            this.payload.hotelId = this.$route.params.id;
            this.payload.checkin = this.$route.params.checkin;
            this.payload.checkout = this.$route.params.checkout;
            this.payload.adults = this.$route.params.adults;
            this.payload.children = this.$route.params.children;
            this.payload.ageOne = this.$route.params.ageOne;
            this.payload.ageTwo = this.$route.params.ageTwo;
            this.payload.ageThree = this.$route.params.ageThree;
        }
    },
}
</script>
<style scoped>
.container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
}

#hotel-details__gallery .carousel-indicators {
    position: static;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    width: 100%;
    right: 0;
    bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.carousel-control-prev,
.carousel-control-next {
    height: 520px;
}


#hotel-details__gallery .carousel-indicators > li {
    width: 59px;
    height: 27px;
}

#hotel-details__gallery .carousel-indicators > li > a {
    width: 65px;
    height: 60px;
    display: block;
}

#hotel-details__gallery .carousel-indicators li img {
    display: block;
    opacity: 0.5;
}

#hotel-details__gallery .carousel-indicators li.active img {
    opacity: 1;
}

#hotel-details__gallery .carousel-indicators li:hover img {
    opacity: 0.75;
}

.carousel-item img {

    width: 100%;
}

.carousel-item-photo {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
}

.carousel-item-thumb {
    width: 66px;
    height: 43px;
    background-size: cover;
    background-position: center;
}
</style>
