<template>

  <div>

    <div class="main-wrapper">

      <layout-header></layout-header>

      <hero></hero>

      <category></category>

      <popular></popular>

      <section class="call-us">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <span>Największa baza hoteli</span>
              <h2>Zatrzymaj się w naszych wyjątkowych obiektach</h2>
              <p>Od zamków i willi po łodzie i igloo – mamy wszystko</p>
            </div>
            <div class="col-6 call-us-btn">
              <a href="#app" class="btn btn-call-us">Wyszukaj hotel</a>
            </div>
          </div>
        </div>
      </section>

      <section class="about-us">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <div class="about-blk-content">
                  <div class="viewall aos aos-init aos-animate mb-4" data-aos="fade-up">

                    <span class="double-color">Dlaczego my?</span><br>
                  </div>
                  <p><strong>Dzielimy z Państwem zamiłowanie do podróży, dzięki któremu z powodzeniem działamy w branży turystycznej nieustannie podnosząc jakość naszych usług.</strong>
                    <br><br>
                    Kredyt zaufania, który otrzymujemy od Państwa każdego dnia, umożliwia realizację niepowtarzalnych projektów o międzynarodowym zasięgu. Zorganizowaliśmy tysiące podróży w wielu zakątkach świata. Wsparliśmy wiedzą i doświadczeniem setki Klientów, którzy dzięki naszym usługom mają pewność szybkiej i profesjonalnej obsługi w korzystnej cenie.</p>
                </div>
              </div>
              <div class="col-6">
                <div class="about-blk-image mt-5 ml-4">
                  <img src="../../../../../assets/img/home/home-login-form-bg.jpg" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="how-work" v-if="false">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="heading howitworks aos" data-aos="fade-up">
                <h2>How It Works</h2>
                <span>Aliquam lorem ante, dapibus in, viverra quis</span>
              </div>

              <work></work>
            </div>
          </div>
        </div>
      </section>


      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {

  data() {
    return {

    }
  },

  methods: {
    getDestinations() {
      axios.post('/api/destinations', {service: 'hotelston'})
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          });
    },
  },

  mounted() {
    $('.fav-btn .fav-icon').on('click', function () {
      $(this).toggleClass('favourite');
    });

    // this.getDestinations();
  },
}
</script>
