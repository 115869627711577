<template>

    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Panel informacyjny - <small>Informacje o firmie</small></h3>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body" v-if="content">
                                    <div class="text-center" v-if="isSuperadmin">
                                        <editor v-model="content" />
                                        <button class="btn btn-primary p-4 w-100 mt-4" type="submit" @click.prevent="submit">Zapisz informacje</button>
                                    </div>
                                    <p class="text-center"  v-else v-html="content"></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

import AdminInformationFiles from "./AdminInformationFiles";
import AdminInformationPrices from "./AdminInformationPrices";
import AdminInformationUsers from "./AdminInformationUsers";

export default {
    components: {
        Loading,
        EditorContent,
    },

    data() {
        return {
            loading: false,
            content: null,
            editor: null,
            success: null,
            error: null,
        }
    },

    methods: {
        getContent() {
            this.loading = true;
            axios.get(`/api/admin/information/info/company-information`)
                .then(response => {
                    this.content = response.data.text;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        submit() {
            this.loading = true;
            axios.post('/api/admin/information/info/company-information', {text: this.content})
                .then(res => {
                    this.success = res.data.message;
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                this.loading = false;
            })
        }
    },

    created() {
        this.getContent();
    }
}
</script>

<style scoped>

</style>
