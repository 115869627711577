<template>

<!-- Footer -->
  <footer class="footer mt-5">

    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title double-color">Dane adresowe</h2>
              <div class="footer-contact-info">
                <h5 class="mb-4">Trivium Travel Sp. z o.o.</h5>
                <div class="footer-address mb-3">
                  <span><i class="far fa-building"></i></span>
                  <p>ul. Wadowicka 8a<br>
                    30-415 Kraków</p>
                </div>
                <p><i class="fas fa-headphones"></i> +48 798 712 639</p>
                <p class="mb-0"><i class="fas fa-envelope"></i> biuro@travelpartner.pl</p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title double-color">System rezerwacji</h2>
              <ul>
                <li>
                  <a href="#">Warunki rezerwacji</a>
                </li>
                <li>
                  <a href="#">Zmiana rezerwacji</a>
                </li>
                <li>
                  <a href="#">Anulowanie rezerwacji</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Rezerwacja grupowa</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Gwarancja najniższej ceny</a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title double-color">Trivium travel</h2>
              <ul>
                <li>
                  <a href="#">O nas</a>
                </li>
                <li>
                  <a href="#">Obsługa firm</a>
                </li>
                <li>
                  <a href="#">Polityka cookies</a>
                </li>
                <li>
                  <a href="#">Polityka prywatności</a>
                </li>
                <li>
                  <a href="#">Zgłoś błąd</a>
                </li>
                <li>
                  <a href="#">Kontakt</a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title double-color">Kontakt z nami</h2>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook-f"></i> </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i> </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-google"></i></a>
                  </li>
                </ul>
              </div>
              <div class="subscribe-form">
                <input type="email" class="form-control" placeholder="Twój adres E-mail">
                <button onclick="void(0)" type="submit" class="btn footer-btn">
                  <i class="fas fa-paper-plane"></i>
                </button>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">&copy; 2023 <a href="/">Travelpartner</a>. Wszelkie prawa zastrzeżone.</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu float-right">
                Realizacja: <a href="https://www.dreampics.pl/">Dreampics</a>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->

  </footer>

</template>
