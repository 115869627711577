<template>

    <div class="main-wrapper">
        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <layout-header></layout-header>

        <div class="content">
            <div class="container">
                <div class="row">
                    <customersidebar></customersidebar>
                    <div class="col-xl-9 col-md-8">
                        <div class="row align-items-center mb-4">
                            <div class="col">
                                <h4 class="widget-title mb-0">Twoja polityka podróży</h4>
                            </div>
                            <div class="col-auto">

                            </div>
                        </div>

                        <user-travel-policy-list></user-travel-policy-list>
                        <br><br>
                        <user-travel-policy-files></user-travel-policy-files>

                    </div>
                </div>
            </div>
        </div>

        <layout-footer></layout-footer>

    </div>

</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
        }
    },

    methods: {

    },

    created() {

    },
}
</script>

<style scoped>

</style>
