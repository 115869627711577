<template>
<div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="/images/services/service-02.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">Car Repair Services</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 23 Jul 2020 <span class="badge badge-pill badge-prof bg-success">Complete Request sent to User</span>
                                            </li>
                                            <li><span>Booking time</span> 13:00:00 - 14:00:00</li>
                                            <li><span>Amount</span> $500</li>
                                            <li><span>Location</span> 223 Jehovah Drive Roanoke</li>
                                            <li><span>Phone</span> 410-242-3850</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-01.jpg">
                                                </div>
                                                Jeffrey Akridge
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action"></div>
                            </div>
                        </div>

                        <div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="/images/services/service-03.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">Electric Panel Repairing Service</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 21 Jul 2020 <span class="badge badge-pill badge-prof bg-primary">Inprogress</span>
                                            </li>
                                            <li><span>Booking time</span> 17:00:00 - 18:00:00</li>
                                            <li><span>Amount</span> $500</li>
                                            <li><span>Location</span> 3281 West Fork Street Great Falls</li>
                                            <li><span>Phone</span> 410-242-3850</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-02.jpg">
                                                </div>
                                                Nancy Olson
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <router-link to="/user-chat" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> Chat</router-link>
                                    <a href="javascript:;" class="btn btn-sm bg-danger-light" data-toggle="modal" data-target="#myCancel"><i class="fas fa-times"></i> Cancel the Service</a>
                                    <a href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Complete Request to user</a>
                                </div>
                            </div>
                        </div>

                        <div class="bookings">
                            <div class="booking-list">
                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img src="/images/services/service-04.jpg" alt="User Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">Steam Car Wash</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li>
                                                <span>Booking Date</span> 21 Jul 2020 <span class="badge badge-pill badge-prof bg-warning">Pending</span>
                                            </li>
                                            <li><span>Booking time</span> 13:00:00 - 14:00:00</li>
                                            <li><span>Amount</span> $500</li>
                                            <li><span>Location</span> 596 Walton Street Ogden</li>
                                            <li><span>Phone</span> 410-242-3850</li>
                                            <li>
                                                <span>User</span>
                                                <div class="avatar avatar-xs me-1">
                                                    <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-03.jpg">
                                                </div>
                                                Ramona Kingsley
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <a href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> User Request Accept</a>
                                    <a href="javascript:;" class="btn btn-sm bg-danger-light" data-toggle="modal" data-target="#myCancel">	<i class="fas fa-times"></i> Cancel the Service</a>
                                </div>
                            </div>
                        </div>
</template>