<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Polityka podróży</h3>
                            </div>
                            <div class="col-auto text-center d-flex justify-content-between " v-if="isSuperadmin">
                                <button class="btn btn-primary mr-3" data-toggle="modal" data-target="#addItemModal">Dodaj</button>

                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table
                                            v-if="isSuperadmin && travelPolicies"
                                            id="travel-policies"
                                            class="table table-hover table-center mb-0 datatable"
                                        >
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>Cena</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(policy, index) in travelPolicies" :key="policy.id">
                                                <td>{{ policy.id }}</td>
                                                <td>{{ policy.title }}</td>
                                                <td>
                                                    <input v-model="policy.price" type="text" class="form-control" />
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteConfirmModal" @click="deletePolicy(policy.id)">Usuń</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <button class="btn btn-success mt-3" @click="saveAll">Edytuj</button>
                                        </table>

                                        <table
                                            v-if="isClient && travelPolicies && customTravelPolicies"
                                            id="custom-travel-policies"
                                            class="table table-hover table-center mb-0 datatable"
                                        >
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>Cena</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(policy, index) in customPolicies" :key="policy.id">
                                                <td>{{ policy.id }}</td>
                                                <td>{{ (policy.travel_policy_id) ? policy.travel_policy.title : policy.title }}</td>
                                                <td>
                                                    <input
                                                        :value="policy.travel_policy_id? policy.price : null"
                                                        @input="updateCustomPolicy(policy.id, $event)"
                                                        :placeholder="policy.price"
                                                        type="text" class="form-control"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                            <button class="btn btn-success mt-3" @click.prevent="saveCustomAll" type="submit">Edytuj</button>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

        <div class="modal fade" id="addItemModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Dodaj nową pozycję</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table>
                            <tbody>
                            <tr>
                                <td class="w-75"><input class="form-control" type="text" name="title" placeholder="Nazwa pozycji" v-model="newItemPayload.title" /> </td>
                                <td class="w-25"><input class="form-control" type="text" name="price" placeholder="cena" v-model="newItemPayload.price" /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger si_accept_confirm" @click.prevent="addNew">Zapisz</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz usunąć pozycję??</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="deletePolicyWithId" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
export default {

    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            travelPolicies: [],
            customTravelPolicies: [],
            newItemPayload: {
                title: null,
                price: null,
            },
            policyToDelete: null,

            customPolicyPayload: {},
        }
    },

    computed: {
        /**
         * Combination of `travelPolicies` and `customTravelPolicies`
         * to receive a normal and custom (client's) travel policies
         * @returns {(*)[]}
         */
        customPolicies() {
            return this.travelPolicies.map(policy => {
                // Find if there's a custom policy for this policy
                const customPolicy = this.customTravelPolicies.find(
                    custom => custom.travel_policy_id === policy.id
                );

                // If there's a custom policy, return that, otherwise return the original policy
                return customPolicy ? customPolicy : policy;
            });
        },
    },

    methods: {
        deletePolicy(userId) {
            this.policyToDelete = userId;
        },

        deletePolicyWithId() {
            this.loading = true;
            axios.delete(`/api/admin/travel-policy/${this.policyToDelete}`)
                .then(() => {
                    this.travelPolicies = this.travelPolicies.filter(policy => policy.id !== this.policyToDelete);
                    $('#deleteConfirmModal').modal('hide');
                    this.policyToDelete = null;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Adding new travel policy from `#addItemModal` modal form
         */
        addNew() {
            this.loading = true;
            axios.post(`/api/admin/travel-policy`, this.newItemPayload)
                .then((response) => {
                    $('#addItemModal').modal('hide');
                    this.getAll();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
        * Saves all regular travelPolicy records
        */
        saveAll() {
            this.loading = true;
            axios.put(`/api/admin/travel-policy`, this.travelPolicies)
                .then((response) => {
                    console.log(response.data)
                    this.travelPolicies = response.data
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateCustomPolicy(index, event) {
            this.customPolicyPayload[index] = event.target.value;
        },

        /**
        * Save all customTravelPolicy inputs that has value or don't.
        * It sends `customPolicyPayload` that is an object containing { travelPolicy.id: price }
        */
        saveCustomAll() {
            this.loading = true;
            axios.put(`/api/admin/travel-policy/custom/${this.globalUser.id}`, this.customPolicyPayload)
                .then((response) => {
                    console.log(response.data)
                    // this.travelPolicies = response.data
                    this.getCustomAll();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
        * Get all TravelPolicy records
        */
        getAll() {
            this.loading = true;
            axios.get('/api/admin/travel-policy')
                .then(response => {
                    this.travelPolicies = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Get all CustomTravelPolicy records for current user ID
         * Then set fresh custom policy payload with setCustomPolicyPayload()
         */
        getCustomAll() {
            this.loading = true;
            axios.get(`/api/admin/travel-policy/custom/${this.globalUser.id}`)
                .then(response => {
                    this.customTravelPolicies = response.data;
                    this.setCustomPolicyPayload();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Sets CustomPolicyPayload at getCustomAll() (mostly startup or update)
         */
        setCustomPolicyPayload() {
        this.customPolicyPayload = {};
            this.customTravelPolicies.forEach(policy => {
                this.customPolicyPayload[policy.id] = policy.price;
            });
        },
    },

    created() {
        this.getAll();
        if(this.isClient) {
            this.getCustomAll();
        }
    }
}
</script>

<style scoped>

</style>
