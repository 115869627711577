<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Subscriptions</h3>
                            </div>
                            <div class="col-auto text-end">
                                <router-link to="/admin/add-subscription" class="btn btn-primary add-button">
                                    <i class="fas fa-plus"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <div class="row pricing-box">
                        <div class="col-md-6 col-lg-4 col-xl-3" v-for="item in subscription" :key="item.id">
                            <div class="card">
                                <div class="card-body">
                                    <div class="pricing-header">
                                        <h2>{{item.name}}</h2>
                                        <p>{{item.name1}}</p>
                                    </div>
                                    <div class="pricing-card-price">
                                        <h3 class="heading2 price">{{item.rate}}</h3>
                                        <p>{{item.time}} <span>{{item.period}}</span></p>
                                    </div>
                                    <ul class="pricing-options">
                                        <li><i class="far fa-check-circle"></i> {{item.description}}</li>
                                        <li><i class="far fa-check-circle"></i> {{item.description1}}</li>
                                    </ul>
                                    <router-link to="/admin/edit-subscription" class="btn btn-primary btn-block w-100">{{item.button}}</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import subscription from '../../../../assets/json/admin/subscription.json'
	export default {
	data() {
        return {
            subscription: subscription
        }
    }
	}
	</script>