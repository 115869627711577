<template>

    <section class="popular-services popular-services-three">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="top-section-head popular-head aos" data-aos="fade-up">
                        <h2>Most Popular</h2>
                    </div>
                    <div class="section-heading section-heading-three text-center aos" data-aos="fade-up">
                        <span>Explore the greates our services. You won’t be disappointed</span>
                        <div class="section-three">
                            <h2>Most Popular Services</h2>
                        </div>
                    </div>
                    <div class="service-carousel arrow-service aos" data-aos="fade-up">
                        <div class="categories-slidernew slider">
                            <div class="service-widget service-box">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-16.jpg">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <span class="service-price">$25</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Cleaning</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <div class="service-rate">
                                        <img src="/images/customer/user-02.jpg" alt="">
                                        <ul>
                                            <li>Thomas Herzberg</li>
                                            <li class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star "></i>
                                                <span class="d-inline-block average-rating">(5)</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Cleaning Service</router-link>
                                    </h3>
                                    <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                    <div class="user-info">
                                        <div class="user-info-box">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone-alt"></i>
                                                <span>609-639-6458</span>
                                            </span>
                                            <span class="col ser-location"><i class="fas fa-map-marker-alt ms-1"></i>
                                                <span>Wayne, New Jersey</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget service-box">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-17.jpg">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <span class="service-price">$50</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Automobile</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <div class="service-rate">
                                        <img src="/images/customer/user-03.jpg" alt="">
                                        <ul>
                                            <li>Hendry Evangline</li>
                                            <li class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star "></i>
                                                <span class="d-inline-block average-rating">(4.5)</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Computer & AMC Service</router-link>
                                    </h3>
                                    <span>This a facility used to clean the exterior and, in some cases....</span>
                                    <div class="user-info">
                                        <div class="user-info-box">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone-alt"></i>
                                                <span>0699 149 25 07</span>
                                            </span>
                                            <span class="col ser-location"><i class="fas fa-map-marker-alt ms-1"></i>
                                                <span>Hanover, Maryland</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget service-box">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-18.jpg">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <span class="service-price">$45</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Electrical</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <div class="service-rate">
                                        <img src="/images/customer/user-01.jpg" alt="">
                                        <ul>
                                            <li>Hilary Desouza</li>
                                            <li class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star "></i>
                                                <span class="d-inline-block average-rating">(5)</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Car wash</router-link>
                                    </h3>
                                    <span>Car wash is a facility used to clean the exterior and, in some cases....</span>
                                    <div class="user-info">
                                        <div class="user-info-box">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone-alt"></i>
                                                <span>0660 616 82 76</span>
                                            </span>
                                            <span class="col ser-location"><i class="fas fa-map-marker-alt ms-1"></i>
                                                <span>Kalispell, Montana</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget service-box">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-19.jpg">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <span class="service-price">$14</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Car Wash</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <div class="service-rate">
                                        <img src="/images/customer/user-05.jpg" alt="">
                                        <ul>
                                            <li>Thomas Herzberg</li>
                                            <li class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star "></i>
                                                <span class="d-inline-block average-rating">(0)</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Electrical Service</router-link>
                                    </h3>
                                    <span>This a facility used to clean the exterior and, in some cases....</span>
                                    <div class="user-info">
                                        <div class="user-info-box">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone-alt"></i>
                                                <span>(07) 4516 0420</span>
                                            </span>
                                            <span class="col ser-location"><i class="fas fa-map-marker-alt ms-1"></i>
                                                <span>Electra, Texas</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget service-box">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-17.jpg">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <span class="service-price">$100</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Cleaning</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <div class="service-rate">
                                        <img src="/images/customer/user-06.jpg" alt="">
                                        <ul>
                                            <li>Hilary Desouza</li>
                                            <li class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star "></i>
                                                <span class="d-inline-block average-rating">(3)</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">House Cleaning Services </router-link>
                                    </h3>
                                    <span>This a facility used to clean the exterior and, in some cases....</span>
                                    <div class="user-info">
                                        <div class="user-info-box">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone-alt"></i>
                                                <span>442 82 780</span>
                                            </span>
                                            <span class="col ser-location"><i class="fas fa-map-marker-alt ms-1"></i>
                                                <span>Sylvester, Georgia</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sell-view-btn text-center aos" data-aos="fade-up">
                        <router-link to="/search" class="btn btn-view">View All <i class="fas fa-long-arrow-alt-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
	export default {
	mounted() {
        if($('.categories-slidernew').length > 0) {
		$('.categories-slidernew').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>