<template>

    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Panel informacyjny</h3>
                            </div>
                            <div class="col-auto text-center d-flex justify-content-between " v-if="isSuperadmin">
                                <button class="btn btn-primary mr-3" data-toggle="modal" data-target="#addItemModal">Dodaj</button>

                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import AdminInformationFiles from "./AdminInformationFiles";
import AdminInformationPrices from "./AdminInformationPrices";
import AdminInformationUsers from "./AdminInformationUsers";

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
        }
    },

    methods: {

    },

    created() {

    },
}
</script>

<style scoped>

</style>
