<template>
<!-- Footer -->
<footer class="footer footer-two">

    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <div class="footer-logo-sell">
                            <img src="/images/logo-two.png" alt="img">
                        </div>
                        <div class="footer-about">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">Categories</h2>
                        <ul>
                            <li>
                                <router-link to="/search">Computer</router-link>
                            </li>
                            <li>
                                <router-link to="/search">Interior</router-link>
                            </li>
                            <li>
                                <router-link to="/search">Car Wash</router-link>
                            </li>
                            <li>
                                <router-link to="/search">Cleaning</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget footer-contact">
                        <h2 class="footer-title">Address</h2>
                        <div class="footer-contact-info">
                            <div class="footer-address">
                                <span><i class="far fa-building"></i></span>
                                <p>367 Hillcrest Lane, Irvine, California, United States</p>
                            </div>
                            <p><i class="fas fa-headphones"></i> 321 546 8764</p>
                            <p class="mb-0"><i class="fas fa-envelope"></i> truelysell@example.com</p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->
                </div>
                <div class="col-lg-3 col-md-6">
                    <!-- Footer Widget -->
                    <div class="footer-widget">
                        <h2 class="footer-title">Follow Us</h2>
                        <div class="social-icon">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook"></i> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-linkedin"></i> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Enter your email">
                            <router-link to="/search" class="btn footer-btn d-flex align-items-center justify-content-center">
                                <i class="fas fa-paper-plane"></i>
                            </router-link>
                        </div>
                    </div>
                    <!-- /Footer Widget -->
                </div>
            </div>
        </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <!-- Copyright -->
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 col-lg-6">
                        <div class="copyright-text">
                            <p class="mb-0">&copy; 2022 <router-link to="/">Truelysell</router-link>. All rights reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <!-- Copyright Menu -->
                        <div class="copyright-menu">
                            <ul class="policy-menu">
                                <li>
                                    <router-link to="/term-condition">Terms and Conditions</router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy-policy1">Privacy</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- /Copyright Menu -->
                    </div>
                </div>
            </div>
            <!-- /Copyright -->
        </div>
    </div>
    <!-- /Footer Bottom -->

</footer>
<!-- /Footer -->
</template>