<template>
    <div>
        <p><strong>Cennik</strong></p>
        <div class="card transaction-table mb-0">
            <div class="card-body">
                <div class="table-responsive" v-if="travelPolicies">
                    <table class="front-table table table-center mb-0 no-footer w-100">
                        <thead>
                        <tr>
                            <th class="w-75">Nazwa</th>
                            <th class="w-25">Cena</th>

                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="item in travelPolicies" :key="item.id">
                            <td class="w-75">{{item.title}}</td>
                            <td class="w-25"><strong>{{item.price}} zł</strong></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {

    data() {
        return {
            travelPolicies: null,
            loading: false,
        }
    },

    methods: {
        /**
         * Get all TravelPolicy records for user
         */
        getAll() {
            this.loading = true;
            axios.get(`/api/travel-policy/${this.globalUserId}`)
                .then(response => {
                    this.travelPolicies = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    created() {
        this.getAll();
    },
}
</script>

<style scoped>

</style>
