<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Service Providers</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <providerfilter></providerfilter>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="serviceprovider">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Provider Name</th>
                                                    <th>Contact No</th>
                                                    <th>Email</th>
                                                    <th>Reg Date</th>
                                                    <th>Subscription</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in serviceprovider" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name}}</a>
                                                        </h2>
                                                    </td>
                                                    <td>{{item.no1}}</td>
                                                    <td>{{item.mail}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>{{item.name1}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import serviceprovider from '../../../../assets/json/admin/serviceprovider.json'
    import util from '../../../../assets/utils/util'
	const images = require.context('../../../../assets/admin_img/provider', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
        util.datatable('#serviceprovider')
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            serviceprovider: serviceprovider
        }
    }
	}
	</script>
