<template>

	<div class="main-wrapper">

<!--	<layout-header></layout-header>-->
    <layout-header></layout-header>
    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8" style="min-height: 50vh">
                    <div class="row">
                        <div class="col-lg-4" v-if="limit">
                            <router-link to="/user-bookings" class="dash-widget dash-bg-1">
                                <span class="dash-widget-icon" v-if="limit.amount">{{ limit.amount }} zł</span>
                                <span class="dash-widget-icon" v-else>Brak</span>
                                <div class="dash-widget-info">
                                    <span>Limit <br> wydatków</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/user-reviews" class="dash-widget dash-bg-2">
                                <span class="dash-widget-icon">{{ reservations }}</span>
                                <div class="dash-widget-info">
                                    <span>Liczba <br> rezerwacji</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-4">
                            <router-link to="/notifications" class="dash-widget dash-bg-3">
                                <span class="dash-widget-icon">0</span>
                                <div class="dash-widget-info">
                                    <span>Powiadomienia</span>
                                </div>
                            </router-link>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 bookings" v-if="upcoming" v-for="item in upcoming" :key="item.id">
                            <h3>Nadchodzące rezerwacje: </h3>
                            <div class="booking-list">

                                <div class="booking-widget">
                                    <router-link to="/service-details" class="booking-img">
                                        <img :src="item.post_data.hotel.image" alt="Hotel Image">
                                    </router-link>
                                    <div class="booking-det-info">
                                        <h3>
                                            <router-link to="/service-details">{{ item.post_data.hotel.name }}</router-link>
                                        </h3>
                                        <ul class="booking-details">
                                            <li><span>Rezerwacja: : </span> {{ item.reservation_number }}</li>
                                            <li>
                                                <span>Daty:</span> {{ item.check_in }} do {{ item.check_out }} <span
                                                class="badge badge-pill badge-prof bg-success">Zarezerwowany</span>
                                            </li>
                                            <li><span>Suma: </span> {{ item.price_brutto_pln }} zł</li>t
                                            <li><span>Adres: </span> {{ item.post_data.hotel.address }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="booking-action">
                                    <a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i>
                                        Anuluj rezerwację</a>
                                </div>
                            </div>
                        </div>

                        <div v-if="upcoming && upcoming.length === 0" class="col-sm-12 bookings">
                            <p class="text-center my-5 py-5">Brak nadchodzących rezerwacji</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {

    components: {
        Loading,
    },

    data() {
        return {
            limit: null,
            reservations: 0,
            upcoming: 0,

            error: null,
            loading: false,
            success: false,

            userId: 1,
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    },

    methods: {
        getTravelLimit() {
            this.loading = true;
            axios.get(`/api/information/limit/${this.globalUserId}`)
                .then(res => {
                    this.limit = res.data;
                    console.log(this.limit);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        getUpcomingReservations() {
            this.loading = true;
            axios.get(`/api/information/upcoming/${this.globalUserId}/1`)
                .then(res => {
                    this.upcoming = res.data.data;
                    console.log(this.upcoming);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        getNumberOfReservations() {
            this.loading = true;
            axios.get(`/api/information/reservations/${this.globalUserId}`)
                .then(res => {
                    this.reservations = res.data;
                    console.log(this.reservations);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        this.getTravelLimit();
        this.getNumberOfReservations();
        this.getUpcomingReservations();
    },
}
</script>
