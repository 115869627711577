<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Ustawienia - Polecane hotele</h3>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table
                                            v-if="isSuperadmin"
                                            id="settings-popular-hotels"
                                            class="table table-hover table-center mb-0 datatable"
                                        >
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Id hotelu</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <input v-model="hotels.first" type="text" class="form-control" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    <input v-model="hotels.second" type="text" class="form-control" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    <input v-model="hotels.third" type="text" class="form-control" />
                                                </td>
                                            </tr>
                                            </tbody>
                                            <button class="btn btn-success mt-3" @click="saveAll">Edytuj</button>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>




    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
export default {

    components: {
        Loading,
    },

    data() {
        return {
            loading: false,

            hotels: {
                first: '',
                second: '',
                third: '',
            },
        }
    },

    computed: {

    },

    methods: {
        saveAll() {
            this.loading = true;
            this.$emit('loading', true);
            axios.post('/api/settings/popular-hotels', {
                value: [this.hotels.first, this.hotels.second, this.hotels.third]
            })
                .then(res => {
                    console.log('Saved:', res.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },

        getHotelIds() {
            this.loading = true;
            this.$emit('loading', true);
            axios.get('/api/settings/popular-hotels')
                .then(res => {
                    this.hotels = {
                        first: res.data.value[0],
                        second: res.data.value[1],
                        third: res.data.value[2],
                    };
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },
    },

    created() {
        this.getHotelIds();
    }
}
</script>

<style scoped>

</style>
