<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Rating Type</h3>
                            </div>
                            <div class="col-auto text-end">
                                <router-link to="/admin/add-ratingstype" class="btn btn-primary add-button">
                                    <i class="fas fa-plus"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table custom-table mb-0 datatable" id="ratingtype">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Rating Type</th>
                                                    <th>Status</th>
                                                    <th class="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in ratingtype" :key="item.id">
                                                    <td>{{item.no}}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="rating_1" class="check" type="checkbox" checked>
                                                            <label for="rating_1" class="checktoggle">{{item.name1}}</label>
                                                        </div>
                                                    </td>
                                                    <td class="text-end">
                                                        <router-link to="/admin/edit-ratingstype" class="table-action-btn btn btn-sm bg-success-light">
                                                            <i class="far fa-edit me-1"></i> Edit
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import ratingtype from '../../../../../assets/json/admin/rating/ratingtype.json'
    import util from '../../../../../assets/utils/util'
	export default {
	mounted() {
        util.datatable('#ratingtype')
	},
	data() {
        return {
            ratingtype: ratingtype
        }
    }
	}
	</script>