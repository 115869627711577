<template>
<div class="col-xl-3 col-md-4">
                        <div class="widget settings-menu">
                            <ul>
                                <li class="nav-item" :class="{'active': currentPath === '/admin/invoices-settings'}">
                                    <router-link to="/admin/invoices-settings" class="nav-link">
                                        <i class="fa fa-cog"></i> <span>General Settings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item" :class="{'active': currentPath === '/admin/tax-settings'}">
                                    <router-link to="/admin/tax-settings" class="nav-link">
                                        <i class="fa fa-bookmark"></i> <span>Tax Settings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item" :class="{'active': currentPath === '/admin/bank-settings'}">
                                    <router-link to="/admin/bank-settings" class="nav-link">
                                        <i class="fas fa-university"></i> <span>Bank Settings</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
</template>
<script>
    export default {
        computed: {
            currentPath() {
                return this.$route.name;
            }
        },
    data() {
        return {
          activeClass: 'active',
        };
                //  isactive : true
      },
    }
    </script>
    <style>
    .sidebar-menu ul li a.active {
        background-color: #f58220;
        color: #fff;
    }
    </style>
