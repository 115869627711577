<template>

	<div class="main-wrapper">

        <layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div>
                        <h4 class="widget-title">Notifications</h4>
                        <div class="notcenter">
                            <div class="notificationlist" v-for="item in notification" :key="item.id">
                                <div class="inner-content-blk position-relative">
                                    <div class="d-flex text-dark">
                                        <img class="rounded" :src="loadImg(item.img)" width="50" alt="">
                                        <div class="noti-contents">
                                            <h3><strong>{{item.name}}</strong></h3>
                                            <span>{{item.name1}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Pagination -->
                        <div class="pagination">
                            <ul>
                                <li class="active">
                                    <a href="javascript:void(0);">1</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">2</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">3</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">4</a>
                                </li>
                                <li class="arrow">
                                    <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Pagination -->

                    </div>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import notification from '../../../../assets/json/website/notification.json'
	const images = require.context('../../../../assets/img/customer', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },
	data() {
        return {
            notification: notification
        }
    }
	}
	</script>