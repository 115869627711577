<template>
    <div class="main-wrapper">

        <layout-header></layout-header>

        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="content"  :style="{height: (loading) ? '60vh' : 'auto'}">
            <div class="container">
                <div class="row">

                    <div class="col-lg-8">

                        <div class="service-view" v-if="details">
                            <div class="service-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h1> {{ details.name }}</h1>
                                    <div class="fav-btn fav-btn-big" v-show="false">
                                        <a href="javascript:void(0)" class="fav-icon with-border">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <address class="service-location"><i class="fas fa-location-arrow"></i> {{ details.address.city }},
                                    {{ details.address.country }}
                                </address>

                                <div class="col-sm-12 rating mapgridrating1"
                                     v-if="details.starRating && details.starRating > 0">
                                    <i class="fas fa-star" :class="{ 'filled': details.starRating > 0 }"></i>
                                    <i class="fas fa-star" :class="{ 'filled': details.starRating > 1 }"></i>
                                    <i class="fas fa-star" :class="{ 'filled': details.starRating > 2 }"></i>
                                    <i class="fas fa-star" :class="{ 'filled': details.starRating > 3 }"></i>
                                    <i class="fas fa-star" :class="{ 'filled': details.starRating > 4 }"></i>
                                    <span class="d-inline-block average-rating">({{ details.starRating }})</span>
                                </div>
                            </div>

                            <div class="service-images service-carousel-view slider" v-if="false">

                                <div
                                    v-if="details.image && details.image[0]"
                                    v-for="image in details.image"
                                    class="item"
                                >
                                    <img :src="image.url" alt="" class="img-fluid">
                                </div>


                            </div>

                            <div id="hotel-details__gallery" class="carousel slide" data-ride="carousel" align="center">
                                <!-- slides -->
                                <div class="carousel-inner">
                                    <div class="carousel-item"
                                         v-if="details.image && details.image[0]"
                                         v-for="(image, index) in details.image"
                                         :class="{ 'active': index === 0 }"
                                    >
                                        <div class="carousel-item-photo" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                                    </div>

                                </div>

                                <!-- Left right -->
                                <a class="carousel-control-prev" href="#hotel-details__gallery" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#hotel-details__gallery" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>

                                <!-- Thumbnails -->
                                <ol class="carousel-indicators list-inline">
                                    <li class="list-inline-item"
                                        :class="{ 'active': index === 0 }"
                                        v-if="details.image && details.image[0]"
                                        v-for="(image, index) in details.image">
                                        <a :id="`carousel-selector-${ index }`" class="selected" :data-slide-to="index"
                                           data-target="#hotel-details__gallery">
                                            <div class="carousel-item-thumb" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                                        </a>
                                    </li>
                                </ol>
                            </div>


                            <div class="service-details mt-5">
                                <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                                           aria-controls="pills-home" aria-selected="true">Opis</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab"
                                           aria-controls="pills-details" aria-selected="false">Szczegóły</a>
                                    </li>

                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="card service-description">
                                            <div class="card-body">
                                                <h5 class="card-title">Opis</h5>
                                                <p class="mb-0" v-html="details.descriptions.description._"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                                        <div class="card service-description">
                                            <div class="card-body">
                                                <h5 class="card-title">Szczegóły</h5>
                                                <ul class="pl-5" style="list-style: circle">
                                                    <li v-for="feature in details.feature">{{ feature.name }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <h4 class="card-title" v-if="false">Related Services</h4>

                        <popular-hotel v-if="false"></popular-hotel>

                    </div>

                    <div class="hotel-details col-lg-4 theiaStickySidebar" v-if="details">
                        <div class="stickyside">

                            <div class="card provider-widget clearfix">
                                <div class="card-body">

                                    <h5 class="card-title">Adres hotelu</h5>

                                    <div class="hotel-details__address-details">

                                        <a href="javascript:void(0);" class="hotel-details__address-details__city">{{ details.address.city }}</a>

                                        <p class="hotel-details__address-details__street">
                                            {{ details.address.street1 }}
                                            {{ (details.address.street2 !== '') ? ', ' : '' }}
                                            {{ details.address.street2 }}
                                        </p>

                                        <p class="hotel-details__address-details__zip text-muted mb-1">{{ details.address.zip }} {{ details.address.city }}</p>

                                    </div>

                                </div>
                            </div>

                            <div class="card available-widget" style="position: relative;">
                                <iframe
                                    :src="mapSrc"
                                    height="450"
                                    style="border:0; width: 100%;"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                                <div class="overlay" @click="openModal"></div>
                            </div>
                        </div>


                        <div class="card filter-card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">Wyszukaj hotel</h4>
                                <hr>
                                <form id="search_form">
                                    <div class="filter-widget">

                                        <div class="filter-list">
                                            <h4 class="filter-title">Pobyt:</h4>

                                            <VueDatePicker
                                                v-model="date"
                                                placeholder="Kiedy jedziesz?"
                                                :min-date="new Date()"
                                                model-type="yyyy-MM-dd"
                                                format="dd MMM"
                                                range
                                                auto-apply
                                                multi-calendars
                                                :format-locale="lang"
                                            ></VueDatePicker>

                                        </div>

                                        <div class="filter-list row">

                                            <div class="col-sm-12 col-md-6 pl-md-0">
                                                <h4 class="filter-title">Dorośli</h4>
                                                <input type="number" step="1" max="3" v-model="payload.adults"
                                                       class="form-control">
                                            </div>


                                            <div class="col-sm-12 col-md-6 pr-md-0">
                                                <h4 class="filter-title">Dzieci</h4>
                                                <input type="number" step="1" max="3" @change="resetChildrenAge"
                                                       v-model="payload.children"
                                                       class="form-control">
                                            </div>

                                        </div>


                                        <div class="filter-list row" v-if="payload.children > 0">

                                            <div class="col-sm-12 px-0 ">
                                                <h4 class="filter-title">Wiek dzieci:</h4>
                                            </div>

                                            <div class="col-sm-12 col-md-6 col-lg-4 px-1 children-count"
                                                 v-for="(count, index) in payload.children" :key="index">

                                                <input
                                                    v-model="payload.childrenAge[count - 1]"
                                                    type="number"
                                                    step="0"
                                                    max="14"
                                                    class="form-control"
                                                >
                                            </div>

                                        </div>
                                    </div>

                                    <hr>
                                    <router-link
                                        class="btn btn-primary mt-3"
                                        :to="{
                                        name: 'hotel',
                                        params: {
                                                id: $route.params.id,
                                                checkin:  date?.[0],
                                                checkout: date?.[1],
                                                adults: payload.adults,
                                                children: payload.children,
                                                ageOne: payload.ageOne,
                                                ageTwo: payload.ageTwo,
                                                ageThree: payload.ageThree,
                                            }
                                        }"
                                    >
                                        Sprawdź dostępność
                                    </router-link>
                                </form>
                            </div>
                        </div>

                    </div>



                </div>
            </div>

            <div id="fullscreenMapModal" class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-fullscreen" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; top: 3rem; right: 3rem;">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <iframe
                                :src="mapSrc"
                                style="border:0; width: 100%; height: 90vh;"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <layout-footer></layout-footer>

    </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from 'vuex';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import {useRoute} from "vue-router";
import {onMounted} from 'vue';
import {ref} from 'vue';
import {pl} from 'date-fns/locale';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import SimpleTypeahead from "vue3-simple-typeahead";
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

export default {

    components: {
        Loading,
        VueDatePicker,
        SimpleTypeahead,
    },


    data() {
        return {

            country: 0,
            city: null,

            hotels: null,
            hotelDetails: null,
            count: null,
            error: null,

            searchName: '',
            sort: '',

            noOfDays: null,

            lang: null,

            offerGenerator: false,
            offersToGenerate: [],
            roomsToGenerate: [],
            offerGenerateView: false,


            countries: [],
            cities: null,
            loading: false,

            availability: null,
            bookingRemarks: null,
            details: null,

            payload: {
                countryId: null,
                cityId: null,
                checkin: '2023-05-15',
                checkout: '2023-05-20',
                adults: 1,
                children: 0,
                childrenAge: [],
            },
        }
    },

    computed: {
        ...mapGetters({
            hotelDetails: 'hotels/hotelDetails',
        }),
        mapSrc() {
            if(this.details) {
                return `https://maps.google.com/maps?q=${this.details.coordinates.latitude},${this.details.coordinates.longitude}&z=15&output=embed`;
            }
            return `https://maps.google.com/maps?q=&z=15&output=embed`;
        },

    },

    methods: {

        setLoading(value) {
            this.loading = value;
        },

        openModal() {
            $('#fullscreenMapModal').modal('show');
        },

        getHotelDetails(page = 1) {
            this.$store.commit('hotels/setHotelDetails', null)
            this.loading = true;
            this.$emit('loading', true);
            axios.get(`/api/search/get-hotel-details/${this.$route.params.id}`)
                .then(res => {
                    console.log('hotelPresentation', res.data.hotel);
                    this.details = res.data.hotel;
                    sessionStorage.setItem('details', JSON.stringify(res.data.hotel));
                    this.$store.commit('hotels/setHotelDetails', res)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        }
    },

    mounted() {
        this.lang = pl;
        this.getHotelDetails();
        if(this.details) {
            if(this.details.channel.room && this.details.channel.room[0]) {
                this.payload.roomId = this.details.channel.room[0].id;
                this.payload.boardTypeId = this.details.channel.room[0].boardType.id;
                this.payload.roomTypeId = this.details.channel.room[0].roomType.id;
            } else {
                this.payload.roomId = this.details.channel.room.id;
                this.payload.boardTypeId = this.details.channel.room.boardType.id;
                this.payload.roomTypeId = this.details.channel.room.roomType.id;
            }
        }
        // console.log(this.$store.getters['hotels/hotelDetails']);
    },

    setup() {
        const date = ref();

        return {
            date
        }
    }
}
</script>
<style scoped>
.container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
}

#hotel-details__gallery .carousel-indicators {
    position: static;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    width: 100%;
    right: 0;
    bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.carousel-control-prev,
.carousel-control-next {
    height: 520px;
}


#hotel-details__gallery .carousel-indicators > li {
    width: 59px;
    height: 27px;
}

#hotel-details__gallery .carousel-indicators > li > a {
    width: 65px;
    height: 60px;
    display: block;
}

#hotel-details__gallery .carousel-indicators li img {
    display: block;
    opacity: 0.5;
}

#hotel-details__gallery .carousel-indicators li.active img {
    opacity: 1;
}

#hotel-details__gallery .carousel-indicators li:hover img {
    opacity: 0.75;
}

.carousel-item img {

    width: 100%;
}

.carousel-item-photo {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
}

.carousel-item-thumb {
    width: 66px;
    height: 43px;
    background-size: cover;
    background-position: center;
}

.modal-fullscreen {
    width: 96vw;
    max-width: none;
    height: 96vh;
    margin: 0;
    padding: 0;
    margin: 0 auto;
    margin-top: 2rem;
}
.modal-fullscreen .modal-content {
    height: 90vh;
    border: none;
    border-radius: 0;
}
.modal-fullscreen .modal-body {
    padding: 0;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
}

</style>
