<template>
<!-- Header -->
<div class="header fixed-header" :class="{'bg-base': isClient}">
    <div class="header-left">
        <router-link :to="{name: 'index'}" class="logo logo-small d-none d-lg-flex">
            <img src="/images/logo-icon.png" alt="Logo" width="30" height="30">
        </router-link>
    </div>
    <a class="d-none" href="javascript:void(0);" id="toggle_btn">
        <i class="fas fa-align-left"></i>
    </a>
    <a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
        <i class="fas fa-align-left"></i>
    </a>

    <communicator
        v-if="communicator"
        @close="closeCommunicator"
        :email="globalUser.email"
        :subject="subject"
    ></communicator>

    <a href="javascript:void(0);" @click.prevent="openCommunicator(null, null)" class="nav-link nav-chat-icon" style="">
        <i class="fas fa-comments"></i>
    </a>
    <a href="/chat" style="position: absolute; top: 10px; right: 100px; ">
        <i class="fa fa-comments" aria-hidden="true"></i>
    </a>

    <ul class="nav user-menu">
        <!-- Notifications -->
        <li class="nav-item dropdown noti-dropdown">
            <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown">
                <i class="far fa-bell" :class="{'text-white': isClient}"></i>  <span v-if="messages && messages.length > 0" class="badge badge-pill"></span>
            </a>
            <div class="dropdown-menu notifications">
                <div class="topnav-dropdown-header">
                    <span class="notification-title">Powiadomienia</span>
                    <a href="javascript:void(0)" @click="clearAllMessages" class="clear-noti"> Wyczyść wszystkie </a>
                </div>
                <div class="noti-content">
                    <ul class="notification-list" v-if="messages && messages.length > 0">
                        <li class="notification-message" v-for="message in messages">
<!--                            <router-link to="/admin/admin-notification">-->
                            <a href="#" @click.prevent="openCommunicator(message.subject, message.id)">
                                <div class="media d-flex">
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title" :class="{'fw-bold': !message.opened }">{{ message.user.firstname }} {{ message.user.lastname }} | {{ message.subject }}</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">{{ $filters.formatDate(message.created_at)  }}</span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <p class="text-center mt-5" v-else>Brak powiadomień</p>
                </div>
                <div class="topnav-dropdown-footer">
                    <router-link to="/admin/admin-notification">Pokaż wszystkie</router-link>
                </div>
            </div>
        </li>
        <!-- /Notifications -->

        <!-- User Menu -->
        <li class="nav-item dropdown" v-if="false">
            <a href="javascript:void(0)" class="dropdown-toggle user-link  nav-link" data-toggle="dropdown">
                <span class="user-img">
                    <img class="rounded-circle" src="/images/user.jpg" width="40" alt="Admin">
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <router-link class="dropdown-item" to="/admin/admin-profile">Profile</router-link>
                <router-link class="dropdown-item" to="/admin/login">Logout</router-link>
            </div>
        </li>
        <!-- /User Menu -->
    </ul>
</div>
<!-- /Header -->
</template>
<script>
import Communicator from "../communicator/Communicator";
import {PerfectScrollbar} from "vue3-perfect-scrollbar";
import axios from "axios";

    export default {

    components: {
        Communicator,
    },

    data() {
        return {
            communicator: false,
            loading: false,
            messages: null,
            subject: null,
        };
    },

    computed: {
        currentPath() {
            return this.$route.name
        },
    },

    methods: {
        closeCommunicator() {
            this.communicator = false;
        },

        openCommunicator(subject = null, messageId = null) {
            if(subject) {
                this.subject = subject;
            }
            if(messageId) {
                this.clearMessage(messageId);
            }
            this.communicator = true;
        },

        clearMessage(messageId) {
            this.loading = true;
            axios.post(`/api/admin/chat/messages/${messageId}/clear`)
                .then(res => {
                    this.getMessages();
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

        clearAllMessages() {
            this.loading = true;
            axios.post(`/api/admin/chat/messages/${this.globalUserId}/clearAll`)
                .then(res => {
                    this.getMessages();
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },

        getMessages() {
            this.loading = true;
            axios.get(`/api/admin/chat/messages/pending/${this.globalUserId}`)
                .then(res => {
                    this.messages = res.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },
    },

    mounted() {
        this.getMessages();

        // Variables declarations

        var $wrapper = $('.main-wrapper');
        var $pageWrapper = $('.page-wrapper');
        var $slimScrolls = $('.slimscroll');

        // Sidebar

        var Sidemenu = function() {
            this.$menuItem = $('#sidebar-menu a');
        };

        function init() {
            var $this = Sidemenu;
            $('#sidebar-menu a').on('click', function(e) {
                if($(this).parent().hasClass('submenu')) {
                    e.preventDefault();
                }
                if(!$(this).hasClass('subdrop')) {
                    $('ul', $(this).parents('ul:first')).slideUp(350);
                    $('a', $(this).parents('ul:first')).removeClass('subdrop');
                    $(this).next('ul').slideDown(350);
                    $(this).addClass('subdrop');
                } else if($(this).hasClass('subdrop')) {
                    $(this).removeClass('subdrop');
                    $(this).next('ul').slideUp(350);
                }
            });
            $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
        }

        // Sidebar Initiate
        init();

        // Mobile menu sidebar overlay

        $('body').append('<div class="sidebar-overlay"></div>');
        $(document).on('click', '#mobile_btn', function() {
            $wrapper.toggleClass('slide-nav');
            $('.sidebar-overlay').toggleClass('opened');
            $('html').addClass('menu-opened');
            return false;
        });

        // Sidebar overlay

        $(".sidebar-overlay").on("click", function () {
            $wrapper.removeClass('slide-nav');
            $(".sidebar-overlay").removeClass("opened");
            $('html').removeClass('menu-opened');
        });
        // Small Sidebar

        $(document).on('click', '#toggle_btn', function() {
            if($('body').hasClass('mini-sidebar')) {
                $('body').removeClass('mini-sidebar');
                $('.subdrop + ul').slideDown();
            } else {
                $('body').addClass('mini-sidebar');
                $('.subdrop + ul').slideUp();
            }
            setTimeout(function(){
                mA.redraw();
                mL.redraw();
            }, 300);
            return false;
        });

        $(document).on('mouseover', function(e) {
            e.stopPropagation();
            if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
                var targ = $(e.target).closest('.sidebar').length;
                if(targ) {
                    $('body').addClass('expand-menu');
                    $('.subdrop + ul').slideDown();
                } else {
                    $('body').removeClass('expand-menu');
                    $('.subdrop + ul').slideUp();
                }
                return false;
            }

            // $(window).scroll(function() {
            // 	if ($(window).scrollTop() >= 30) {
            // 		$('.header').addClass('fixed-header');
            // 	} else {
            // 		$('.header').removeClass('fixed-header');
            // 	}
            // });

            $(document).on('click', '#loginSubmit', function() {
                $("#adminSignIn").submit();
            });

        });
    },
}
</script>
