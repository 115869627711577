<template>

	<div class="main-wrapper">

	<layout-header></layout-header>

    <div class="content">
        <div class="container">
            <div class="row">
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row align-items-center mb-4">
                        <div class="col">
                            <h4 class="widget-title mb-0">Twoje rezerwacje</h4>
                        </div>
                        <div class="col-auto">
                            <div class="sort-by">
                                <select class="form-control-sm custom-select">
                                    <option>Wszystkie</option>
                                    <option>Oczekujące</option>
                                    <option>Zarezerwowane</option>
                                    <option>Odrzucone</option>
                                    <option>Anulowane</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <bookinglist></bookinglist>

                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
