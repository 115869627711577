<template>
<section class="category-section category-section-three">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="top-section-head aos" data-aos="fade-up">
                        <h2>Featured Categories</h2>
                    </div>
                    <div class="section-heading section-heading-three text-center aos" data-aos="fade-up">
                        <span>What do you need to find?</span>
                        <div class="section-three">
                            <h2>Featured Categories</h2>
                        </div>
                    </div>
                    <div class="catsec aos" data-aos="fade-up">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-10.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Computer</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>21</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-11.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Interior</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>15</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-12.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Car Wash</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>21</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-13.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Cleaning</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>14</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-14.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Electrical</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>10</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <router-link to="/search">
                                    <div class="cate-widget">
                                        <img src="/images/category/category-15.jpg" alt="">
                                        <div class="cate-title">
                                            <h3><span> Construction</span></h3>
                                        </div>
                                        <div class="cate-count">
                                            <i class="fas fa-users"></i>
                                            <span>11</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="sell-view-btn text-center aos" data-aos="fade-up">
                        <router-link to="/search" class="btn btn-view ">View All <i class="fas fa-long-arrow-alt-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>