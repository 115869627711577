<template>
    <div class="main-wrapper">

        <layout-header></layout-header>

        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="content"  :style="{height: (loading && !details.channel) ? '60vh' : 'auto'}">
            <div class="container">
                <div class="row" v-if="details && details.channel">

                    <div class="col-lg-8">

                        <hotel-view
                            :details="details"
                            :rate="rate"
                        ></hotel-view>

                        <h4 class="card-title" v-if="false">Related Services</h4>

                        <popular-hotel v-if="false"></popular-hotel>

                    </div>

                    <hotel-details-sidebar
                        :rate="rate"
                        :details="details"
                        :booking-remarks="bookingRemarks"
                        :availability="availability"
                    ></hotel-details-sidebar>

                </div>

                <div class="row" v-if="error">

                    <div class="col-lg-12"
                    style="
                        height: 60vh;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        align-items: center;"
                    >
                        <h3 style="font-size: 50px; font-weight: 500;">Przepraszamy,</h3>
                        <p style=" text-align: center;">{{ error }}</p>
                        <a href="/" title="Strona główna">Powrót do strony głównej</a>
                    </div>
                </div>
            </div>
        </div>

        <layout-footer></layout-footer>

    </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from 'vuex';

import '@vuepic/vue-datepicker/dist/main.css'

export default {

    components: {
        Loading,
    },


    data() {
        return {

            country: 0,
            city: 0,

            rate: 4.60,

            hotels: null,
            count: null,
            error: null,

            noOfDays: null,

            countries: [],
            cities: null,
            loading: false,

            availability: null,
            bookingRemarks: null,

            payload: {
                hotelId: null,
                roomId: null,
                boardTypeId: null,
                roomTypeId: null,
                checkin: null,
                checkout: null,
                adults: null,
                children: null,
                ageOne: 0,
                ageTwo: 0,
                ageThree: 0,
            },
        }
    },

    computed: {
        ...mapGetters({
            hotelDetails: 'hotels/hotelDetails',
        }),

        details() {
            let details = null;
            const sessionDetails = sessionStorage.getItem('details');
            let hotels = (sessionDetails) ? JSON.parse(sessionDetails) : this.$store.getters['hotels/hotelDetails'];
            console.log('hotelDetails', hotels);
            if (hotels && hotels.address) {
                details = {};
                details.details = hotels;
            } else {
                details = hotels.reduce((acc, item) => {
                    const id = parseInt(item.id);
                    const param = parseInt(this.$route.params.id);
                    if (id === param) {
                        acc = item;
                    }
                    return acc;
                }, {});
            }

            return details;
        },
    },

    methods: {

        setLoading(value) {
            this.loading = value;
        },

        getEurPlnRate() {
            this.loading = true;
            this.$emit('loading', true);
            axios.get('/api/settings/eur-pln-rate')
                .then(res => {
                    this.rate = res.data.rate;
                    this.effectiveDate = res.data.effectiveDate;
                })
                .catch(error => {
                    console.log(error);
                })
        },

        checkAvailability() {
            this.loading = true;
            axios.post('/api/book/check-availability', this.payload)
                .then(res => {
                    this.availability = res.data;
                    this.bookingRemarks = res.data.hotel.channel.room.bookingTerms.bookingRemarks;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        search() {
            this.error = null;
            this.payload.checkin = this.date[0];
            this.payload.checkout = this.date[1];

            const diffInMs = new Date(this.date[1]) - new Date(this.date[0])
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            this.noOfDays = diffInDays;

            this.$store.commit('hotels/setHotels', null)
            this.loading = true;
            this.$emit('loading', true);
            axios.post('/api/search/hotels', this.payload)
                .then(res => {
                    this.hotels = res.data;
                    this.$store.commit('hotels/setHotels', res)
                    this.getHotelDetails();
                })
                .catch(error => {
                    this.error = error.response.data.error;
                    this.loading = false;
                    this.$emit('loading', false);
                })

        },

        getHotelDetails(page = 1) {
            this.$store.commit('hotels/setHotelDetails', null)
            this.loading = true;
            this.$emit('loading', true);
            axios.post('/api/search/hotel-details', {page: page, hotels: this.hotels})
                .then(res => {
                    this.hotelDetails = res.data;
                    this.$store.commit('hotels/setHotelDetails', res)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },

        async getHotelById() {
            this.loading = true;
            this.$emit('loading', true);

            await axios.post('/api/search/hotel-by-id', this.payload)
                .then(res => {
                    this.details.channel = res.data[0].channel;
                    //this.$store.commit('hotels/setHotelDetails', res)
                })
                .catch(error => {
                    this.error = 'Wybrany hotel jest niedostępny w tych dniach';
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        }
    },

    async mounted() {
        this.getEurPlnRate();
        if (this.$route.params.checkin && this.$route.params.checkout) {
            this.payload.hotelId = this.$route.params.id;
            this.payload.checkin = this.$route.params.checkin;
            this.payload.checkout = this.$route.params.checkout;
            this.payload.adults = this.$route.params.adults;
            this.payload.children = this.$route.params.children;
            this.payload.ageOne = this.$route.params.ageOne;
            this.payload.ageTwo = this.$route.params.ageTwo;
            this.payload.ageThree = this.$route.params.ageThree;
        }
        if(this.details && this.details.channel) {
            if(this.details.channel.room && this.details.channel.room[0]) {
                this.payload.roomId = this.details.channel.room[0].id;
                this.payload.boardTypeId = this.details.channel.room[0].boardType.id;
                this.payload.roomTypeId = this.details.channel.room[0].roomType.id;
            } else {
                this.payload.roomId = this.details.channel.room.id;
                this.payload.boardTypeId = this.details.channel.room.boardType.id;
                this.payload.roomTypeId = this.details.channel.room.roomType.id;
            }
            this.checkAvailability();
        } else {
            await this.getHotelById();
            // this.checkAvailability();
        }
        // console.log(this.$store.getters['hotels/hotelDetails']);
    },
}
</script>
