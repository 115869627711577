<template>

<section class="popular-services truly-one-service dots-hover">
        <div class="container">
            <div class="section-heading text-center">
                <h2>Most Popular Services</h2>
                <span>What do you need to find?</span>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="service-carousel">
                        <div class="service-sliderhome2 slider aos" data-aos="fade-up">
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-13.jpg">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img src="/images/customer/user-01.jpg" alt="">
                                            </router-link>
                                            <span class="service-price">$25</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Glass Cleaning</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">Toughened Glass Fitting Service</router-link>
                                    </h3>
                                    <div class="rating">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i>
                                                <span>28-62-76-32</span>
                                            </span>
                                            <span class="col ser-location">
                                                <span>Wayne, New Jersey</span> <i class="fas fa-map-marker-alt ms-1"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-14.jpg">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img src="/images/customer/user-02.jpg" alt="">
                                            </router-link>
                                            <span class="service-price">$50</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Car Mechanism</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">Car Repair Service</router-link>
                                    </h3>
                                    <div class="rating">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> <span>792 5457</span></span>
                                            <span class="col ser-location"><span>Hanover, Maryland</span>  <i class="fas fa-map-marker-alt ms-1"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-15.jpg">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img src="/images/customer/user-03.jpg" alt="">
                                            </router-link>
                                            <span class="service-price">$45</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Computer Repairing</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">Computer Repairing & Spares</router-link>
                                    </h3>
                                    <div class="rating">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> <span>030 44 89 44</span></span>
                                            <span class="col ser-location"><span>Kalispell, Montana</span>  <i class="fas fa-map-marker-alt ms-1"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-04.jpg">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img src="/images/customer/user-04.jpg" alt="">
                                            </router-link>
                                            <span class="service-price">$14</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Car Wash</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">Steam Car Wash</router-link>
                                    </h3>
                                    <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> <span>514-756-6436</span></span>
                                            <span class="col ser-location"><span>Electra, Texas</span>  <i class="fas fa-map-marker-alt ms-1"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="service-widget">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" src="/images/services/service-05.jpg">
                                    </router-link>
                                    <div class="fav-btn">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="fas fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <router-link to="/service-details">
                                                <img src="/images/customer/user-05.jpg" alt="">
                                            </router-link>
                                            <span class="service-price">$100</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">Cleaning</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">House Cleaning Services</router-link>
                                    </h3>
                                    <div class="rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                    <div class="user-info">
                                        <div class="row">
                                            <span class="col-auto ser-contact"><i class="fas fa-phone me-1"></i> <span>442 82 780</span></span>
                                            <span class="col ser-location"><span>Sylvester, Georgia</span>  <i class="fas fa-map-marker-alt ms-1"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
	export default {
	mounted() {
        if($('.service-sliderhome2').length > 0) {
		$('.service-sliderhome2').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>