<template>
  <div>
    <div class="card provider-widget clearfix">
      <div class="card-body">

        <h5 class="card-title">Termin pobytu:</h5>

        <div class="hotel-details__address-details">

          <p class="hotel-details__address-details__city">Przyjazd:</p>
          <p class="hotel-details__address-details text-capitalize">{{ checkinDate }}</p>
          <p class="hotel-details__address-details__city">Wyjazd:</p>
          <p class="hotel-details__address-details text-capitalize">{{ checkoutDate }}</p>
          <p class="hotel-details__address-details__city">Długość pobytu:</p>
          <p class="hotel-details__address-details">Całkowita liczba nocy: <strong>{{ countDays }}</strong></p>

        </div>

        <hr>

        <div class="hotel-details__address-details">
          <p class="hotel-details__address-details__city">Liczba osób:</p>
          <p class="hotel-details__address-details">Dorośli: <strong>{{ payload.adults }}</strong>, Dzieci:
            <strong>{{ payload.children }}</strong></p>

        </div>

      </div>
    </div>

    <div class="card provider-widget clearfix" v-if="availability">
      <div class="card-body">

        <h5 class="card-title">Całkowity koszt w PLN</h5>

        <div class="hotel-details__address-details">
            <p class="hotel-details__address-details fs-2">
                <strong>{{ Math.ceil(availability.hotel.channel.room.price * 1.10 * rate) }} zł</strong></p>
        </div>

      </div>
    </div>

      <div class="card provider-widget clearfix" v-if="availability">
          <div class="card-body">

              <h5 class="card-title">Pokój:</h5>

              <div class="hotel-details__address-details">

                  <p class="hotel-details__address-details__city">Pokój:</p>
                  <p class="hotel-details__address-details text-capitalize">{{ availability.hotel.channel.room.roomType.hotelstonName }}</p>
                  <p class="hotel-details__address-details__city">Nazwa:</p>
                  <p class="hotel-details__address-details text-capitalize">{{ availability.hotel.channel.room.roomType.name }}</p>
                  <p class="hotel-details__address-details__city">Wyżywienie:</p>
                  <p class="hotel-details__address-details">{{availability.hotel.channel.room.boardType.groupName }}</p>

              </div>

          </div>
      </div>


    <div class="card provider-widget clearfix" v-if="availability">
      <div class="card-body">

        <h5 class="card-title">Zasady rezerwacji</h5>

        <div class="hotel-details__address-details">

          <p class="hotel-details__address-details__city">Bezpłatne odwolanie rezerwacji:</p>

          <p class="hotel-details__address-details text-success text-capitalize">
            {{ cancelDate }}
          </p>

          <p class="hotel-details__address-details"
             v-html="availability.hotel.channel.room.bookingTerms.bookingRemarks"></p>

        </div>

      </div>
    </div>

    <div class="card available-widget">

    </div>


    <div class="card provider-widget clearfix" v-if="details">
      <div class="card-body">

        <h5 class="card-title">Adres hotelu</h5>

        <div class="hotel-details__address-details">

          <p class="hotel-details__address-details__street">
            {{ details.address.street1 }}
            {{ (details.address.street2 !== '') ? ', ' : '' }}
            {{ details.address.street2 }}
          </p>

          <p class="hotel-details__address-details__zip text-muted mb-1">{{ details.address.zip }}
            {{ details.address.city }}</p>

        </div>

        <hr>

        <div class="provider-info" v-show="false">
          <p class="mb-1" v-if="details.email"><i class="far fa-envelope"></i> <a href="javascript:void(0);">{{
              details.email
            }}</a></p>
          <p class="mb-0" v-if="details.phone"><i class="fas fa-phone-alt"></i> {{ details.phone }}
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";

export default {
  components: {
    Loading,
  },

  props: {
    details: {
      type: Object,
      required: true,
    },
    availability: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    countDays: {
      type: Number,
      required: true,
    },
      rate: {
          type: Number,
          required: true,
      },
  },

  data() {
    return {
    }
  },

  computed: {
    checkinDate() {
      if (this.payload.checkin) {
        let date = new Date(this.payload.checkin);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        return date.toLocaleDateString("pl", options)

      }
      return null;
    },

    checkoutDate() {
      if (this.payload.checkout) {
        let date = new Date(this.payload.checkout);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        return date.toLocaleDateString("pl", options)

      }
      return null;
    },

    cancelDate() {
      if (this.availability) {
        let date = new Date(this.availability.hotel.channel.room.bookingTerms.cancellationPolicy.cxlDate);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        return date.toLocaleDateString("pl", options)

      }
      return null;
    },

  },

  methods: {

  },

  mounted() {
    console.log(this.details);
    console.log(this.availability);
  }
}
</script>
