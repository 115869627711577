<template>

	<div class="main-wrapper">

    <layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">Payment History</h4>
                    <div class="card transaction-table mb-0">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Service</th>
                                            <th>Customer</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in payment" :key="item.id">
                                            <td>
                                                <a href="javascript:void(0);">
                                                    <img :src="loadImg(item.img)" class="pro-avatar" alt=""> {{item.name}}
                                                </a>
                                            </td>
                                            <td>
                                                <img class="avatar-xs rounded-circle" :src="loadImg1(item.img1)" alt=""> {{item.name1}}
                                            </td>
                                            <td>{{item.date}}</td>
                                            <td><strong>{{item.rate}}</strong></td>
                                            <td>
                                                <span class="badge bg-danger-light">{{item.description}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import payment from '../../../../../assets/json/website/payment.json'
	const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../assets/img/customer', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            payment: payment
        }
    }
	}
	</script>