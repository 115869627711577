<template>
    <div>
        <p><strong>Pliki</strong></p>
        <div class="card transaction-table mb-0">
            <div class="card-body">
                <div class="table-responsive" v-if="files">
                    <table class="front-table table table-center mb-0 no-footer w-100">
                        <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Oryginalna nazwa</th>
                            <th>Pobierz</th>

                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="item in files" :key="item.id">
                            <td>{{ item.title }}</td>
                            <td>{{ item.file.file_name }}</td>
                            <td><a :href="item.file.file_path" target="_blank">Pobierz</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {

    data() {
        return {
            files: null,
            loading: false,
        }
    },

    methods: {
        /**
         * Get all TravelPolicy records for user
         */
        getAllFiles() {
            this.loading = true;
            axios.get(`/api/travel-policy/files/${this.globalUserId}`)
                .then(response => {
                    this.files = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    created() {
        this.getAllFiles();
    },
}
</script>

<style scoped>

</style>
