<template>

	<div class="main-wrapper">

	<layout-headerservice></layout-headerservice>

    <div class="content">
        <div class="container">
            <div class="row">
                <providersidebar></providersidebar>
                <div class="col-xl-9 col-md-8">
                    <h4 class="widget-title">My Services</h4>
                    <ul class="nav nav-tabs menu-tabs">
                        <li class="nav-item active">
                            <router-link class="nav-link" to="/my-services">Active Services</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/my-services-inactive">Inactive Services</router-link>
                        </li>
                    </ul>
                    <div class="row">

                        <div class="col-lg-4 col-md-6 d-flex" v-for="item in favourites" :key="item.id">
                            <div class="service-widget flex-fill">
                                <div class="service-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image" :src="loadImg(item.img)">
                                    </router-link>
                                    <div class="item-info">
                                        <div class="service-user">
                                            <a href="javascript:void(0);">
                                                <img :src="loadImg1(item.img1)" alt="">
                                            </a>
                                            <span class="service-price ms-1">{{item.rate}}</span>
                                        </div>
                                        <div class="cate-list">
                                            <router-link class="bg-yellow" to="/service-details">{{item.name}}</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-content">
                                    <h3 class="title">
                                        <router-link to="/service-details">{{item.name1}}</router-link>
                                    </h3>
                                    <div class="rating mapgridrating1">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <span class="d-inline-block average-rating">{{item.rating}}</span>
                                    </div>
                                    <div class="user-info">
                                        <div class="service-action">
                                            <div class="row">
                                                <div class="col">
                                                    <router-link to="/edit-service" class="text-success"><i class="far fa-edit"></i> Edit</router-link>
                                                </div>
                                                <div class="col text-end">
                                                    <a href="javascript:void(0);" class="text-danger" data-toggle="modal" data-target="#deleteNotConfirmModal"><i class="far fa-trash-alt"></i> Inactive</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Pagination Links -->
                        <div class="pagination">
                            <ul>
                                <li class="active">
                                    <a href="javascript:void(0);">1</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">2</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">3</a>
                                </li>
                                <li class="ms-1">
                                    <a href="javascript:void(0);">4</a>
                                </li>
                                <li class="arrow">
                                    <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <myservicemodal></myservicemodal>

	<layout-footer></layout-footer>

	</div>

</template>
<script>
	import favourites from '../../../../../assets/json/website/favourites.json'
	const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../../assets/img/provider', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            favourites: favourites
        }
    }
	}
	</script>