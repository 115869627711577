<template>
	<div>
		<div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>

            <communicator
                v-if="communicator"
                @close="closeCommunicator"
                :email="globalUser.email"
                :subject="subject"
            ></communicator>

            <div class="page-wrapper">
                <div class="content container-fluid">

                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Wszystkie powiadomienia</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <div class="admin-noti-wrapper" v-if="messages">
                        <div class="noti-list" v-for="message in messages" :key="message.id">
<!--                            <div class="noti-avatar">-->
<!--                                <img src="/images/user.jpg" alt="">-->
<!--                            </div>-->
                            <div class="noti-contents" style="cursor: pointer;" @click.prevent="openCommunicator(message.subject, message.id)">
                                <h3>{{ message.subject }}</h3>
                                <p>{{ message.message }}</p>
                                <span>{{ $filters.formatDate(message.created_at)  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
import Communicator from "../../communicator/Communicator";
import {PerfectScrollbar} from "vue3-perfect-scrollbar";
import axios from "axios";

export default {

    components: {
        Communicator,
    },

    data() {
        return {
            communicator: false,
            loading: false,
            messages: null,
            subject: null,
        };
    },

    computed: {
        currentPath() {
            return this.$route.name
        },
    },

    methods: {
        closeCommunicator() {
            this.communicator = false;
        },

        openCommunicator(subject = null, messageId = null) {
            if(subject) {
                this.subject = subject;
            }
            this.communicator = true;
        },

        getMessages() {
            this.loading = true;
            axios.get(`/api/admin/chat/messages/${this.globalUserId}`)
                .then(res => {
                    this.messages = res.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        this.getMessages();
    },
}
</script>
