<template>
    <div class="communicator shadow">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0">
                    <div class="card-header primary">
                        <h5 class="card-title">Skontaktuj się</h5>
                        <button type="button" class="close" @click.prevent="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="card-body pt-3">
                        <form>
                            <div class="settings-form">
                                <div class="form-group form-placeholder">
                                    <label>E-mail <span class="star-red">*</span></label>
                                    <input type="text" v-model="payload.email" class="form-control">
                                </div>

                                <div class="form-group form-placeholder" v-if="reservation">
                                    <label>Numer rezerwacji <span class="star-red">*</span></label>
                                    <input type="text" v-model="payload.reservation" class="form-control">
                                </div>

                                <div class="form-group form-placeholder" v-if="subject">
                                    <label>Temat <span class="star-red">*</span></label>
                                    <input type="text" v-model="payload.subject" class="form-control">
                                </div>

                                <div class="form-group" v-else>
                                    <h5 class="pay-cont mb-3">Wybierz temat</h5>
                                    <label class="custom_radio d-block" v-for="(subject, index) in topics" :key="index">
                                        <input type="radio" name="subject" :checked="index === 0"  v-model="payload.subject" :value="subject" checked="">
                                        <span class="checkmark"></span> {{ subject }}
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label>Wiadomość <span class="star-red">*</span></label>
                                    <textarea v-model="payload.message" class="form-control"></textarea>
                                </div>

                                <p class="text-success" v-if="success">
                                    {{ success }}
                                </p>

                                <div class="form-group mb-0">
                                    <div class="settings-btns">
                                        <button type="submit" class="btn btn-primary" @click.prevent="send">Wyślij</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    props: {
        email: {
            type: String,
            required: false,
        },
        reservation: {
            type: String,
            required: false,
        },
        subject: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return {
            loading: false,
            success: false,
            payload: {
                message: null,
                subject: 'Zgłoś problem',
                email: null,
                reservation: null,
            },
            topics: [
                'Zgłoś problem',
                'Wyślij informacje do hotelu',
                'Anuluj rezerwację lub usługę',
                'Dowiedz się więcej o rezerwacji lub hotelu',
                'Zwróć się z prośbą o dokumenty',
                'Inne',
            ],
        }
    },

    methods: {
        close() {
            this.$emit('close', true);
        },

        send() {
            console.log(this.payload);
            this.loading = true;
            axios.post('/api/chat/message/send', this.payload)
            .then(res => {
                this.success = res.data.message;
            })
            .catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
    },

    mounted() {
        if(this.email) {
            this.payload.email = this.email;
        }
        if(this.reservation) {
            this.payload.reservation = this.reservation;
        }
        if(this.subject) {
            this.payload.subject = `RE: ${this.subject}`;
        }
    },
}
</script>

<style scoped>

</style>
