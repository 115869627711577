import {createRouter, createWebHistory} from 'vue-router';
/***********Frontend ***************/
import Index from './views/frontend/dashboard/home/Index'
import Home5 from './views/frontend/dashboard/home5/index'
import Home4 from './views/frontend/dashboard/home4/index'
import Home3 from './views/frontend/dashboard/home3/index'
import Home2 from './views/frontend/dashboard/home2/index'
import Aboutus from './views/frontend/pages/aboutus/about'
import Addservice from './views/frontend/pages/service/addservice'
import Bookservice from './views/frontend/pages/service/bookservice'
import Categories from './views/frontend/pages/categories'
import Chat from './views/frontend/pages/chat/chat'
import Contactus from './views/frontend/pages/contactus'
import Editservice from './views/frontend/pages/service/editservice'
import Faq from './views/frontend/pages/faq'
import Favourites from './views/frontend/customer/favourites'
import Myservice from './views/frontend/pages/service/myservice'
import Myserviceinactive from './views/frontend/pages/service/myserviceinactive'
import Notifcations from './views/frontend/pages/notification'
import Privacypolicy from './views/frontend/pages/privacypolicy'
import Provideravaiability from './views/frontend/provider/availability/provideravailability'
import Providerbooking from './views/frontend/provider/booking/providerbooking'
import Providerdash from './views/frontend/provider/dashboard/providerdash'
import Payment from './views/frontend/provider/payment/payment'
import Review from './views/frontend/provider/reviews/review'
import Settings from './views/frontend/provider/setting/settings'
import Subscription from './views/frontend/provider/subscription/subscription'
import Wallet from './views/frontend/provider/wallet/wallet'
import Search from './views/frontend/pages/search/search'
import HotelDetails from './views/frontend/pages/hotel/HotelDetails'
import BookingView from './views/frontend/pages/booking/BookingView'
import Servicedetail from './views/frontend/pages/servicedetail/servicedetail'
import Termcondition from './views/frontend/pages/termcondition'
import Customerdashboard from './views/frontend/customer/dashboard/index'
import Userpayment from './views/frontend/customer/payment/userpayment'
import Userreview from './views/frontend/customer/reviews/Userreview'
import Usersetting from './views/frontend/customer/setting/usersetting'
import Userwallet from './views/frontend/customer/wallet/userwallet'

import Booking from './views/frontend/customer/booking/booking'
import UserTravelPolicy from './views/frontend/customer/travelpolicy/UserTravelPolicy'

import Information from "./views/frontend/customer/information/Information";
import InformationFiles from "./views/frontend/customer/information/InformationFiles";
import InformationPrices from "./views/frontend/customer/information/InformationPrices";
import InformationInfo from "./views/frontend/customer/information/InformationInfo";

/**************** Admin  *************/
import AdminReservationView from './views/admin/reservation/AdminReservationView'
import AdminReservationItem from './views/admin/reservation/AdminReservationItem'
import AdminReservationEdit from './views/admin/reservation/AdminReservationEdit'
import AdminReservationCreate from './views/admin/reservation/AdminReservationCreate'

import AdminTravelPolicyView from './views/admin/travelpolicy/AdminTravelPolicyView'
import AdminTravelPolicyFiles from './views/admin/travelpolicy/AdminTravelPolicyFiles'
import AdminTravelPolicyList from './views/admin/travelpolicy/AdminTravelPolicyList'
import AdminTravelPolicyLimit from "./views/admin/travelpolicy/AdminTravelPolicyLimit"

import AdminInformation from "./views/admin/information/AdminInformation";
import AdminInformationFiles from "./views/admin/information/AdminInformationFiles";
import AdminInformationPrices from "./views/admin/information/AdminInformationPrices";
import AdminInformationUsers from "./views/admin/information/AdminInformationUsers";
import AdminInformationUsersCreate from "./views/admin/information/AdminInformationUsersCreate";
import AdminInformationUsersEdit from "./views/admin/information/AdminInformationUsersEdit";
import AdminInformationInfo from "./views/admin/information/AdminInformationInfo";
import PopularHotels from "./views/admin/setting/PopularHotels";


import Addcategory from './views/admin/category/addcategory'
import Faq1 from './views/admin/setting/faq'
import Addratingstype from './views/admin/rating/addrating'
import Addsubcategory from './views/admin/subcategory/addsubcategory'
import Addsubscription from './views/admin/subscription/addsubscription'
import Adminnotification from './views/admin/category/adminnoti'
import Adminprofile from './views/admin/category/adminprofile'
import Cancelreport from './views/admin/booking/cancelreport'
import Categories1 from './views/admin/category/categories'
import Completereport from './views/admin/booking/completereport'
import Editcategory from './views/admin/category/Editcategory'
import Editlang from './views/admin/setting/editlang'
import Editratingstype from './views/admin/rating/editrating'
import Editsubcategory from './views/admin/subcategory/editsubcategory'
import Editsubscription from './views/admin/subscription/editsubscription'
import Emailsetting from './views/admin/setting/emailsetting'
import Leftfooter from './views/admin/setting/footersetting/leftfooter'
import Wallethistory from './views/admin/wallet/Wallethistory'
import Adminwallet from './views/admin/wallet/wallet'
import Invoiceview from './views/admin/invoices/view/viewinvoice'
import AdminUsers from './views/admin/user/Users'
import AdminUsersActivate from './views/admin/user/UserActivate'
import AdminUsersEdit from './views/admin/user/UserEdit'
import AdminUserCreate from './views/admin/user/UserCreate'
import Admintranslation from './views/admin/translation'
import Admintotalreport from './views/admin/booking/totalreport'
import Taxsettings from './views/admin/invoices/setting/taxsettings'
import Adminsubscriptions from './views/admin/subscription/subscription'
import Adminsubcategory from './views/admin/subcategory/subcategory'
import Socialsetting from './views/admin/setting/social/socialsetting'
import Sociallink from './views/admin/setting/socialink/sociallinks'
import Generalsetting from './views/admin/setting/general/generalsetting'
import Serviceprovider from './views/admin/provider/serviceprovider'
import Servicelist from './views/admin/service/servicelist'
import Adminservicedetail from './views/admin/service/detail/servicedetail'
import Seosetting from './views/admin/setting/seo/seosetting'
import Reviewreport from './views/admin/reviewreport'
import Rejectreport from './views/admin/booking/rejectreport'
import Adminratingtype from './views/admin/rating/type/ratingtype'
import Privacypolicy1 from './views/admin/setting/privacypolicy'
import Pendingreport from './views/admin/booking/pendingreport'
import Paymentsetting from './views/admin/setting/payment/paymentsetting'
import Paymentlist from './views/admin/payment/paymentlist'
import Pages from './views/admin/setting/pages'
import Othersetting from './views/admin/setting/other/othersetting'
import Adminlogin from './views/admin/login'
import Localization from './views/admin/setting/localization'
import General from './views/admin/invoices/setting/general'
import Recurring from './views/admin/invoices/list/recurring'
import Paid from './views/admin/invoices/list/paid'
import Overdue from './views/admin/invoices/list/overdue'
import Draft from './views/admin/invoices/list/draft'
import Cancelled from './views/admin/invoices/list/cancelled'
import Invoice from './views/admin/invoices/list/invoice'
import Grid from './views/admin/invoices/grid/grid'
import Inprogress from './views/admin/booking/inprogress'
import Admindashboard from './views/admin/dashboard/index'
import Homepage from './views/admin/setting/home/homepage'
import Frontsettings from './views/admin/setting/front/frontsettings'
import Editinvoice from './views/admin/invoices/edit/editinvoice'
import Banksetting from './views/admin/invoices/setting/banksetting'
import Addinvoice from './views/admin/invoices/add/addinvoice'
import HotelPresentation from "./views/frontend/pages/hotel/HotelPresentation";
import PopularHotel from "./views/frontend/pages/hotel/PopularHotel";





const routesForUser = [
    {
        path: '/user-bookings',
        name: 'user-bookings',
        component: Booking
    },
    {
        path: '/user/travel-policy',
        name: 'user/travel-policy',
        component: UserTravelPolicy
    },
    {
        path: '/user-dashboard',
        name: 'user-dashboard',
        component: Customerdashboard
    },
    {
        path: '/user-payment',
        name: 'user-payment',
        component: Userpayment
    },
    {
        path: '/user-reviews',
        name: 'user-reviews',
        component: Userreview
    },
    {
        path: '/user-settings',
        name: 'user-settings',
        component: Usersetting
    },
    {
        path: '/user-wallet',
        name: 'user-wallet',
        component: Userwallet
    },

    {
        path: '/user/information',
        name: 'user/information',
        component: Information
    },
    {
        path: '/user/information/files',
        name: 'user/information/files',
        component: InformationFiles
    },
    {
        path: '/user/information/prices',
        name: 'user/information/prices',
        component: InformationPrices
    },
    {
        path: '/user/information/info',
        name: 'user/information/info',
        component: InformationInfo
    },

];

// routes for client
const routesForClient = [
    /* ADMIN ROUTES */
    {
        path: '/admin',
        name: '/admin',
        component: Admindashboard
    },
    /* RESERVATION */
    {
        path: '/admin/reservation/list',
        name: '/admin/reservation/list',
        component: AdminReservationView
    },
    {
        path: '/admin/reservation/:id',
        name: '/admin/reservation/item',
        component: AdminReservationItem
    },
    {
        path: '/admin/reservation/edit/:id',
        name: '/admin/reservation/edit',
        component: AdminReservationEdit
    },
    {
        path: '/admin/reservation/create',
        name: '/admin/reservation/create',
        component: AdminReservationCreate
    },
    /* TRAVEL POLICY */
    {
        path: '/admin/travelpolicy',
        name: '/admin/travelpolicy',
        component: AdminTravelPolicyView
    },
    {
        path: '/admin/travelpolicy/list',
        name: '/admin/travelpolicy/list',
        component: AdminTravelPolicyList
    },
    {
        path: '/admin/travelpolicy/limit',
        name: '/admin/travelpolicy/limit',
        component: AdminTravelPolicyLimit
    },
    {
        path: '/admin/travelpolicy/files',
        name: '/admin/travelpolicy/files',
        component: AdminTravelPolicyFiles
    },
    /* INFORMATION PANEL */
    {
        path: '/admin/information',
        name: '/admin/information',
        component: AdminInformation
    },
    {
        path: '/admin/information/files',
        name: '/admin/information/files',
        component: AdminInformationFiles
    },
    {
        path: '/admin/information/prices',
        name: '/admin/information/prices',
        component: AdminInformationPrices
    },
    {
        path: '/admin/information/users',
        name: '/admin/information/users',
        component: AdminInformationUsers
    },
    {
        path: '/admin/information/users/create',
        name: '/admin/information/users/create',
        component: AdminInformationUsersCreate
    },
    {
        path: '/admin/information/users/edit/:id',
        name: '/admin/information/users/edit',
        component: AdminInformationUsersEdit
    },
    {
        path: '/admin/information/info',
        name: '/admin/information/info',
        component: AdminInformationInfo
    },

    {
        path: '/admin/add-category',
        name: '/admin/add-category',
        component: Addcategory
    },
    {
        path: '/admin/add-faq',
        name: '/admin/add-faq',
        component: Faq1
    },
    {
        path: '/admin/add-ratingstype',
        name: '/admin/add-ratingstype',
        component: Addratingstype
    },
    {
        path: '/admin/add-subcategory',
        name: '/admin/add-subcategory',
        component: Addsubcategory
    },
    {
        path: '/admin/add-subscription',
        name: '/admin/add-subscription',
        component: Addsubscription
    },
    {
        path: '/admin/admin-notification',
        name: '/admin/admin-notification',
        component: Adminnotification
    },
    {
        path: '/admin/admin-profile',
        name: '/admin/admin-profile',
        component: Adminprofile
    },
    {
        path: '/admin/cancel-report',
        name: '/admin/cancel-report',
        component: Cancelreport
    },
    {
        path: '/admin/categories',
        name: '/admin/categories',
        component: Categories1
    },
    {
        path: '/admin/complete-report',
        name: '/admin/complete-report',
        component: Completereport
    },
    {
        path: '/admin/edit-category',
        name: '/admin/edit-category',
        component: Editcategory
    },
    {
        path: '/admin/edit-language',
        name: '/admin/edit-language',
        component: Editlang
    },
    {
        path: '/admin/edit-ratingstype',
        name: '/admin/edit-ratingstype',
        component: Editratingstype
    },
    {
        path: '/admin/edit-subcategory',
        name: '/admin/edit-subcategory',
        component: Editsubcategory
    },
    {
        path: '/admin/edit-subscription',
        name: '/admin/edit-subscription',
        component: Editsubscription
    },
    {
        path: '/admin/email-settings',
        name: '/admin/email-settings',
        component: Emailsetting
    },
    {
        path: '/admin/footer-settings',
        name: '/admin/footer-settings',
        component: Leftfooter
    },
    {
        path: '/admin/wallet-history',
        name: '/admin/wallet-history',
        component: Wallethistory
    },
    {
        path: '/admin/wallet',
        name: '/admin/wallet',
        component: Adminwallet
    },
    {
        path: '/admin/view-invoice',
        name: '/admin/view-invoice',
        component: Invoiceview
    },
    {
        path: '/admin/translation',
        name: '/admin/translation',
        component: Admintranslation
    },
    {
        path: '/admin/total-report',
        name: '/admin/total-report',
        component: Admintotalreport
    },
    {
        path: '/admin/tax-settings',
        name: '/admin/tax-settings',
        component: Taxsettings
    },
    {
        path: '/admin/subscriptions',
        name: '/admin/subscriptions',
        component: Adminsubscriptions
    },
    {
        path: '/admin/subcategories',
        name: '/admin/subcategories',
        component: Adminsubcategory
    },
    {
        path: '/admin/social-settings',
        name: '/admin/social-settings',
        component: Socialsetting
    },
    {
        path: '/admin/social-links',
        name: '/admin/social-links',
        component: Sociallink
    },
    {
        path: '/admin/settings',
        name: '/admin/settings',
        component: Generalsetting
    },
    {
        path: '/admin/service-providers',
        name: '/admin/service-providers',
        component: Serviceprovider
    },
    {
        path: '/admin/service-list',
        name: '/admin/service-list',
        component: Servicelist
    },
    {
        path: '/admin/service-details',
        name: '/admin/service-details',
        component: Adminservicedetail
    },
    {
        path: '/admin/seo-settings',
        name: '/admin/seo-settings',
        component: Seosetting
    },
    {
        path: '/admin/review-reports',
        name: '/admin/review-reports',
        component: Reviewreport
    },
    {
        path: '/admin/reject-report',
        name: '/admin/reject-report',
        component: Rejectreport
    },
    {
        path: '/admin/ratingstype',
        name: '/admin/ratingstype',
        component: Adminratingtype
    },
    {
        path: '/admin/privacy-policy',
        name: '/admin/privacy-policy',
        component: Privacypolicy1
    },
    {
        path: '/admin/pending-report',
        name: '/admin/pending-report',
        component: Pendingreport
    },
    {
        path: '/admin/payment-settings',
        name: '/admin/payment-settings',
        component: Paymentsetting
    },
    {
        path: '/admin/payment_list',
        name: '/admin/payment_list',
        component: Paymentlist
    },
    {
        path: '/admin/pages',
        name: '/admin/pages',
        component: Pages
    },
    {
        path: '/admin/others-settings',
        name: '/admin/others-settings',
        component: Othersetting
    },
    {
        path: '/admin/login',
        name: '/admin/login',
        component: Adminlogin
    },
    {
        path: '/admin/localization-details',
        name: '/admin/localization-details',
        component: Localization
    },
    {
        path: '/admin/invoices-settings',
        name: '/admin/invoices-settings',
        component: General
    },
    {
        path: '/admin/invoices-recurring',
        name: '/admin/invoices-recurring',
        component: Recurring
    },
    {
        path: '/admin/invoices-paid',
        name: '/admin/invoices-paid',
        component: Paid
    },
    {
        path: '/admin/invoices-overdue',
        name: '/admin/invoices-overdue',
        component: Overdue
    },
    {
        path: '/admin/invoices-draft',
        name: '/admin/invoices-draft',
        component: Draft
    },
    {
        path: '/admin/invoices-cancelled',
        name: '/admin/invoices-cancelled',
        component: Cancelled
    },
    {
        path: '/admin/invoices',
        name: '/admin/invoices',
        component: Invoice
    },
    {
        path: '/admin/invoice-grid',
        name: '/admin/invoice-grid',
        component: Grid
    },
    {
        path: '/admin/inprogress-report',
        name: '/admin/inprogress-report',
        component: Inprogress
    },

    {
        path: '/admin/home-page',
        name: '/admin/home-page',
        component: Homepage
    },
    {
        path: '/admin/front-settings',
        name: '/admin/front-settings',
        component: Frontsettings
    },
    {
        path: '/admin/edit-invoice',
        name: '/admin/edit-invoice',
        component: Editinvoice
    },
    {
        path: '/admin/bank-settings',
        name: '/admin/bank-settings',
        component: Banksetting
    },
    {
        path: '/admin/add-invoice',
        name: '/admin/add-invoice',
        component: Addinvoice
    },
];

// routes for superadmin
const routesForSuperadmin = [
    {
        path: '/admin/users',
        name: '/admin/users',
        component: AdminUsers
    },
    {
        path: '/admin/users-active',
        name: '/admin/users-active',
        component: AdminUsers
    },
    {
        path: '/admin/users-pending',
        name: '/admin/users-pending',
        component: AdminUsers
    },
    {
        path: '/admin/user-create',
        name: '/admin/user-create',
        component: AdminUserCreate
    },
    {
        path: '/admin/user/activate/:id',
        name: '/admin/users/activate',
        component: AdminUsersActivate
    },
    {
        path: '/admin/user/edit/:id',
        name: '/admin/users/edit',
        component: AdminUsersEdit
    },
    {
        path: '/admin/settings/popular-hotels/',
        name: '/admin/settings/popular-hotels',
        component: PopularHotels
    },
];

const commonRoutes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/index',
        name: 'indexx',
        component: Index
    },
    {
        path: '/hotel/:id/:checkin?/:checkout?/:adults?/:children?/:ageOne?/:ageTwo?/:ageThree?',
        name: 'hotel',
        component: HotelDetails
    },
    {
        path: '/hotel-presentation/:id/',
        name: 'hotel-presentation',
        component: HotelPresentation
    },
    {
        path: '/book/:hotelId/:roomId/:boardTypeId/:roomTypeId/:checkin/:checkout/:adults/:children/:ageOne?/:ageTwo?/:ageThree?',
        name: 'book',
        component: BookingView
    },
    {
        path: '/index-five',
        name: 'index-five',
        component: Home5
    },
    {
        path: '/index-four',
        name: 'index-four',
        component: Home4
    },
    {
        path: '/index-three',
        name: 'index-three',
        component: Home3
    },
    {
        path: '/index-two',
        name: 'index-two',
        component: Home2
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: Aboutus
    },
    {
        path: '/add-service',
        name: 'add-service',
        component: Addservice
    },
    {
        path: '/book-service',
        name: 'book-service',
        component: Bookservice
    },
    {
        path: '/categories',
        name: 'categories',
        component: Categories
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: Contactus
    },
    {
        path: '/edit-service',
        name: 'edit-service',
        component: Editservice
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites
    },
    {
        path: '/my-services',
        name: 'my-services',
        component: Myservice
    },
    {
        path: '/my-services-inactive',
        name: 'my-services-inactive',
        component: Myserviceinactive
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifcations
    },
    {
        path: '/privacy-policy1',
        name: 'privacy-policy',
        component: Privacypolicy
    },
    {
        path: '/provider-availability',
        name: 'provider-availability',
        component: Provideravaiability
    },
    {
        path: '/provider-bookings',
        name: 'provider-bookings',
        component: Providerbooking
    },
    {
        path: '/provider-dashboard',
        name: 'provider-dashboard',
        component: Providerdash
    },
    {
        path: '/provider-payment',
        name: 'provider-payment',
        component: Payment
    },
    {
        path: '/provider-reviews',
        name: 'provider-reviews',
        component: Review
    },
    {
        path: '/provider-settings',
        name: 'provider-settings',
        component: Settings
    },
    {
        path: '/provider-subscription',
        name: 'provider-subscription',
        component: Subscription
    },
    {
        path: '/provider-wallet',
        name: 'provider-wallet',
        component: Wallet
    },
    {
        path: '/search/:page?/:destination?/:checkin?/:checkout?/:adults?/:children?/:ageOne?/:ageTwo?/:ageThree?',
        name: 'search',
        component: Search
    },
    {
        path: '/service-details',
        name: 'service-details',
        component: Servicedetail
    },
    {
        path: '/term-condition',
        name: 'term-condition',
        component: Termcondition
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: Index,
    }
];

let routes;
switch(window.role) {
    case 'user':
        routes = [...routesForUser, ...commonRoutes];
        break;
    case 'client':
        routes = [...routesForUser, ...routesForClient, ...commonRoutes];
        break;
    case 'superadmin':
        routes = [...routesForUser, ...routesForClient, ...routesForSuperadmin, ...commonRoutes];
        break;
    default:
        routes = [...commonRoutes];
}

export const router = createRouter({
    history: createWebHistory(''),
    linkActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
});
