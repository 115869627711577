<template>
 <!-- Page Header -->
 <div class="page-header">
    <div class="row">
        <div class="col-12">
            <h3 class="page-title">Witaj {{ globalUser.firstname }} {{ globalUser.lastname }}!</h3>
        </div>
    </div>
</div>
<!-- /Page Header -->

<div class="row" v-if="dashboard">
    <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
            <div class="card-body">
                <div class="dash-widget-header">
                    <span class="dash-widget-icon bg-primary">
                        <i class="far fa-user"></i>
                    </span>
                    <div class="dash-widget-info">
                        <h3>{{dashboard.users}}</h3>
                        <h6 class="text-muted">Użytkownicy</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
            <div class="card-body">
                <div class="dash-widget-header">
                    <span class="dash-widget-icon bg-primary">
                        <i class="fas fa-calendar-check"></i>
                    </span>
                    <div class="dash-widget-info">
                        <h3>{{ dashboard.reservations }}</h3>
                        <h6 class="text-muted">Rezerwacje</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
            <div class="card-body">
                <div class="dash-widget-header">
                    <span class="dash-widget-icon bg-primary">
                        <i class="fas fa-money-bill"></i>
                    </span>
                    <div class="dash-widget-info">
                        <h3>{{ dashboard.total }} PLN</h3>
                        <h6 class="text-muted">Suma rezerwacji</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
            <div class="card-body">
                <div class="dash-widget-header">
                    <span class="dash-widget-icon bg-primary">
                        <i class="fas fa-money-check"></i>
                    </span>
                    <div class="dash-widget-info">
                        <h3>{{ dashboard.average }} PLN</h3>
                        <h6 class="text-muted">Średnia kwota rezerwacji</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {

    components: {
        Loading,
    },

    data() {
        return {
            loading: false,
            dashboard: null,
        }
    },

    methods: {
        getData() {
            this.loading = true;
            axios.get(`/api/admin/dashboard`)
                .then(response => {
                    this.dashboard = response.data;
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    created() {
        this.getData();
    },

}
</script>

<style scoped>

</style>
