<template>
	<div>
	<div class="main-wrapper">
	<!-- Header -->
	<header class="header">
		<nav class="navbar navbar-expand-lg header-nav">
			<div class="navbar-header">
				<a id="mobile_btn" href="javascript:void(0);">
					<span class="bar-icon">
						<span></span>
						<span></span>
						<span></span>
					</span>
				</a>
				<router-link to="/" class="navbar-brand logo">
					<img src="/images/logo.png" class="img-fluid" alt="Logo">
				</router-link>
				<router-link to="/" class="navbar-brand logo-small">
					<img src="/images/logo-icon.png" class="img-fluid" alt="Logo">
				</router-link>
			</div>
			<div class="main-menu-wrapper">
				<div class="menu-header">
					<router-link to="/" class="menu-logo">
						<img src="/images/logo.png" class="img-fluid" alt="Logo">
					</router-link>
					<a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
				</div>
				<ul class="main-nav">
					<li class="has-submenu" :class=" homeMenu ? 'active' : 'notactive'">
						<a href="javascript:void(0);">Home <i class="fas fa-chevron-down"></i></a>
						<ul class="submenu">
							<li :class="currentPath == 'index' ? 'active' : 'notactive'"><router-link to="/">Home</router-link></li>
							<li :class="currentPath == 'index-two' ? 'active' : 'notactive'"><router-link to="/index-two">Home 2</router-link></li>
							<li :class="currentPath == 'index-three' ? 'active' : 'notactive'"><router-link to="/index-three">Home 3</router-link></li>
							<li :class="currentPath == 'index-four' ? 'active' : 'notactive'"><router-link to="/index-four">Home 4</router-link></li>
							<li :class="currentPath == 'index-five' ? 'active' : 'notactive'"><router-link to="/index-five">Home 5</router-link></li>
						</ul>
					</li>
					<li v-bind:class="{'active': currentPath == 'categories'}">
						<router-link to="/categories">Categories</router-link>
					</li>
					<li class="has-submenu" :class=" providerMenu ? 'active' : 'notactive'">
						<a href="javascript:void(0);">Providers <i class="fas fa-chevron-down"></i></a>
						<ul class="submenu">
							<li :class="currentPath == 'provider-dashboard' ? 'active' : 'notactive'"><router-link to="/provider-dashboard">Dashboard</router-link></li>
							<li :class="currentPath == 'my-services' || currentPath == 'my-services-inactive' ? 'active' : 'notactive'"><router-link to="/my-services">Services</router-link></li>
							<li :class="currentPath == 'provider-bookings' ? 'active' : 'notactive'"><router-link to="/provider-bookings">Bookings</router-link></li>
							<li :class="currentPath == 'provider-settings' ? 'active' : 'notactive'"><router-link to="/provider-settings">Profile Settings</router-link></li>
							<li :class="currentPath == 'provider-wallet' ? 'active' : 'notactive'"><router-link to="/provider-wallet">Wallet</router-link></li>
							<li :class="currentPath == 'provider-subscription' ? 'active' : 'notactive'"><router-link to="/provider-subscription">Subscription</router-link></li>
							<li :class="currentPath == 'provider-availability' ? 'active' : 'notactive'"><router-link to="/provider-availability">Availability</router-link></li>
							<li :class="currentPath == 'provider-reviews' ? 'active' : 'notactive'"><router-link to="/provider-reviews">Reviews</router-link></li>
							<li :class="currentPath == 'provider-payment' ? 'active' : 'notactive'"><router-link to="/provider-payment">Payment</router-link></li>
						</ul>
					</li>
					<li class="has-submenu" :class=" customerMenu ? 'active' : 'notactive'">
						<a href="javascript:void(0);">Customers <i class="fas fa-chevron-down"></i></a>
						<ul class="submenu">
							<li :class="currentPath == 'user-dashboard' ? 'active' : 'notactive'"><router-link to="/user-dashboard">Dashboard</router-link></li>
							<li :class="currentPath == 'favourites' ? 'active' : 'notactive'"><router-link to="/favourites">Favourites</router-link></li>
							<li :class="currentPath == 'user-bookings' ? 'active' : 'notactive'"><router-link to="/user-bookings">Bookings</router-link></li>
							<li :class="currentPath == 'user-settings' ? 'active' : 'notactive'"><router-link to="/user-settings">Profile Settings</router-link></li>
							<li :class="currentPath == 'user-wallet' ? 'active' : 'notactive'"><router-link to="/user-wallet">Wallet</router-link></li>
							<li :class="currentPath == 'user-reviews' ? 'active' : 'notactive'"><router-link to="/user-reviews">Reviews</router-link></li>
							<li :class="currentPath == 'user-payment' ? 'active' : 'notactive'"><router-link to="/user-payment">Payment</router-link></li>
						</ul>
					</li>
					<li class="has-submenu" :class=" pageMenu ? 'active' : 'notactive'">
						<a href="javascript:void(0);">Pages <i class="fas fa-chevron-down"></i></a>
						<ul class="submenu">
							<li><router-link to="/search">Search</router-link></li>
							<li :class="currentPath == 'service-details' ? 'active' : 'notactive'"><router-link to="/service-details">Service Details</router-link></li>
							<li :class="currentPath == 'add-service' ? 'active' : 'notactive'"><router-link to="/add-service">Add Service</router-link></li>
							<li :class="currentPath == 'edit-service' ? 'active' : 'notactive'"><router-link to="/edit-service">Edit Service</router-link></li>
							<li :class="currentPath == 'chat' ? 'active' : 'notactive'"><router-link to="/chat">Chat</router-link></li>
							<li :class="currentPath == 'notifications' ? 'active' : 'notactive'"><router-link to="/notifications">Notifications</router-link></li>
							<li :class="currentPath == 'about-us' ? 'active' : 'notactive'"><router-link to="/about-us">About Us</router-link></li>
							<li :class="currentPath == 'contact-us' ? 'active' : 'notactive'"><router-link to="/contact-us">Contact Us</router-link></li>
							<li :class="currentPath == 'faq' ? 'active' : 'notactive'"><router-link to="/faq">Faq</router-link></li>
							<li :class="currentPath == 'term-condition' ? 'active' : 'notactive'"><router-link to="/term-condition">Terms & Conditions</router-link></li>
							<li :class="currentPath == 'privacy-policy1' ? 'active' : 'notactive'"><router-link to="/privacy-policy1">Privacy Policy</router-link></li>
						</ul>
					</li>
					<li>
						<router-link to="/admin/login" target="_blank">Admin</router-link>
					</li>
				</ul>
			</div>
			<ul class="nav header-navbar-rht">

                <li class="nav-item desc-list">
                    <router-link to="/add-service" class="nav-link header-login">
                        <i class="fas fa-plus-circle me-1"></i> <span>Post a Service</span>
                    </router-link>
                </li>

                <!-- Notifications -->
                <li class="nav-item dropdown logged-item">
                    <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-bell"></i> <span class="badge badge-pill bg-yellow">1</span>
                    </a>
                    <div class="dropdown-menu notify-blk dropdown-menu-end notifications">
                        <div class="topnav-dropdown-header">
                            <span class="notification-title">Notifications</span>
                            <a href="javascript:void(0)" class="clear-noti">Clear All  </a>
                        </div>
                        <div class="noti-content">
                            <ul class="notification-list">
                                <li class="notification-message">
                                    <router-link to="/notifications">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-01.jpg">
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"> <span class="noti-title">Jeffrey Akridge has booked your service</span></p>
                                                <p class="noti-time"><span class="notification-time">Today 10:04 PM</span></p>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="notification-message">
                                    <router-link to="/notifications">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-02.jpg">
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"> <span class="noti-title">Nancy Olson has booked your service</span></p>
                                                <p class="noti-time"><span class="notification-time">Today 9:45 PM</span></p>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="notification-message">
                                    <router-link to="/notifications">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-03.jpg">
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"> <span class="noti-title">Ramona Kingsley has booked your service</span></p>
                                                <p class="noti-time"><span class="notification-time">Yesterday 8:17 AM</span></p>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="notification-message">
                                    <router-link to="/notifications">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-04.jpg">
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"> <span class="noti-title">Ricardo Lung has booked your service</span></p>
                                                <p class="noti-time"><span class="notification-time">Yesterday 6:20 AM</span></p>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="notification-message">
                                    <router-link to="/notifications">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image" src="/images/customer/user-05.jpg">
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"> <span class="noti-title">Annette Silva has booked your service</span></p>
                                                <p class="noti-time"><span class="notification-time">17 Sep 2020 10:04 PM</span></p>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="topnav-dropdown-footer">
                            <router-link to="/notifications">View all Notifications</router-link>
                        </div>
                    </div>
                </li>
                <!-- /Notifications -->

                <!-- chat -->
                <li class="nav-item logged-item">
                    <router-link to="/chat" class="nav-link">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                    </router-link>
                </li>
                <!-- /chat -->

                <!-- User Menu -->
                <li class="nav-item dropdown has-arrow logged-item">
                    <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="user-img">
                            <img class="rounded-circle" src="/images/provider/provider-01.jpg" alt="" width="31">
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <div class="user-header">
                            <div class="avatar avatar-sm">
                                <img class="avatar-img rounded-circle" src="/images/provider/provider-01.jpg" alt="">
                            </div>
                            <div class="user-text">
                                <h6 class="text-truncate">Thomas</h6>
                                <p class="text-muted mb-0">Provider</p>
                            </div>
                        </div>
                        <router-link class="dropdown-item" to="/provider-dashboard">Dashboard</router-link>
                        <router-link class="dropdown-item" to="/my-services">My Services</router-link>
                        <router-link class="dropdown-item" to="/provider-bookings">Booking List</router-link>
                        <router-link class="dropdown-item" to="/provider-settings">Profile Settings</router-link>
                        <router-link class="dropdown-item" to="/provider-wallet">Wallet</router-link>
                        <router-link class="dropdown-item" to="/provider-subscription">Subscription</router-link>
                        <router-link class="dropdown-item" to="/provider-availability">Availability</router-link>
                        <router-link class="dropdown-item" to="/chat">Chat</router-link>
                        <router-link class="dropdown-item" to="/index">Logout</router-link>
                    </div>
                </li>
                <!-- /User Menu -->

            </ul>
		</nav>
	</header>
	<!-- /Header -->
	</div>
	<!-- Provider Register Modal -->
	<div class="modal account-modal fade multi-step" id="provider-register" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Join as a Provider</h3>
					</div>

					<!-- Register Form -->
					<form>
						<div class="form-group form-focus">
							<label class="focus-label">Name</label>
							<input type="text" class="form-control" placeholder="johndoe@exapmle.com">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Mobile Number</label>
							<input type="text" class="form-control" placeholder="986 452 1236">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Create Password</label>
							<input type="password" class="form-control" placeholder="********">
						</div>
						<div class="text-end">
							<a class="forgot-link" href="#">Already have an account?</a>
						</div>
						<router-link to="/">
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Signup</button>
						</div>
					    </router-link>
						<div class="login-or">
							<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f me-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google me-1"></i> Login</a>
							</div>
						</div>
					</form>
					<!-- /Register Form -->

				</div>
			</div>
		</div>
	</div>
	<!-- /Provider Register Modal -->

	<!-- User Register Modal -->
	<div class="modal account-modal fade multi-step" id="user-register" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Join as a User</h3>
					</div>

					<!-- Register Form -->
					<form>
						<div class="form-group form-focus">
							<label class="focus-label">Name</label>
							<input type="text" class="form-control" placeholder="johndoe@exapmle.com">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Mobile Number</label>
							<input type="text" class="form-control" placeholder="986 452 1236">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Create Password</label>
							<input type="password" class="form-control" placeholder="********">
						</div>
						<div class="text-end">
							<a class="forgot-link" href="#">Already have an account?</a>
						</div>
						<router-link to="/">
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Signup</button>
						</div>
					    </router-link>
						<div class="login-or">
							<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f me-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google me-1"></i> Login</a>
							</div>
						</div>
					</form>
					<!-- /Register Form -->

				</div>
			</div>
		</div>
	</div>
	<!-- /User Register Modal -->

	<!-- Login Modal -->
	<div class="modal account-modal fade" id="login_modal">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Login <span>Truelysell</span></h3>
					</div>
					<form>
						<div class="form-group form-focus">
							<label class="focus-label">Email</label>
							<input type="email" class="form-control" placeholder="truelysell@example.com">
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Password</label>
							<input type="password" class="form-control" placeholder="********">
						</div>
						<div class="text-end">
						</div>
						<router-link to="/">
						<div class="d-grid">
							<button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Login</button>
						</div>
					    </router-link>
						<div class="login-or">	<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f me-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google me-1"></i> Login</a>
							</div>
						</div>
						<div class="text-center dont-have">Don’t have an account? <a href="javascript:void(0);">Register</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /Login Modal -->
	</div>
</template>
<script>
import AOS from 'aos';
export default {
        mounted() {
			if($('.main-wrapper .aos').length > 0) {
	    AOS.init({
		  duration: 1200,
		  once: true,
		});
	}
			if($(window).width() <= 991) {
		var Sidemenu = function() {
			this.$menuItem = $('.main-nav a');
		};

		function init() {
			var $this = Sidemenu;
			$('.main-nav a').on('click', function(e) {
				if($(this).parent().hasClass('has-submenu')) {
					e.preventDefault();
				}
				if(!$(this).hasClass('submenu')) {
					$('ul', $(this).parents('ul:first')).slideUp(350);
					$('a', $(this).parents('ul:first')).removeClass('submenu');
					$(this).next('ul').slideDown(350);
					$(this).addClass('submenu');
				} else if($(this).hasClass('submenu')) {
					$(this).removeClass('submenu');
					$(this).next('ul').slideUp(350);
				}
			});
		}

	// Sidebar Initiate
	init();
	}
		// Mobile menu sidebar overlay
		$('.header-fixed').append('<div class="sidebar-overlay"></div>');
	$(document).on('click', '#mobile_btn', function() {
		$('main-wrapper').toggleClass('slide-nav');
		$('.sidebar-overlay').toggleClass('opened');
		$('html').addClass('menu-opened');
		return false;
	});
	$(document).on('click', '.sidebar-overlay', function() {
		$('html').removeClass('menu-opened');
		$(this).removeClass('opened');
		$('main-wrapper').removeClass('slide-nav');
	});

	$(document).on('click', '#menu_close', function() {
		$('html').removeClass('menu-opened');
		$('.sidebar-overlay').removeClass('opened');
		$('main-wrapper').removeClass('slide-nav');
	});
    },
       computed: {
           currentPath() {
               return this.$route.name
           },
	   homeMenu() {
		return this.$route.name == '/' ||  this.$route.name == 'index' || this.$route.name == 'index-two' || this.$route.name == 'index-three' || this.$route.name == 'index-four' || this.$route.name == 'index-five';
	   },
	   providerMenu() {
        return this.$route.name == 'provider-dashboard' || this.$route.name == 'my-services-inactive' || this.$route.name == 'my-services' || this.$route.name == 'provider-bookings' ||  this.$route.name == 'provider-settings' ||  this.$route.name == 'provider-wallet' ||  this.$route.name == 'provider-subscription' ||  this.$route.name == 'provider-availability' ||  this.$route.name == 'provider-reviews' ||  this.$route.name == 'provider-payment';
       },
	   pageMenu() {
          return this.$route.name == 'search' || this.$route.name == 'service-details' || this.$route.name == 'add-service' ||  this.$route.name == 'edit-service' ||  this.$route.name == 'chat' ||  this.$route.name == 'notifications' ||  this.$route.name == 'about-us' ||  this.$route.name == 'contact-us' ||  this.$route.name == 'faq' ||  this.$route.name == 'term-condition' ||  this.$route.name == 'privacy-policy1';
       },
	   customerMenu() {
          return this.$route.name == 'user-dashboard' || this.$route.name == 'favourites' || this.$route.name == 'user-bookings' || this.$route.name == 'user-settings' ||  this.$route.name == 'user-wallet' ||  this.$route.name == 'user-reviews' ||  this.$route.name == 'user-payment';
       },
       },
    }
    </script>
