<template>
<div class="card transaction-table mb-0">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-center mb-0 no-footer">
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="item in subscriptiontable" :key="item.id">
                                            <td>{{item.name}}</td>
                                            <td>{{item.date}}</td>
                                            <td>{{item.date1}}</td>
                                            <td>{{item.time}}</td>
                                            <td><span class="badge bg-success-light">{{item.status}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
</template>
<script>
	import subscriptiontable from '../../../../../assets/json/website/subscriptiontable.json'
	export default {
	mounted() {
	},
	data() {
        return {
            subscriptiontable: subscriptiontable
        }
    }
	}
	</script>