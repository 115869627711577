<template>
 <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Użytkownicy</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->

                    <!-- Search Filter -->
                    <div class="card filter-card" id="filter_inputs">
                        <div class="card-body pb-0">
                            <form>
                                <div class="row filter-row">
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input class="form-control" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <div class="cal-icon">
                                                <datepicker v-model="startdate"  class="picker"
                                                        :editable="true"
                                                        :clearable="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <div class="cal-icon">
                                                <datepicker v-model="enddate"  class="picker"
                                                        :editable="true"
                                                        :clearable="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <button class="btn btn-primary btn-block w-100" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="settings-menu-links">
                        <ul class="nav nav-tabs menu-tabs">
                            <li class="nav-item" :class="{'active': currentPath === '/admin/users'}">
                                <router-link class="nav-link" to="/admin/users">Wszyscy użytkownicy</router-link>
                            </li>
                            <li class="nav-item" :class="{'active': currentPath === '/admin/users-pending'}">
                                <router-link class="nav-link" to="/admin/users-pending">Oczekujący</router-link>
                            </li>
                            <li class="nav-item" :class="{'active': currentPath === '/admin/users-active'}">
                                <router-link class="nav-link" to="/admin/users-active">Aktywni</router-link>
                            </li>
                            <li class="nav-item" :class="{'active': currentPath === '/admin/user-create'}">
                                <router-link class="nav-link" to="/admin/user-create">Stwórz użytkownika</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- /Search Filter -->
</template>
<script>
    import { ref } from 'vue'
    const currentDate = ref(new Date())
    const currentDate1 = ref(new Date())
	export default {


        computed: {
            currentPath() {
                return this.$route.name;
            }

        },

        data() {
            return {
                startdate: currentDate,
                enddate: currentDate1
            }
        },

        mounted() {

        },

	}
	</script>
