<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Polityka podróży</h3>
                            </div>
                            <div class="col-auto text-center d-flex justify-content-between ">
                                <button class="btn btn-primary mr-3" data-toggle="modal" data-target="#addFilesModal">Dodaj</button>

                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive">

                                        <table id="travel-policies" class="table table-hover table-center mb-0 datatable" v-if="travelPolicyFiles">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nazwa</th>
                                                <th>Oryginalna nazwa</th>
                                                <th>Pobierz</th>
                                                <th>Akcja</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(policy, index) in travelPolicyFiles" :key="policy.id">
                                                <td>{{ policy.id }}</td>
                                                <td>{{ policy.title }}</td>
                                                <td>{{ policy.file.file_name }}</td>
                                                <td><a :href="policy.file.file_path" target="_blank">Pobierz</a></td>
                                                <td>
                                                    <button class="btn btn-danger mr-3" data-toggle="modal" data-target="#deleteConfirmModal" @click="deletePolicy(policy.id)">Usuń</button>
                                                    <button class="btn btn-warning" data-toggle="modal" data-target="#editFilesModal" @click="updatePolicy(policy.id)">Aktualizuj</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>

        <div class="modal fade" id="addFilesModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Dodaj nową pozycję</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <file-upload
                            endpoint="/api/admin/travel-policy/file"
                            @success="getAll"
                            :additional-fields="additionalFields"
                        ></file-upload>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editFilesModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="form-header text-start mb-0">
                            <h4 class="mb-0">Edytuj plik - wgraj</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <file-upload
                            endpoint="/api/admin/travel-policy/file"
                            :file-to-update="policyToUpdate"
                            action="update"
                            @success="fileUpdated"
                        ></file-upload>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz usunąć pozycję??</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="deletePolicyWithId" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import FileUpload from "../../partials/FileUpload";
export default {

    components: {
        FileUpload,
        Loading,
    },

    data() {
        return {
            loading: false,
            travelPolicyFiles: [],
            newItemPayload: {
                title: null,
                price: null,
            },
            policyToDelete: null,
            policyToUpdate: null,
            clients: null,
        }
    },

    computed: {
        additionalFields() {
            let fields =  [];
            if(this.clients) {
                const clients = this.clients.reduce((acc, item) => {
                    acc[item.id] = `${item.id}. ${item.firstname} ${item.lastname}`
                    return acc;
                }, {});

                if(this.isSuperadmin) {
                    fields.push({
                        type: 'select',
                        label: 'Użytkownik',
                        key: 'user',
                        value: clients,
                    });
                }
            }

            return fields;
        },
    },

    methods: {
        deletePolicy(id) {
            this.policyToDelete = id;
        },

        updatePolicy(id) {
            this.policyToUpdate = id;
        },

        fileUpdated() {
            $('#editFilesModal').modal('hide');
            this.getAll();
        },

        getClientList() {
            axios.get('/api/admin/users/clients')
                .then(response => {
                    this.clients = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        deletePolicyWithId() {
            this.loading = true;
            axios.delete(`/api/admin/travel-policy/file/${this.policyToDelete}`)
                .then(() => {
                    this.travelPolicyFiles = this.travelPolicyFiles.filter(policy => policy.id !== this.policyToDelete);
                    $('#deleteConfirmModal').modal('hide');
                    this.policyToDelete = null;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        addNew() {
            this.loading = true;
            axios.post(`/api/admin/travel-policy`, this.newItemPayload)
                .then((response) => {
                    $('#addFilesModal').modal('hide');
                    this.getAll();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        saveAll() {
            this.loading = true;
            axios.put(`/api/admin/travel-policy`, this.travelPolicyFiles)
                .then((response) => {
                    console.log(response.data)
                    this.travelPolicyFiles = response.data
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getAll() {
            this.loading = true;
            axios.get('/api/admin/travel-policy/file')
                .then(response => {
                    this.travelPolicyFiles = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    created() {
        this.getAll();
        if(this.isSuperadmin) {
            this.getClientList();
        }
    }
}
</script>

<style scoped>

</style>
