<template>

<!-- Footer -->
  <footer class="footer mt-5">

    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title double-color">Dane adresowe</h2>
              <div class="footer-contact-info">
                <h5 class="mb-4">Trivium Travel Sp. z o.o.</h5>
                <div class="footer-address mb-3">
                  <span><i class="far fa-building"></i></span>
                  <p>ul. Wadowicka 8a<br>
                    30-415 Kraków</p>
                </div>
                <p><i class="fas fa-headphones"></i> +48 798 712 639</p>
                <p class="mb-0"><i class="fas fa-envelope"></i> biuro@travelpartner.pl</p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title double-color">System rezerwacji</h2>
              <ul>
                <li>
                  <a href="#">Warunki rezerwacji</a>
                </li>
                <li>
                  <a href="#">Zmiana rezerwacji</a>
                </li>
                <li>
                  <a href="#">Anulowanie rezerwacji</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Rezerwacja grupowa</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Gwarancja najniższej ceny</a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title double-color">Trivium travel</h2>
              <ul>
                <li>
                  <a href="#">O nas</a>
                </li>
                <li>
                  <a href="#">Obsługa firm</a>
                </li>
                <li>
                  <a href="#">Polityka cookies</a>
                </li>
                <li>
                  <a href="#">Polityka prywatności</a>
                </li>
                <li>
                  <a href="#">Zgłoś błąd</a>
                </li>
                <li>
                  <a href="#">Kontakt</a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title double-color">Kontakt z nami</h2>
              <div class="social-icon">
                  <ul style="list-style: none; display: flex; gap: 10px; padding: 0; margin: 0;">
                      <li>
                          <a href="https://www.facebook.com/triviumtravelpl/" target="_blank" style="background-color: rgb(245, 130, 32); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%; text-decoration: none;">
                              <i class="fab fa-facebook-f" style="color: white; font-size: 16px; transform: translateX(76%);"></i>
                          </a>
                      </li>
                      <li>
                          <a href="https://twitter.com/triviumtravel" target="_blank" style="background-color: rgb(245, 130, 32); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%; text-decoration: none;">
                              <i class="fab fa-twitter" style="color: white; font-size: 16px; transform: translateX(54%);"></i>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.linkedin.com/company/trivium-travel/" target="_blank" style="background-color: rgb(245, 130, 32); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%; text-decoration: none;">
                              <i class="fab fa-linkedin" style="color: white; font-size: 16px; transform: translateX(58%);"></i>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.instagram.com/triviumtravel/" target="_blank" style="background-color: rgb(245, 130, 32); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%; text-decoration: none;">
                              <i class="fab fa-instagram" style="color: white; font-size: 16px; transform: translateX(55%);"></i>
                          </a>
                      </li>
                  </ul>
              </div>
              <div class="subscribe-form d-none">
                <input type="email" class="form-control" placeholder="Twój adres E-mail">
                <button onclick="void(0)" type="submit" class="btn footer-btn">
                  <i class="fas fa-paper-plane"></i>
                </button>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">Copyright &copy; 2024 <a href="/">Trivium Travel Sp. z o.o.</a>.</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu float-right">
                Realizacja: <a href="https://www.dreampics.pl/">Dreampics</a>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->

  </footer>

</template>
