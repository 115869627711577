<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                     <bookingheader></bookingheader>

                     <bookingnav></bookingnav>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable" id="totalreport">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Provider</th>
                                                    <th>Service</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>Updated</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="item in totalreport" :key="item.id">
                                                <tr>
                                                    <td>{{item.no}}</td>
                                                    <td>{{item.date}}</td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg(item.img)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name}}</a>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" alt="" :src="loadImg1(item.img1)">
                                                            </a>
                                                            <a href="javascript:void(0);">{{item.name1}}</a>
                                                        </span>
                                                    </td>
                                                    <td>{{item.description}}</td>
                                                    <td>{{item.rate}}</td>
                                                    <td>
                                                        <label class="badge badge-dark">{{item.status}}</label>
                                                    </td>
                                                    <td>{{item.date1}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
	import totalreport from '../../../../assets/json/admin/booking/totalreport.json'
    import util from '../../../../assets/utils/util'
	const images = require.context('../../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/admin_img/provider', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
        util.datatable('#totalreport')
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            totalreport: totalreport
        }
    }
	}
	</script>