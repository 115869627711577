<template>
<section class="app-set phone-app">
        <div class="down-bg-screen">
            <div class="down-bg-dots">
                <div class="container">
                    <div class="app-spaces aos" data-aos="fade-up">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-6 col-12">
                                <div class="appimg-set phone-img ">
                                    <img src="/images/down-app-03.png" alt="img">
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-12">
                                <div class="col-md-12">
                                    <div class="heading aos" data-aos="fade-up">
                                        <h2>Download Our App</h2>
                                        <span>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.Aliquam lorem ante, dapibus in, viverra quis, feugiat </span>
                                    </div>
                                </div>
                                <div class="downlaod-set app-set-img aos" data-aos="fade-up">
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);"><img src="/images/app-ap-02.png" alt="img"></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);"><img src="/images/app-gp-02.png" alt="img"></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>