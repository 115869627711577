<template>
  <div class="hotel-details col-lg-4 theiaStickySidebar" v-if="details">
    <div class="stickyside">

      <div class="hotel-details__sidebar-widget widget">

        <div class="hotel-details__price" v-if="details.price">
          od <strong> {{ Math.ceil(details.price * 1.10 * rate) }} PLN</strong>
        </div>

        <div class="hotel-details__book-button">
          <a href="#hotel-rooms" class="btn btn-primary scroll-down"> Wybierz pokój</a>
        </div>

      </div>

      <div class="card provider-widget clearfix">
        <div class="card-body">

          <h5 class="card-title">Adres hotelu</h5>

          <div class="hotel-details__address-details">

            <p class="hotel-details__address-details__street">
              {{ details.details.address.street1 }}
              {{ (details.details.address.street2 !== '') ? ', ' : '' }}
              {{ details.details.address.street2 }}
            </p>

            <p class="hotel-details__address-details__zip text-muted mb-1">{{ details.details.address.zip }} {{ details.details.address.city }}</p>

          </div>

          <hr>

          <div class="provider-info" v-if="false">
            <p class="mb-1" v-if="details.details.email"><i class="far fa-envelope"></i> <a href="javascript:void(0);">{{
                details.details.email
              }}</a></p>
            <p class="mb-0" v-if="details.details.phone"><i class="fas fa-phone-alt"></i> {{ details.details.phone }}
            </p>
          </div>

        </div>
      </div>

      <div class="card available-widget">
        <iframe
          :src="`https://maps.google.com/maps?q=${ details.details.coordinates.latitude }, ${ details.details.coordinates.longitude }&z=15&output=embed`"
          height="450"
          style="border:0; width: 100%;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>


        <div class="card provider-widget clearfix" v-if="availability && false">
            <div class="card-body">

                <h5 class="card-title">Zasady rezerwacji</h5>

                <div class="hotel-details__address-details">

                    <p class="hotel-details__address-details"
                       v-html="bookingRemarks"></p>

                </div>

            </div>
        </div>

      <div class="card available-widget">
        <div class="card-body">
          <h5 class="card-title">Udogodnienia</h5>
          <ul>
            <li><span><i class="fas fa-wifi"></i> Wifi</span><i class="fas fa-parking"></i> Parking</li>
            <li><span><i class="fas fa-snowflake"></i> Klimatyzacja</span><i class="fas fa-utensils"></i> Restauracja</li>
            <li><span><i class="fas fa-shopping-basket"></i> Mini-market</span><i class="fas fa-smoking-ban"></i> Non-smoking rooms</li>

          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {

  props: {
    details: Object,
      bookingRemarks: String,
      availability: Object,
      rate: Number,
  },

  data() {
    return {

    }
  },

  mounted() {

  },
}
</script>

<style scoped>
.available-widget ul li {
  margin-right: 3rem;
}
</style>
