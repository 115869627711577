<template>
    <div :class="{'admin-panel': isAdminPanel}">
        <loader v-if="!status"></loader>
        <router-view/>

    </div>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            status: false,
            isAdminPanel: false
        }
    },
    watch: {
        '$route': function (newRoute) {
            this.isAdminPanel = newRoute.path.includes("/admin") && !this.isUser;
        }
    },
    created() {
        this.isAdminPanel = this.$route.path.includes("/admin") && !this.isUser;
    }

}
</script>


