
const state = {
    hotels: null,
    hotelDetails: null,
}


const getters = {
    hotels: state => {
        return state.hotels;
    },
    hotelDetails: state => {
        return state.hotelDetails;
    },
}


const actions = {
    // searchHotels({commit, state}) {
    //     commit('setHotels', state.hotels);
    // },
}


const mutations = {
    setHotels(state, hotels) {
        state.hotels = hotels;
    },
    setHotelDetails(state, hotels) {
        state.hotelDetails = hotels;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
