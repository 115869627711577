<template>
    <!-- Sidebar -->
    <div id="sidebar" class="sidebar">
        <div class="sidebar-logo">
            <router-link to="index">
                <img alt="" class="img-fluid" src="/images/logo-icon.png">
            </router-link>
        </div>

        <communicator
            v-if="communicator"
            @close="closeCommunicator"
            email="ptiw@wp.pl"
        ></communicator>

        <div class="sidebar-inner">
            <perfect-scrollbar :settings="settings" class="scroll-area" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul>
                        <li v-if="false">
                            <router-link to="/admin/index"><i class="fas fa-columns"></i> <span>Dashboard</span>
                            </router-link>
                        </li>
                        <hr>
                        <li>
                            <router-link to="/admin"><i class="fas fa-columns"></i> <span>Panel główny</span>
                            </router-link>
                        </li>
                        <li v-if="isSuperadmin">
                            <router-link to="/admin/users"><i class="fas fa-user-alt"></i>
                                <span>Użytkownicy</span></router-link>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i class="fas fa-calendar-check"></i> <span> Moje rezerwacje</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/reservation/list' }"
                                                 to="/admin/reservation/list">
                                        Lista rezerwacji
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i class="fas fa-plane"></i> <span> Polityka podróży</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/travelpolicy/list' }"
                                                 to="/admin/travelpolicy/list">
                                        Lista
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/travelpolicy/files' }"
                                                 to="/admin/travelpolicy/files">
                                        Pliki
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/travelpolicy/limit' }"
                                                 to="/admin/travelpolicy/limit">
                                        Limity
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i class="fas fa-book"></i> <span> Panel informacyjny</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/information/files' }"
                                                 to="/admin/information/files">
                                        Umowy i aneksy
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/information/prices' }"
                                                 to="/admin/information/prices">
                                        Cennik usług
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/information/users' }"
                                                 to="/admin/information/users">
                                        Dane pracowników
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/information/info' }"
                                                 to="/admin/information/info">
                                        Dane kontaktowe Trivium
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="submenu"  v-if="isSuperadmin">
                            <a href="javascript:void(0);"><i class="fas fa-book"></i> <span> Ustawienia</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link :class="{ 'active': currentPath === '/admin/settings/popular-hotels' }"
                                                 to="/admin/settings/popular-hotels">
                                        Popularne hotele
                                    </router-link>
                                </li>

                            </ul>
                        </li>
                        <hr>
                    </ul>
                    <ul v-if="false">
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/add-category' || currentPath === '/admin/edit-category'}"
                                         to="/admin/categories">
                                <i class="fas fa-layer-group"></i> <span>Categories</span></router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/add-subcategory' || currentPath === '/admin/edit-subcategory'}"
                                         to="/admin/subcategories">
                                <i class="fab fa-buffer"></i> <span>Sub Categories</span></router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/service-details'}"
                                         to="/admin/service-list"><i
                                class="fas fa-bullhorn"></i> <span> Services</span></router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/inprogress-report' || currentPath === '/admin/pending-report' || currentPath === '/admin/reject-report' || currentPath === '/admin/cancel-report' || currentPath === '/admin/complete-report'}"
                                         to="/admin/total-report">
                                <i class="far fa-calendar-check"></i> <span> Booking List</span></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/payment_list"><i class="fas fa-hashtag"></i> <span>Payments</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/add-ratingstype' || currentPath === '/admin/edit-ratingstype'}"
                                         to="/admin/ratingstype">
                                <i class="fas fa-star-half-alt"></i> <span>Rating Type</span></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/review-reports"><i class="fas fa-star"></i> <span>Ratings</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/add-subscription' || currentPath === '/admin/edit-subscription'}"
                                         to="/admin/subscriptions">
                                <i class="far fa-calendar-alt"></i> <span>Subscriptions</span></router-link>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/wallet-history'}"
                                         to="/admin/wallet"><i
                                class="fas fa-wallet"></i> <span> Wallet</span></router-link>
                        </li>
                        <li>
                            <router-link to="/admin/service-providers"><i class="fas fa-user-tie"></i> <span> Service Providers</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/admin/users"><i class="fas fa-user"></i> <span>Users</span></router-link>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i class="fas fa-clipboard"></i> <span> Invoices</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link :class="{'active': currentPath === '/admin/invoices-cancelled' || currentPath === '/admin/invoices-draft' || currentPath === '/admin/invoices-overdue' || currentPath === '/admin/invoices-recurring' || currentPath === '/admin/invoices-paid'}"
                                                 to="/admin/invoices">
                                        Invoices List
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/invoice-grid">Invoices Grid</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/add-invoice">Add Invoices</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/edit-invoice">Edit Invoices</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/view-invoice">Invoices Details</router-link>
                                </li>
                                <li>
                                    <router-link :class="{'active': currentPath === '/admin/tax-settings' || currentPath === '/admin/bank-settings'}"
                                                 to="/admin/invoices-settings">
                                        Invoices Settings
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <router-link :class="{'active': currentPath === '/admin/localization-details' || currentPath === '/admin/others-settings' || currentPath === '/admin/payment-settings' || currentPath === '/admin/seo-settings' || currentPath === '/admin/email-settings' || currentPath === '/admin/social-settings' || currentPath === '/admin/social-links'}"
                                         to="/admin/settings">
                                <i class="fas fa-cog"></i> <span>Settings</span></router-link>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i class="fas fa-cog"></i> <span> Frontend Settings</span>
                                <span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
                            </a>
                            <ul>
                                <li>
                                    <router-link to="/admin/front-settings"><span> Header Settings</span></router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/footer-settings"><span>Footer Settings</span></router-link>
                                </li>
                                <li>
                                    <router-link :class="{'active': currentPath === '/admin/home-page' || currentPath === '/admin/pages' || currentPath === '/admin/add-faq' || currentPath === '/admin/privacy-policy'}"
                                                 to="/admin/pages">
                                        <span>Pages </span></router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- /Sidebar -->
</template>
<script>
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import Communicator from "../communicator/Communicator";

export default {
    computed: {
        currentPath() {
            return this.$route.name;
        }

    },
    components: {
        Communicator,
        PerfectScrollbar,
    },
    mounted() {

    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            communicator: false,
            activeClass: 'active',
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {
            console.log(evt)
        },

        closeCommunicator() {
            this.communicator = false;
        },
    },
}
</script>
<style>
.scroll-area {
    position: relative;
    margin: auto;
    height: calc(100vh - 60px);
    background-color: transparent !important;
}

.sidebar-menu ul li a.active {
    background-color: #f58220;
    color: #fff;
}
</style>
