<template>
    <div style="min-height: 50vh">
        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="col-sm-12 mb-4" v-if="success">
            <p class="w-100 alert alert-success">
                {{ success }}
            </p>
        </div>

        <div class="bookings" v-if="bookings" v-for="item in bookings" :key="item.id">
            <div class="booking-list">

                <div class="booking-widget">
                    <router-link to="/user-bookings" class="booking-img">
                        <img :src="item.post_data.hotel.image" alt="Hotel Image">
                    </router-link>
                    <div class="booking-det-info">
                        <h3>
                            <router-link to="/user-bookings">{{ item.post_data.hotel.name }}</router-link>
                        </h3>
                        <ul class="booking-details">
                            <li><span>Rezerwacja: : </span> {{ item.reservation_number }}</li>
                            <li>
                                <span>Daty:</span> {{ item.check_in }} do {{ item.check_out }} <span
                                :class="{'bg-danger' : item.status7, 'bg-success' : !item.status7 }"
                                class="badge badge-pill badge-prof">{{ (item.status7) ? 'Anulowana' : 'Zarezerwowany' }}</span>
                            </li>
                            <li><span>Suma: </span> {{ item.price_brutto_pln }} zł</li>t
                            <li><span>Adres: </span> {{ item.post_data.hotel.address }}</li>
                        </ul>
                    </div>
                </div>
                <div class="booking-action">
                    <a href="#" v-if="!item.status7" data-toggle="modal" data-target="#cancelConfirmModal" @click="cancel(item.id)" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i>
                        Anuluj rezerwację</a>
                </div>
            </div>
        </div>

        <div v-if="bookings && bookings.length === 0" class="bookings">
            <p class="text-center my-5 py-5">Brak rezerwacji</p>
        </div>

        <div class="modal fade" id="cancelConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Czy na pewno chcesz anulować rezerwację?</strong></p>
                    </div>
                    <div class="modal-footer">	<a href="#" @click.prevent="cancelReservation" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import bookinglist from '../../../../../assets/json/website/bookinglist.json'

const images = require.context('../../../../../assets/img/services', false, /\.png$|\.jpg$/)
const images1 = require.context('../../../../../assets/img/provider', false, /\.png$|\.jpg$/)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {

    components: {
        Loading,
    },

    data() {
        return {
            bookinglist: bookinglist,

            bookings: null,

            error: null,
            loading: false,
            success: false,

            reservationToCancel: null,

            userId: 1,
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    },

    methods: {
        getReservations() {
            this.loading = true;
            axios.get(`/api/user/${this.globalUserId}/reservations`)
                .then(res => {
                    this.bookings = res.data.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },

        cancel(id) {
            this.reservationToCancel = id;
        },

        cancelReservation() {
            this.loading = true;
            axios.post('/api/reservation/cancel', {reservation: this.reservationToCancel})
                .then(res => {
                    console.log(res);
                    this.success = res.data;
                    $('#cancelConfirmModal').modal('hide');
                    this.reservationToCancel = null;
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                this.loading = false;
            })
        },

        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },

    mounted() {
        this.getReservations();
        $('.fav-btn .fav-icon').on('click', function () {
            $(this).toggleClass('favourite');
        });
    },
}
</script>
