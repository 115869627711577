<template>
<!-- Settings Menu -->
				<div class="settings-menu-links">
					<ul class="nav nav-tabs menu-tabs">
						<li class="nav-item" :class="{'active': currentPath === '/admin/settings'}">
							<router-link class="nav-link" to="/admin/settings">General Settings</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/localization-details'}">
							<router-link class="nav-link" to="/admin/localization-details">Localization</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/payment-settings'}">
							<router-link class="nav-link" to="/admin/payment-settings">Payment Settings</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/email-settings'}">
							<router-link class="nav-link" to="/admin/email-settings">Email Settings</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/social-settings'}">
							<router-link class="nav-link" to="/admin/social-settings">Social Media Login</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/social-links'}">
							<router-link class="nav-link" to="/admin/social-links">Social Links</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/seo-settings'}">
							<router-link class="nav-link" to="/admin/seo-settings">SEO Settings</router-link>
						</li>
						<li class="nav-item" :class="{'active': currentPath === '/admin/others-settings'}">
							<router-link class="nav-link" to="/admin/others-settings">Others</router-link>
						</li>
					</ul>
				</div>
				<!-- Settings Menu -->
</template>
<script>
    export default {
        computed: {
            currentPath() {
                return this.$route.name;
            }

        },
    mounted() {
      },
    data() {
        return {
          activeClass: 'active',
        };
                //  isactive : true
      },
    }
    </script>
    <style>
    .sidebar-menu ul li a.active {
        background-color: #f58220;
        color: #fff;
    }
    </style>
