<template>
    <div class="file-upload">
        <form action=""  @submit.prevent="submitFile">
            <div class="form-group">
                <label>Nazwa pliku <span class="star-red">*</span></label>
                <input type="text" class="form-control" name="title" v-model="fileTitle"  placeholder="Wpisz nazwę pliku">
            </div>

            <div class="form-group">
                <p class="settings-label">Plik do wgrania <span class="star-red">*</span></p>
                <div class="settings-btn">
                    <input type="file" name="image" id="file" @change="uploadFile" class="hide-input">
                    <label for="file" class="upload">
                        <i class="fas fa-upload"></i>
                    </label>
                </div>
                <h6 class="settings-size mt-1">Akceptowalne formaty: pdf, jpg, jpeg, png, doc, docx</h6>
            </div>

            <div class="form-group" v-if="additionalFields" v-for="(field, index) in additionalFields" :key="index">
                <div v-if="field.type === 'select'">
                    <label>{{ field.label }} </label>
                    <select :name="field.key" :id="`field-${field.key}`" v-model="additionalPayload[field.key]" class="form-control">
                        <option v-for="(option, index) in field.value" :value="index">{{ option }}</option>
                    </select>
                </div>

            </div>

            <div class="form-group mb-0">
                <div class="settings-btns">
                    <button class="btn btn-primary">Wgraj plik</button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>

import axios from "axios";

export default {

    props: {
        endpoint: {
            type: String,
            required: true,
        },
        action: {
            type: String,
            required: false,
            default: 'add',
        },
        fileToUpdate: {
            type: [Number, String],
            required: false,
        },
        additionalFields: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            loading: false,
            success: false,
            file: null,
            fileTitle: null,
            additionalPayload: {},
        }
    },

    methods: {
        uploadFile(event) {
            this.file = event.target.files[0]
        },
        submitFile() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('title', this.fileTitle)

            if(this.additionalFields && this.additionalPayload) {
                for (let key in this.additionalPayload) {
                    formData.append(key, this.additionalPayload[key])
                }
            }

            if(this.action === 'update' && this.fileToUpdate) {
                formData.append('id', this.fileToUpdate);
            }

            axios.post(`${this.endpoint}/${this.action}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response)
                this.$emit('success');
            })
            .catch(error => {
                console.log(error)
            })
        }
    },

    mounted() {
        console.log(this.additionalFields)
    },
}
</script>

<style scoped>

</style>
