<template>

    <div class="main-wrapper">

        <layout-header></layout-header>

        <div class="content">
            <div class="container">
                <div class="row">
                    <customersidebar></customersidebar>
                    <div class="col-xl-9 col-md-8">
                        <div class="row align-items-center mb-4">
                            <div class="col">
                                <h4 class="widget-title mb-0">Informacje</h4>
                                <hr>
                            </div>

                            <div class="col-lg-12 mt-3 mb-5">
                                <div v-if="information" v-html="information.text">

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <layout-footer></layout-footer>

    </div>

</template>
<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {

    components: {
        Loading,
    },

    data() {
        return {
            information: '',
            error: null,
            loading: false,
            success: false,
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    },

    methods: {

        getCompanyInformation() {
            this.loading = true;
            axios.get(`/api/information/info`)
                .then(res => {
                    this.information = res.data;
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },

    },

    mounted() {
        this.getCompanyInformation();
    },
}
</script>
