<template>
<div class="card">
                                        <div class="card-header">
                                            <div class="card-heads">
                                                <h4 class="card-title">Header Setting</h4>
                                                <div class="col-auto">
                                                    <div class="status-toggle">
                                                        <input id="otp" class="check" type="checkbox" checked="">
                                                        <label for="otp" class="checktoggle">checkbox</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <p class="settings-label">Header Logo</p>
                                                <div class="settings-btn">
                                                    <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                    <label for="file" class="upload">
                                                        <i class="fa fa-upload"></i>
                                                    </label>
                                                </div>
                                                <div class="upload-images d-block">
                                                    <img src="/images/services/service-13.png" alt="Image">
                                                    <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                    <h6>banner-2.jpg</h6>
                                                    <h5 class="m-0">43 KB</h5>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-5 col-12">
                                                        <div class="card-heads mb-3">
                                                            <h4 class="card-title f-14 m-0">Language </h4>
                                                            <div class="col-auto">
                                                                <div class="status-toggle">
                                                                    <input id="language" class="check" type="checkbox" checked>
                                                                    <label for="language" class="checktoggle">checkbox</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-heads mb-3">
                                                            <h4 class="card-title f-14">Currency </h4>
                                                            <div class="col-auto">
                                                                <div class="status-toggle">
                                                                    <input id="currency" class="check" type="checkbox" checked>
                                                                    <label for="currency" class="checktoggle">checkbox</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-heads mb-3">
                                                            <h4 class="card-title f-14">Stikcy header</h4>
                                                            <div class="col-auto">
                                                                <div class="status-toggle">
                                                                    <input id="header" class="check" type="checkbox" checked>
                                                                    <label for="header" class="checktoggle">checkbox</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2" >Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
</template>