<template>
    <div>
        <!-- Header -->
        <header class="header">
            <nav class="navbar navbar-expand-lg header-nav">
                <div class="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);">
					<span class="bar-icon">
						<span></span>
						<span></span>
						<span></span>
					</span>
                    </a>
                    <router-link class="navbar-brand logo" :to="{name: 'index'}">
                        <img alt="Logo" class="img-fluid" src="/images/logo.png">
                    </router-link>
                    <router-link class="navbar-brand logo-small" :to="{name: 'index'}">
                        <img alt="Logo" class="img-fluid" src="/images/logo-icon.png">
                    </router-link>
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link class="menu-logo" :to="{name: 'index'}">
                            <img alt="Logo" class="img-fluid" src="/images/logo.png">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i
                            class="fas fa-times"></i></a>
                    </div>
                    <ul class="main-nav">
                        <li style="display: none;">
                            <a data-bs-target="#user-register" data-bs-toggle="modal" href="javascript:void(0);"><i
                                class="fas fa-search" style="padding: 0 0.5rem; font-size: 17px;"></i></a>
                        </li>
                        <li :class="currentPath == 'user-bookings' ? 'active' : 'notactive'">
                            <router-link to="/user-bookings">Moje rezerwacje</router-link>
                        </li>
                        <li :class="currentPath == 'search' ? 'active' : 'notactive'">
                            <router-link to="/search">Nowa rezerwacja</router-link>
                        </li>
                        <li v-if="isSuperadmin || isClient"
                            :class="currentPath == 'user-settings' ? 'active' : 'notactive'">
                            <router-link to="/admin">Panel administracyjny </router-link>
                        </li>
                        <li v-else :class="currentPath == 'user-dashboard' ? 'active' : 'notactive'">
                            <router-link to="/user-dashboard">Panel administracyjny </router-link>
                        </li>
                        <li :class=" customerMenu ? 'active' : 'notactive'" class="has-submenu">
                            <a href="javascript:void(0);"><i class="far fa-user"
                                                             style="padding: 0 0.5rem; font-size: 17px;"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'user-dashboard' ? 'active' : 'notactive'">
                                    <router-link to="/user-dashboard">Pulpit</router-link>
                                </li>
                                <li :class="currentPath == 'user-bookings' ? 'active' : 'notactive'">
                                    <router-link to="/user-bookings">Twoje rezerwacje</router-link>
                                </li>
                                <li :class="currentPath == 'user-settings' ? 'active' : 'notactive'">
                                    <router-link to="/user-settings">Twoje dane</router-link>
                                </li>
                                <li v-if="isSuperadmin || isClient"
                                    :class="currentPath == 'user-settings' ? 'active' : 'notactive'">
                                    <router-link to="/admin">Panel Admin</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <ul class="nav header-navbar-rht">
                    <li class="nav-item" style="display: none">
                        <a class="nav-link header-login" data-bs-target="#login_modal" data-bs-toggle="modal"
                           href="javascript:void(0);">Login</a>
                    </li>
                </ul>
            </nav>
        </header>
        <!-- /Header -->
        <!-- Provider Register Modal -->
        <div id="provider-register" class="modal account-modal fade multi-step" data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Join as a Provider</h3>
                        </div>

                        <!-- Register Form -->
                        <form>
                            <div class="form-group form-focus">
                                <label class="focus-label">Name</label>
                                <input class="form-control" placeholder="johndoe@exapmle.com" type="text">
                            </div>
                            <div class="form-group form-focus">
                                <label class="focus-label">Mobile Number</label>
                                <input class="form-control" placeholder="986 452 1236" type="text">
                            </div>
                            <div class="form-group form-focus">
                                <label class="focus-label">Create Password</label>
                                <input class="form-control" placeholder="********" type="password">
                            </div>
                            <div class="text-end">
                                <a class="forgot-link" href="#">Already have an account?</a>
                            </div>
                            <router-link to="/">
                                <div class="d-grid">
                                    <button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Signup
                                    </button>
                                </div>
                            </router-link>
                            <div class="login-or">
                                <span class="or-line"></span>
                                <span class="span-or">or</span>
                            </div>
                            <div class="row form-row social-login">
                                <div class="col-6 d-grid">
                                    <a class="btn btn-facebook btn-block" href="javascript:void(0);"><i
                                        class="fab fa-facebook-f me-1"></i> Login</a>
                                </div>
                                <div class="col-6 d-grid">
                                    <a class="btn btn-google btn-block" href="javascript:void(0);"><i
                                        class="fab fa-google me-1"></i> Login</a>
                                </div>
                            </div>
                        </form>
                        <!-- /Register Form -->

                    </div>
                </div>
            </div>
        </div>
        <!-- /Provider Register Modal -->

        <!-- User Register Modal -->
        <div id="user-register" class="modal account-modal fade multi-step" data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Join as a User</h3>
                        </div>

                        <!-- Register Form -->
                        <form>
                            <div class="form-group form-focus">
                                <label class="focus-label">Name</label>
                                <input class="form-control" placeholder="johndoe@exapmle.com" type="text">
                            </div>
                            <div class="form-group form-focus">
                                <label class="focus-label">Mobile Number</label>
                                <input class="form-control" placeholder="986 452 1236" type="text">
                            </div>
                            <div class="form-group form-focus">
                                <label class="focus-label">Create Password</label>
                                <input class="form-control" placeholder="********" type="password">
                            </div>
                            <div class="text-end">
                                <a class="forgot-link" href="#">Already have an account?</a>
                            </div>
                            <router-link to="/">
                                <div class="d-grid">
                                    <button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Signup
                                    </button>
                                </div>
                            </router-link>
                            <div class="login-or">
                                <span class="or-line"></span>
                                <span class="span-or">or</span>
                            </div>
                            <div class="row form-row social-login">
                                <div class="col-6 d-grid">
                                    <a class="btn btn-facebook btn-block" href="javascript:void(0);"><i
                                        class="fab fa-facebook-f me-1"></i> Login</a>
                                </div>
                                <div class="col-6 d-grid">
                                    <a class="btn btn-google btn-block" href="javascript:void(0);"><i
                                        class="fab fa-google me-1"></i> Login</a>
                                </div>
                            </div>
                        </form>
                        <!-- /Register Form -->

                    </div>
                </div>
            </div>
        </div>
        <!-- /User Register Modal -->

        <!-- Login Modal -->
        <div id="login_modal" class="modal account-modal fade">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button"><span
                            aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Login <span>Truelysell</span></h3>
                        </div>
                        <form>
                            <div class="form-group form-focus">
                                <label class="focus-label">Email</label>
                                <input class="form-control" placeholder="truelysell@example.com" type="email">
                            </div>
                            <div class="form-group form-focus">
                                <label class="focus-label">Password</label>
                                <input class="form-control" placeholder="********" type="password">
                            </div>
                            <div class="text-end">
                            </div>
                            <router-link to="/">
                                <div class="d-grid">
                                    <button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Login
                                    </button>
                                </div>
                            </router-link>
                            <div class="login-or"><span class="or-line"></span>
                                <span class="span-or">or</span>
                            </div>
                            <div class="row form-row social-login">
                                <div class="col-6 d-grid"><a class="btn btn-facebook btn-block"
                                                             href="javascript:void(0);"><i
                                    class="fab fa-facebook-f me-1"></i> Login</a>
                                </div>
                                <div class="col-6 d-grid"><a class="btn btn-google btn-block"
                                                             href="javascript:void(0);"><i
                                    class="fab fa-google me-1"></i> Login</a>
                                </div>
                            </div>
                            <div class="text-center dont-have">Don’t have an account? <a href="javascript:void(0);">Register</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Login Modal -->
    </div>
</template>
<script>
import AOS from 'aos';

export default {
    mounted() {
        if ($('.main-wrapper .aos').length > 0) {
            AOS.init({
                duration: 1200,
                once: true,
            });
        }
        if ($(window).width() <= 991) {
            var Sidemenu = function () {
                this.$menuItem = $('.main-nav a');
            };

            function init() {
                var $this = Sidemenu;
                $('.main-nav a').on('click', function (e) {
                    if ($(this).parent().hasClass('has-submenu')) {
                        e.preventDefault();
                    }
                    if (!$(this).hasClass('submenu')) {
                        $('ul', $(this).parents('ul:first')).slideUp(350);
                        $('a', $(this).parents('ul:first')).removeClass('submenu');
                        $(this).next('ul').slideDown(350);
                        $(this).addClass('submenu');
                    } else if ($(this).hasClass('submenu')) {
                        $(this).removeClass('submenu');
                        $(this).next('ul').slideUp(350);
                    }
                });
            }

            // Sidebar Initiate
            init();
        }
        // Mobile menu sidebar overlay
        $('.header-fixed').append('<div class="sidebar-overlay"></div>');
        $(document).on('click', '#mobile_btn', function () {
            $('main-wrapper').toggleClass('slide-nav');
            $('.sidebar-overlay').toggleClass('opened');
            $('html').addClass('menu-opened');
            return false;
        });
        $(document).on('click', '.sidebar-overlay', function () {
            $('html').removeClass('menu-opened');
            $(this).removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });

        $(document).on('click', '#menu_close', function () {
            $('html').removeClass('menu-opened');
            $('.sidebar-overlay').removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });
        $('.fav-btn .fav-icon').on('click', function () {
            $(this).toggleClass('favourite');
        });
    },
    computed: {
        currentPath() {
            return this.$route.name
        },
        homeMenu() {
            return this.$route.name == '/' || this.$route.name == 'index' || this.$route.name == 'index-two' || this.$route.name == 'index-three' || this.$route.name == 'index-four' || this.$route.name == 'index-five';
        },
        providerMenu() {
            return this.$route.name == 'provider-dashboard' || this.$route.name == 'my-services-inactive' || this.$route.name == 'my-services' || this.$route.name == 'provider-bookings' || this.$route.name == 'provider-settings' || this.$route.name == 'provider-wallet' || this.$route.name == 'provider-subscription' || this.$route.name == 'provider-availability' || this.$route.name == 'provider-reviews' || this.$route.name == 'provider-payment';
        },
        pageMenu() {
            return this.$route.name == 'search' || this.$route.name == 'service-details' || this.$route.name == 'add-service' || this.$route.name == 'edit-service' || this.$route.name == 'chat' || this.$route.name == 'notifications' || this.$route.name == 'about-us' || this.$route.name == 'contact-us' || this.$route.name == 'faq' || this.$route.name == 'term-condition' || this.$route.name == 'privacy-policy1';
        },
        customerMenu() {
            return this.$route.name == 'user-dashboard' || this.$route.name == 'favourites' || this.$route.name == 'user-bookings' || this.$route.name == 'user-settings' || this.$route.name == 'user-wallet' || this.$route.name == 'user-reviews' || this.$route.name == 'user-payment';
        },
    },
}
</script>
