<template>
    <div>
        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="service-view" v-if="details">
            <div class="service-header">
                <div class="d-flex justify-content-left align-items-start">
                    <h1> {{ details.name }}</h1>
                    <div class="rating mapgridrating1 w-auto" v-if="details.details.starRating && details.details.starRating > 0">
                        <i class="fas fa-star filled" v-if="details.details.starRating > 0"></i>
                        <i class="fas fa-star filled" v-if="details.details.starRating > 1"></i>
                        <i class="fas fa-star filled" v-if="details.details.starRating > 2"></i>
                        <i class="fas fa-star filled" v-if="details.details.starRating > 3"></i>
                        <i class="fas fa-star filled" v-if="details.details.starRating > 4"></i>

                    </div>

                </div>
                <address class="service-location"><i class="fas fa-location-arrow"></i> {{ details.details.address.city }},
                    {{ details.details.address.country }}
                </address>
            </div>

            <div class="service-images service-carousel-view slider" v-if="false">

                <div
                    v-if="details.details.image && details.details.image[0]"
                    v-for="image in details.details.image"
                    class="item"
                >
                    <img :src="image.url" alt="" class="img-fluid">
                </div>


            </div>

            <div id="hotel-details__gallery" class="carousel slide" data-ride="carousel" align="center">
                <!-- slides -->
                <div class="carousel-inner">
                    <div class="carousel-item"
                         v-if="details.details.image && details.details.image[0]"
                         v-for="(image, index) in details.details.image"
                         :class="{ 'active': index === 0 }"
                    >
                        <div class="carousel-item-photo" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                    </div>

                </div>

                <!-- Left right -->
                <a class="carousel-control-prev" href="#hotel-details__gallery" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#hotel-details__gallery" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>

                <!-- Thumbnails -->
                <ol class="carousel-indicators list-inline">
                    <li class="list-inline-item"
                        :class="{ 'active': index === 0 }"
                        v-if="details.details.image && details.details.image[0]"
                        v-for="(image, index) in details.details.image">
                        <a :id="`carousel-selector-${ index }`" class="selected" :data-slide-to="index"
                           data-target="#hotel-details__gallery">
                            <div class="carousel-item-thumb" :style="{'background-image': 'url(' + image.url + ')'}"></div>
                        </a>
                    </li>
                </ol>
            </div>


            <div class="service-details mt-5">
                <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                           aria-controls="pills-home" aria-selected="true">Opis</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-details-tab" data-toggle="pill" href="#pills-details" role="tab"
                           aria-controls="pills-details" aria-selected="false">Szczegóły</a>
                    </li>

                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="card service-description">
                            <div class="card-body">
                                <h5 class="card-title">Opis</h5>
                                <p class="mb-0" v-if="details.details.descriptions.description && details.details.descriptions.description[0]" v-html="details.details.descriptions.description[0]._"></p>
                                <p class="mb-0" v-else v-html="details.details.descriptions.description._"></p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                        <div class="card service-description">
                            <div class="card-body">
                                <h5 class="card-title">Szczegóły</h5>
                                <ul class="pl-5" style="list-style: circle">
                                    <li v-for="feature in details.details.feature">{{ feature.name }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="hotel-rooms" class="bookings" v-if="details.channel.room && details.channel.room.length > 0"
                 v-for="(item, index) in details.channel.room" :key="index">
                <div class="booking-list">
                    <div class="d-flex w-100">
                        <div class="booking-widget">
                            <div class="booking-det-info">
                                <h3>
                                    <router-link to="/service-details">{{ item.roomType.name }}</router-link>
                                </h3>
                                <p v-if="$filters.isCancellationAvailable(item.cancellationPolicy.cxlDate)" class="text-success">
                                    Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(item.cancellationPolicy.cxlDate) }}</strong>
                                </p>
                                <p v-else class="text-danger">
                                    Brak możliwości bezpłatnego odwołania
                                </p>
                                <ul class="booking-details">
                                    <li>
                                        <span>Wyżywienie</span> {{ item.boardType.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="booking-action flex-column align-items-end">
                            <div v-if="rate" class="text-end mb-3"> <span style="font-weight: bold; font-size: 28px;">{{ Math.ceil(item.price * 1.10 * this.rate) }} PLN</span><span><br>Cena za pobyt</span></div>

                            <router-link
                                :to="{
                                    name: 'book',
                                    params: {
                                      hotelId: payload.hotelId,
                                      roomId: item.id,
                                      boardTypeId: item.boardType.id,
                                      roomTypeId: item.roomType.id,
                                      checkin: payload.checkin,
                                      checkout: payload.checkout,
                                      adults: payload.adults,
                                      children: payload.children,
                                      ageOne: payload.ageOne,
                                      ageTwo: payload.ageTwo,
                                      ageThree: payload.ageThree,
                                    }
                                }"
                                class="btn btn-sm btn-primary"
                            >
                                Rezerwuj
                            </router-link>
                        </div>
                    </div>
                    <!-- Content of booking-list above show-details -->

                    <div class="d-flex mt-2">
                        <hr>
                        <!-- Show booking details section -->
                        <div class="show-details">
                            <a
                                @click.prevent="toggleDetails(index, {
                                        hotelId: payload.hotelId,
                                        roomId: item.id,
                                        boardTypeId: item.boardType.id,
                                        roomTypeId: item.roomType.id,
                                        checkin: payload.checkin,
                                        checkout: payload.checkout,
                                        adults: payload.adults,
                                        children: payload.children,
                                        ageOne: payload.ageOne,
                                        ageTwo: payload.ageTwo,
                                        ageThree: payload.ageThree,
                                    })"
                                class="mb-2">
                                Pokaż szczegóły oferty
                            </a>
                            <div v-if="loadingDetails[index]" class="loading-circle"></div>
                            <transition name="slide mt-2">
                                <div v-show="detailsVisible[index]" class="booking-remarks">
                                    <p v-if="bookingRemarks[index]" v-html="bookingRemarks[index]"></p>
                                    <p v-else class="text-muted">Brak szczegółów rezerwacji</p>
                                </div>
                            </transition>
                        </div>
                    </div>

                </div>
            </div>


            <div id="hotel-rooms" class="bookings" v-else>
                <div class="booking-list">
                    <!-- Booking Widget Section -->

                    <div class="d-flex w-100">
                        <div class="booking-widget">
                            <div class="booking-det-info">
                                <h3>
                                    <router-link to="/service-details">{{ details.channel.room.roomType.name }}</router-link>
                                </h3>
                                <p
                                    v-if="$filters.isCancellationAvailable(details.channel.room.cancellationPolicy.cxlDate)"
                                    class="text-success">
                                    Bezpłatne anulowanie: <strong>do {{ $filters.formatDate(details.channel.room.cancellationPolicy.cxlDate) }}</strong>
                                </p>
                                <p v-else class="text-danger">
                                    Brak możliwości bezpłatnego odwołania
                                </p>
                                <ul class="booking-details">
                                    <li>
                                        <span>Wyżywienie</span> {{ details.channel.room.boardType.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Booking Action Section -->
                        <div class="booking-action flex-column align-items-end">
                            <div v-if="rate" class="text-end mb-3 ">
                                <span style="font-weight: bold; font-size: 28px;">{{ Math.ceil(details.channel.room.price * 1.10 * this.rate) }} PLN</span> <span><br>Cena za pobyt</span>
                            </div>

                            <router-link
                                :to="{
                                    name: 'book',
                                    params: {
                                        hotelId: payload.hotelId,
                                        roomId: details.channel.room.id,
                                        boardTypeId: details.channel.room.boardType.id,
                                        roomTypeId: details.channel.room.roomType.id,
                                        checkin: payload.checkin,
                                        checkout: payload.checkout,
                                        adults: payload.adults,
                                        children: payload.children,
                                        ageOne: payload.ageOne,
                                        ageTwo: payload.ageTwo,
                                        ageThree: payload.ageThree,
                                    }
                                }"
                                class="btn btn-sm btn-primary">
                                Rezerwuj
                            </router-link>
                            <a v-if="false" href="#" class="btn btn-sm btn-primary">Rezerwuj</a>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <hr>
                        <div class="show-details">
                            <a
                                @click="toggleDetails(0, {
                                    hotelId: payload.hotelId,
                                    roomId: details.channel.room.id,
                                    boardTypeId: details.channel.room.boardType.id,
                                    roomTypeId: details.channel.room.roomType.id,
                                    checkin: payload.checkin,
                                    checkout: payload.checkout,
                                    adults: payload.adults,
                                    children: payload.children,
                                    ageOne: payload.ageOne,
                                    ageTwo: payload.ageTwo,
                                    ageThree: payload.ageThree,
                                })"
                                class="mb-2">
                                Pokaż szczegóły oferty
                            </a>
                            <div v-if="loadingDetails[0]" class="loading-circle"></div>
                            <transition name="slide mt-2">
                                <div v-show="detailsVisible[0]" class="booking-remarks">
                                    <p v-if="bookingRemarks[0]" v-html="bookingRemarks[0]"></p>
                                    <p v-else class="text-muted">Brak szczegółów rezerwacji</p>
                                </div>
                            </transition>
                        </div>

                    </div>
                    <!-- Show Booking Details Section -->

                </div>
            </div>

            <div class="modal fade" id="priceChangeModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p><strong>Cena za pokój w międzyczasie uległa zmianie. Sprawdź nową cenę.</strong></p>
                        </div>
                        <div class="modal-footer">
                            <a href="#" @click.prevent="reloadPrices" class="btn btn-success si_accept_confirm mr-3">Rozumiem!</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    components: {
        Loading,
    },

    props: {
        details: Object,
        rate: Number,
    },

    data() {
        return {
            hotelId: '',
            checkin: null,
            checkout: null,
            adults: null,
            children: null,

            loading: false,

            loadingDetails: {}, // Track loading state per room
            detailsVisible: {}, // Track visibility per room
            bookingRemarks: {}, // Store remarks per room

            ageOne: 0,
            ageTwo: 0,
            ageThree: 0,

            availability: null,

            payload: {
                hotelId: null,
                roomId: null,
                boardTypeId: null,
                roomTypeId: null,
                checkin: null,
                checkout: null,
                adults: null,
                children: null,
                ageOne: 0,
                ageTwo: 0,
                ageThree: 0,
            },

        }
    },

    methods: {
        toggleDetails(index, params) {
            if (!this.detailsVisible[index]) {
                this.fetchAvailability(index, params);
            } else {
                this.detailsVisible[index] = false;
            }
        },

        reloadPrices() {
            this.loading = true;
            this.$emit('loading', true);

            axios.post('/api/search/hotel-by-id', this.payload)
                .then(res => {
                    console.log('hotelById', res);
                    $('#priceChangeModal').modal('hide');
                    this.$emit('updateDetails', res.data);
                })
                .catch(error => {
                    this.error = 'Wybrany hotel jest niedostępny w tych dniach';
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },

        fetchAvailability(index, params) {
            // this.loadingDetails[index] = true;
            this.loading = true;
            axios
                .post('/api/book/check-availability', params)
                .then((res) => {
                    this.bookingRemarks[index] =
                        res.data.hotel.channel.room.bookingTerms.bookingRemarks || "Brak szczegółów rezerwacji";
                    this.detailsVisible[index] = true;
                })
                .catch((error) => {
                    $('#priceChangeModal').modal('show');
                    console.error(error);
                })
                .finally(() => {
                    // this.loadingDetails[index] = false;
                    this.loading = false;
                });
        },
    },

    created() {
        if (this.$route.params.checkin && this.$route.params.checkout) {
            this.payload.hotelId = this.$route.params.id;
            this.payload.checkin = this.$route.params.checkin;
            this.payload.checkout = this.$route.params.checkout;
            this.payload.adults = this.$route.params.adults;
            this.payload.children = this.$route.params.children;
            this.payload.ageOne = this.$route.params.ageOne;
            this.payload.ageTwo = this.$route.params.ageTwo;
            this.payload.ageThree = this.$route.params.ageThree;
        }

        // Check if the reload flag exists in localStorage
        const shouldReloadPrices = localStorage.getItem('shouldReloadPrices');

        if (shouldReloadPrices) {
            // Call the reloadPrices function
            this.reloadPrices();

            // Clear the flag from localStorage
            localStorage.removeItem('shouldReloadPrices');
        }
    },
}
</script>
<style scoped>
.container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
}

#hotel-details__gallery .carousel-indicators {
    position: static;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    width: 100%;
    right: 0;
    bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.carousel-control-prev,
.carousel-control-next {
    height: 520px;
}


#hotel-details__gallery .carousel-indicators > li {
    width: 59px;
    height: 27px;
}

#hotel-details__gallery .carousel-indicators > li > a {
    width: 65px;
    height: 60px;
    display: block;
}

#hotel-details__gallery .carousel-indicators li img {
    display: block;
    opacity: 0.5;
}

#hotel-details__gallery .carousel-indicators li.active img {
    opacity: 1;
}

#hotel-details__gallery .carousel-indicators li:hover img {
    opacity: 0.75;
}

.carousel-item img {

    width: 100%;
}

.carousel-item-photo {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
}

.carousel-item-thumb {
    width: 66px;
    height: 43px;
    background-size: cover;
    background-position: center;
}
</style>
