'use strict';

var Front = {
    init() {
        // this.loader();
        this.doubleColorHeader();
        this.hotelDetailsCarousel();
        this.smoothScroll();
        this.showPassword();
    },

    loader() {
        $(window).on ('load', function (){
            $('.preloader-inner').delay(100).fadeOut('slow');
            $('.page-loading').delay(500).fadeOut('slow');
            $('body').delay(500).css({'overflow':'visible'});
        });
    },

    showPassword() {
        const togglePassword = document.querySelector('#toggle-password');
        const passwordField = document.querySelector('#password');
        const togglePasswordIcon = document.querySelector('#toggle-password-icon');


        if(togglePassword) {
            togglePassword.addEventListener('click', function() {
                // Toggle the type attribute
                const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
                passwordField.setAttribute('type', type);

                // Toggle the eye slash icon
                if (type === 'password') {
                    togglePasswordIcon.classList.remove('fa-eye-slash');
                    togglePasswordIcon.classList.add('fa-eye');
                } else {
                    togglePasswordIcon.classList.remove('fa-eye');
                    togglePasswordIcon.classList.add('fa-eye-slash');
                }
            });
        }

    },

    doubleColorHeader() {
        console.log('doubleColorHeader');
        $(".double-color").html(function(){
            var text= $(this).text().trim().split(" ");
            var first = text.shift();
            return (text.length > 0 ? "<span class='first-word'>"+ first + "</span> " : first) + text.join(" ");
        });
    },

    smoothScroll() {
        $('a.scroll-down[href^="#"]').click(function(){

            var the_id = $(this).attr("href");

            $('html, body').animate({
                scrollTop:$(the_id).offset().top
            }, 'slow');

            return false;});
    },

    hotelDetailsCarousel() {
        if ($('.service-carousel-view').length > 0) {
            $('.service-carousel-view').slick({
                dots: true,
                autoplay: false,
                infinite: true,
                prevArrow: false,
                nextArrow: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }
    },

};

window.Front = Front;

$(document).ready(function(){
    console.log('test');
    Front.init();
});

