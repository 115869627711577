
const state = {
    currentUser: null,
}


const getters = {
    currentUser: (state, commit) => {
        return state.currentUser;
    }
}

const actions = {

}

const mutations = {
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
