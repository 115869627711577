<template>

    <div>

	<div class="main-wrapper">

	<layout-header5></layout-header5>

	<!-- Hero Section -->
    <section class="hero-section hero-five">
        <div class="hero-screen">
            <div class="layer">
                <div class="home-banner-five"></div>
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-8">
                            <div class="hero-section-search d-flex align-items-center">
                                <div class="section-search aos" data-aos="fade-up">
                                    <h3>World's Largest <br><span>Marketplace <span>...</span></span></h3>
                                    <p>Search From 100 Awesome Verified Ads!</p>
                                    <div class="search-box">
                                        <form>
                                            <div class="search-input line">
                                                <i class="fa fa-th-large bficon"></i>
                                                <div class="form-group mb-0">
                                                    <input type="text" class="form-control" placeholder="What are you looking for?">
                                                </div>
                                            </div>
                                            <div class="search-input">
                                                <i class="fas fa-map-marker-alt bficon"></i>
                                                <div class="form-group mb-0">
                                                    <input type="text" class="form-control" placeholder="Your Location">
                                                </div>
                                            </div>
                                            <router-link to="/search">
                                            <div class="search-btn">
                                                <button class="btn search_service" ><i class="fa fa-search"></i>Search Now</button>
                                            </div>
                                            </router-link>
                                        </form>
                                    </div>
                                    <div class="search-cat">
                                        <span>Popular Searches</span>
                                        <router-link to="/search">Electrical  Works</router-link>
                                        <router-link to="/search">Cleaning</router-link>
                                        <router-link to="/search">AC Repair</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Hero Section -->

    <!-- Featured Category  -->
    <home5category></home5category>
    <!-- Featured Category -->

    <!-- Popular Servides -->
    <home5popular></home5popular>
    <!-- /Popular Servides -->

    <!-- How It Works -->
    <home5work></home5work>
    <!-- /How It Works -->

    <!-- /our app -->
    <section class="app-set app-five">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-12 ">
                    <div class="app-box-five d-flex align-items-center">
                        <div class="app-box">
                            <div class="col-md-12">
                                <div class="heading aos" data-aos="fade-up">
                                    <h2>Download Our App</h2>
                                    <span>Aliquam lorem ante, dapibus in, viverra quis</span>
                                </div>
                            </div>
                            <div class="downlaod-set aos" data-aos="fade-up">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);"><img src="/images/app-gp-02.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);"><img src="/images/app-ap-02.png" alt="img"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /our app -->

	<layout-footer5></layout-footer5>
	</div>
    </div>
</template>
<script>
	export default {
		mounted() {
		},
	}
</script>