<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>

            <loading :active="loading"
                     color="#db7015"
                     :is-full-page="true"></loading>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-xl-12">

                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Ustawienia użytkownika</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->

                            <div class="card">
                                <div class="card-body profile-menu">
                                    <ul class="nav nav-tabs nav-tabs-solid" role="tablist">
                                        <li class="nav-item home_tab">
                                            <a class="nav-link active" data-toggle="tab" href="#profile_settings"
                                               role="tab" aria-selected="false">
                                                Ustawienia
                                            </a>
                                        </li>
                                        <li class="nav-item home_add">
                                            <a class="nav-link" data-toggle="tab" href="#change_password" role="tab"
                                               aria-selected="false">
                                                Zmiana hasła
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <p class="w-100 alert alert-success" v-if="success">
                                            Profil użytkownika został pomyslnie edytowany
                                        </p>
                                        <p class="w-100 alert alert-danger" v-if="error">
                                            <strong>Błąd!</strong> Podczas edycji użytkownika wytąpił błąd. Skontaktuj się z administratorem
                                        </p>
                                        <form @submit.prevent="updateUser">
                                            <!-- Profile Tab -->
                                            <div class="row tab-pane fade show active" id="profile_settings"
                                                 role="tabpanel">
                                                <div class="col-sm-2 form-group">
                                                    <label>Tytuł</label>
                                                    <select class="form-control" name="title" id="title" v-model="user.title">
                                                        <option value="MR">Pan</option>
                                                        <option value="MRS">Pani</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-5 form-group">
                                                    <label>Użytkownik</label>
                                                    <input type="text" class="form-control" name="name" v-model="user.name">
                                                </div>
                                                <div class="col-sm-5 form-group">
                                                    <label>E-mail</label>
                                                    <input type="email" class="form-control" name="email"
                                                           v-model="user.email" disabled>
                                                </div>
                                                <div class="col-sm-6 form-group">
                                                    <label>Imię</label>
                                                    <input type="text" class="form-control" name="firstname"
                                                           v-model="user.firstname">
                                                </div>
                                                <div class="col-sm-6 form-group">
                                                    <label>Nazwisko</label>
                                                    <input type="text" class="form-control" name="lastname"
                                                           v-model="user.lastname">
                                                </div>

                                                <div class="col-sm-6 form-group">
                                                    <label>Adres</label>
                                                    <input type="text" class="form-control" name="address"
                                                           v-model="user.address">
                                                </div>
                                                <div class="col-sm-3 form-group">
                                                    <label>Kod pocztowy</label>
                                                    <input type="text" class="form-control" name="zipcode"
                                                           v-model="user.zipcode">
                                                </div>
                                                <div class="col-sm-3 form-group">
                                                    <label>Miasto</label>
                                                    <input type="text" class="form-control" name="city"
                                                           v-model="user.city">
                                                </div>

                                                <div class="col-sm-12 mt-4 save-form">
                                                    <button class="btn btn-primary save-btn" type="submit">Zapisz</button>
                                                </div>

                                            </div>
                                            <!-- /Profile Tab -->
                                        </form>
                                        <!-- Password Tab -->
                                        <div class="tab-pane fade" id="change_password" role="tabpanel">
                                            <form>
                                                <div class="form-group">
                                                    <label>Current Password</label>
                                                    <input type="password" class="form-control">
                                                </div>
                                                <div class="form-group">
                                                    <label>New Password</label>
                                                    <input type="password" class="form-control">
                                                </div>
                                                <div class="form-group">
                                                    <label>Repeat Password</label>
                                                    <input type="password" class="form-control">
                                                </div>
                                                <div class="mt-4 save-form">
                                                    <button class="btn save-btn btn-primary" type="submit">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                        <!-- /Password Tab -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {

    components: {
        Loading,
    },

    data() {
        return {
            error: null,
            loading: false,
            success: false,

            userId: 1,

            user: {
                title: 'MR',
                name: 'admin',
                email: 'admin@admin.com',
                firstname: 'Jan',
                lastname: 'Kowalski',
                address: 'Krakowska 26/1',
                zipcode: '31-422',
                city: 'Kraków',
            },
        }
    },

    computed: {

    },

    methods: {
        getUser() {
            this.loading = true;
            axios.get(`/api/user/get/${this.userId}`)
                .then(res => {
                    let user = res.data.data;
                    this.user.name = user.name;
                    this.user.email = user.email;
                    console.log(res.data.data);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },

        updateUser() {
            this.loading = true;
            axios.post(`/api/user/update/${this.userId}`, this.user)
                .then(res => {
                    let user = res.data.data;
                    this.user.name = user.name;
                    this.user.email = user.email;

                    this.success = true;
                    console.log(res.data.data);
                })
                .catch(error => {
                    console.log(error);
                    this.error = true;
                }).finally(() => {
                this.loading = false;
            });
        },
    },

    mounted() {
        this.getUser();
    },
}
</script>
