<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <user-header></user-header>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <users-all  @reload-users="getUsers" v-if="currentPath === '/admin/users'" :users="users"></users-all>
                                        <users-pending v-if="currentPath === '/admin/users-pending'" :users="users"></users-pending>
                                        <users-active v-if="currentPath === '/admin/users-active'" :users="users"></users-active>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </diV>
    </div>

</template>
<script>
import user from '../../../../assets/json/admin/user.json'
import util from '../../../../assets/utils/util'
import axios from "axios";

const images = require.context('../../../../assets/admin_img/customer', false, /\.png$|\.jpg$/)
export default {
    data() {
        return {
            user: user,
            users: null,

            loading: false,
        }
    },


    computed: {
        currentPath() {
            return this.$route.name;
        }
    },

    methods: {
        getUsers() {
            this.loading = true;
            axios.get('/api/users/get')
                .then(res => {
                    this.users = res.data;
                    console.log(this.users)
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },

        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
    },

    mounted() {
        this.getUsers();
        if(this.users) {
            util.datatable('#user')
        }
        console.log(this.role);
    },

}
</script>
