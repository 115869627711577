<template>

<section class="feature-category dots-hover category-five">
        <div class="container">
            <div class="section-heading section-five text-center aos" data-aos="fade-up">
                <span>What do you need to find?</span>
                <h2>Featured Categories</h2>
            </div>

            <div class="service-carousel">
                <div class="categories-slider slider aos" data-aos="fade-up" >
                    <div class="categories-box">
                        <router-link to="/search">
                            <div class="cate-widget">
                                <img src="/images/category/category-20.jpg" alt="">
                                <div class="cate-title">
                                    <h3><span> Interior</span></h3>
                                </div>
                                <div class="cate-count">
                                    <i class="fas fa-users"></i>
                                    <span class="ms-1">31</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="categories-box">
                        <router-link to="/search">
                            <div class="cate-widget">
                                <img src="/images/category/category-21.jpg" alt="">
                                <div class="cate-title">
                                    <h3><span> Electrical</span></h3>
                                </div>
                                <div class="cate-count">
                                    <i class="fas fa-users"></i>
                                    <span class="ms-1">21</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="categories-box">
                        <router-link to="/search">
                            <div class="cate-widget">
                                <img src="/images/category/category-22.jpg" alt="">
                                <div class="cate-title">
                                    <h3><span> Computer</span></h3>
                                </div>
                                <div class="cate-count">
                                    <i class="fas fa-users"></i>
                                    <span class="ms-1">34</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="categories-box">
                        <router-link to="/search">
                            <div class="cate-widget">
                                <img src="/images/category/category-23.jpg" alt="">
                                <div class="cate-title">
                                    <h3><span> Car wash</span></h3>
                                </div>
                                <div class="cate-count">
                                    <i class="fas fa-users"></i>
                                    <span class="ms-1">23</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="categories-box">
                        <router-link to="/search">
                            <div class="cate-widget">
                                <img src="/images/category/category-24.jpg" alt="">
                                <div class="cate-title">
                                    <h3 class="ms-1"><span> Electronics</span></h3>
                                </div>
                                <div class="cate-count">
                                    <i class="fas fa-users"></i>
                                    <span>40</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
	export default {
	mounted() {
        if($('.categories-slider').length > 0) {
		$('.categories-slider').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

	},
	}
	</script>