<template>
<section class="how-work how-work-four">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading section-four-head text-center aos" data-aos="fade-up">
                        <h2>How It Works</h2>
                        <span>Aliquam lorem ante, dapibus in, viverra quis</span>
                    </div>
                    <div class="howworksec work-do-true aos" data-aos="fade-up">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork what-do">
                                    <div class="icon-back d-flex align-items-center justify-content-center">
                                        <div class="iconround d-flex align-items-center justify-content-center">
                                            <img src="/images/icon-5.png" alt="">
                                        </div>
                                    </div>
                                    <h3>Choose What To Do</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork what-do">
                                    <div class="find-icon d-flex align-items-center justify-content-center">
                                        <div class="iconround d-flex align-items-center justify-content-center">
                                            <img src="/images/icon-4.png" alt="">
                                        </div>
                                    </div>
                                    <h3>Find What You Want</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="howwork what-do">
                                    <div class="place-icon d-flex align-items-center justify-content-center">
                                        <div class="iconround d-flex align-items-center justify-content-center">
                                            <img src="/images/icon-6.png" alt="">
                                        </div>
                                    </div>
                                    <h3>Amazing Places</h3>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut metus varius laoreet.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>