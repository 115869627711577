<template>
    <div>
        <div class="main-wrapper">
            <layout-headeradmin></layout-headeradmin>
            <layout-sidebaradmin></layout-sidebaradmin>
            <div class="page-wrapper">
                <div class="content container-fluid">

                    <loading :active="loading"
                             color="#db7015"
                             :is-full-page="true"></loading>

                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Edytuj rezerwację | <small v-if="reservation">{{ reservation.reservation_number }}</small></h3>
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <router-link to="/admin/reservation/list" class="mt-3 mb-3"><i class="fas fa-arrow-circle-left"></i> Powrót</router-link>
                        <form class="row" v-if="reservation">

                            <div class="col-md-6 mt-3">

                                <div class="card col-md-12">
                                    <div class="card-body">

                                        <div class="service-fields mb-3">
                                            <h3 class="heading-2">Dane osobowe</h3>
                                            <div class="row">
                                                <!--

                                                rooms: [{persons: [{title: "MS", firstname: "Weronika", lastname: "Witos"},…]}]
                                                smoking: "bez_preferencji"
                                                special_instructions: null
                                                -->
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label>Tytuł <span class="text-danger">*</span></label>
                                                        <select class="form-control" name="title" v-model="reservation.post_data.reservation.title" id="title">
                                                            <option value="MR">Pan</option>
                                                            <option value="MS">Pani</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="form-group">
                                                        <label>Imię <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="firstname"
                                                               v-model="reservation.post_data.reservation.firstname">
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="form-group">
                                                        <label>Nazwisko <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="lastname"
                                                               v-model="reservation.post_data.reservation.lastname">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Adres <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="street" v-model="reservation.post_data.reservation.street">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label>Miasto <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="city" v-model="reservation.post_data.reservation.city">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label>Kod pocztowy <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="postal_code"
                                                               v-model="reservation.post_data.reservation.postal_code">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label>Państwo <span class="text-danger">*</span></label>
                                                        <select class="form-control" name="country" v-model="reservation.post_data.reservation.country"
                                                                id="country">
                                                            <option value="Poland">Polska</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Adres e-mail <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="email" name="email" v-model="reservation.post_data.reservation.email">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Telefon <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="phone" name="phone" v-model="reservation.post_data.reservation.phone">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="card  col-md-12">
                                    <div class="card-body">

                                        <div class="service-fields mb-3">
                                            <h3 class="heading-2">Faktura</h3>
                                            <div class="row">

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Rodzaj faktury <span class="text-danger">*</span></label>
                                                        <select class="form-control" name="invoice_type" v-model="reservation.post_data.reservation.invoice_type" id="invoice_type">
                                                            <option value="1">Faktura skrócona na osobę fizyczną</option>
                                                            <option value="2">Faktura szczegółowa na osobę fizycznę</option>
                                                            <option value="3">Faktura skrócona na firmę</option>
                                                            <option value="4">Faktura szczegółowa na firmę</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Sposób dostarczenia <span class="text-danger">*</span></label>
                                                        <select class="form-control" name="invoice_send_type" v-model="reservation.post_data.reservation.invoice_send_type" id="invoice_send_type">
                                                            <option value="1">Faktura wysłana pocztą elektroniczną na adres mailowy podany powyżej</option>
                                                            <option value="2">Faktura wysłana pocztą tradycyjną</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Nazwa firmy <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="company_name"
                                                               v-model="reservation.post_data.reservation.company_name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>NIP <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="company_nip"
                                                               v-model="reservation.post_data.reservation.company_nip">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Adres <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="company_street"
                                                               v-model="reservation.post_data.reservation.company_street">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Miasto <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="company_city"
                                                               v-model="reservation.post_data.reservation.company_city">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Kod pocztowy <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="company_postal_code"
                                                               v-model="reservation.post_data.reservation.company_postal_code">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Dodatkowe uwagi do faktury</label>
                                                        <input class="form-control" type="text" name="company_invoice_info"
                                                               v-model="reservation.post_data.reservation.company_invoice_info">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="card  col-md-12">
                                    <div class="card-body">
                                        <div class="service-fields mb-3">
                                            <h3 class="heading-2">Hotel</h3>
                                            <div class="row">

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Kraj <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_name"
                                                               v-model="reservation.post_data.hotel.hotel_name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Miasto <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_city"
                                                               v-model="reservation.post_data.hotel.city">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Nazwa <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_name"
                                                               v-model="reservation.post_data.hotel.name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Ulica i numer domu <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_street"
                                                               v-model="reservation.post_data.hotel.street">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Pełny adres <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_address"
                                                               v-model="reservation.post_data.hotel.address">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Telefon <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" name="hotel_phone"
                                                               v-model="reservation.post_data.hotel.phone">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6 mt-3">

                                <div class="card  col-md-12">

                                    <div class="card-body">
                                        <div class="service-fields mb-3">
                                            <h3 class="heading-2">Rezerwacja</h3>
                                            <div class="row">

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>Wyżywienie <span class="text-danger">*</span></label>
                                                        <select
                                                            name="mealplan"
                                                            id="mealplan"
                                                            class="form-control"
                                                            v-model="reservation.post_data.av.room.mealplan"
                                                        >
                                                            <option value="">Brak danych</option>
                                                            <option value="RO" selected="">Sam pokój</option>
                                                            <option value="BB">Śniadanie</option>
                                                            <option value="BH">Śniadanie i obiadokolacja</option>
                                                            <option value="FB">Pełne wyżywienie</option>
                                                            <option value="AI">All inclusive</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Liczba pokoi <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="number" min="1" step="1" name="roomsCount"
                                                               v-model="reservation.post_data.roomsCount" @change="changeRoomsCount">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Łóżka/pokój: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="number" min="1" step="1" name="bedsPerRoom"
                                                               v-model="reservation.post_data.bedsPerRoom">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Od: <span class="text-danger">*</span></label>

                                                        <input class="form-control" type="date" name="bedsPerRoom"
                                                               v-model="reservation.post_data.checkIn">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Do: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="date" name="bedsPerRoom"
                                                               v-model="reservation.post_data.checkOut">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="card col-md-12" v-for="(room, index) in reservation.post_data.roomsCount" :key="index">
                                    <div class="card-body">
                                        <div class="service-fields mb-3 mt-5">
                                            <h3 class="heading-2">Dane gości - Pokój {{ index + 1 }}</h3>
                                            <hr>
                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label>Liczba osób <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="number" min="1" step="1" :name="`personsCount_${index}`"
                                                               v-model="personsCountPerRoom[index]" @change="changePersonsCount(index, $event)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="reservation.post_data.reservation.rooms[index]"
                                                v-for="(guest, count) in reservation.post_data.reservation.rooms[index].persons"
                                                :key="count"
                                                class="row"
                                            >
                                                <h4 class="col-lg-12 heading-2">Gość {{ count + 1 }} </h4>

                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label>Tytuł <span class="text-danger">*</span></label>
                                                        <select class="form-control" :name="`title_guest_${count}`"
                                                                v-model="reservation.post_data.reservation.rooms[index].persons[count].title"
                                                                :id="`title_guest_${count}`">
                                                            <option value="MR">Pan</option>
                                                            <option value="MRS">Pani</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="form-group">
                                                        <label>Imię <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" :name="`firstname_guest_${count}`"
                                                               v-model="reservation.post_data.reservation.rooms[index].persons[count].firstname"
                                                               :id="`firstname_guest_${count}`">
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="form-group">
                                                        <label>Nazwisko <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" :name="`lastname_guest_${count}`"
                                                               v-model="reservation.post_data.reservation.rooms[index].persons[count].lastname"
                                                               :id="`lastname_guest_${count}`">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-12" v-if="success">>
                                <p class="w-100 alert alert-success">
                                    {{ success }}
                                </p>
                            </div>

                            <div class="col-md-12 mt-3">
                                <div class="card  col-md-12">
                                    <div class="card-body">
                                        <button class="btn btn-primary p-4 w-100" type="submit" @click.prevent="submit">Wyślij prośbę o zmianę rezerwacji</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </diV>

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import {ref} from 'vue';
import { pl } from 'date-fns/locale';
export default {

    components: {
        Loading,
        VueDatePicker,
    },

    data() {
        return {
            loading: false,
            reservation: null,
            lang: null,
            success: false,
        }
    },

    computed: {
        personsCountPerRoom() {
            return this.reservation.post_data.reservation.rooms.map(room => room.persons.length);
        }
    },

    methods: {
        get() {
            this.loading = true;
            axios.get(`/api/admin/reservation/single/${this.$route.params.id}`)
                .then(response => {
                    this.reservation = response.data.data[0];
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        submit() {
            this.loading = true;
            axios.post(`/api/admin/reservation/edit/${this.$route.params.id}`, this.reservation)
                .then(res => {
                    this.success = res.data;
                    // this.$router.push('/admin/reservations')
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                this.loading = false;
            });

        },

        changeRoomsCount(event) {
            const newRoomCount = parseInt(event.target.value, 10);
            const currentRoomCount = this.reservation.post_data.reservation.rooms.length;

            if (newRoomCount > currentRoomCount) {
                // Add new rooms with one person
                for (let i = currentRoomCount; i < newRoomCount; i++) {
                    this.reservation.post_data.reservation.rooms.push({
                        persons: [{ title: 'MR', firstname: '', lastname: '' }]
                    });
                }
            } else if (newRoomCount < currentRoomCount) {
                // Remove extra rooms
                this.reservation.post_data.reservation.rooms.splice(newRoomCount);
            }
        },

        changePersonsCount(roomIndex, event) {
            const numOfPersons = parseInt(event.target.value, 10);

            // Check if the room exists in the rooms array
            if (!this.reservation.post_data.reservation.rooms[roomIndex]) {
                // Initialize the room if it doesn't exist, with at least one person
                this.$set(this.reservation.post_data.reservation.rooms, roomIndex, {
                    persons: [{ title: 'MR', firstname: '', lastname: '' }]
                });
            }

            const room = this.reservation.post_data.reservation.rooms[roomIndex];

            // Update the persons array to match the new count
            room.persons = Array.from({ length: Math.max(numOfPersons, 1) }, (_, i) => {
                return room.persons[i] || { title: 'MR', firstname: '', lastname: '' };
            });
        }
    },

    mounted() {
        this.lang = pl;
        // this.getCountries();
    },

    created() {
        this.get();
    },

}
</script>

<style scoped>

</style>
