<template>
<div class="service-view">
                        <div class="service-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h1>Car Repair Services</h1>
                                <div class="fav-btn fav-btn-big">
                                    <a href="javascript:void(0)" class="fav-icon with-border">
                                        <i class="fas fa-heart"></i>
                                    </a>
                                </div>
                            </div>
                            <address class="service-location"><i class="fas fa-location-arrow"></i> Hanover, Maryland</address>
                            <div class="rating mapgridrating1">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <span class="d-inline-block average-rating">(5)</span>
                            </div>
                            <div class="service-cate">
                                <router-link to="/search">Automobile</router-link>
                            </div>
                        </div>
                        <div class="service-images service-carousel-view slider">
                                <div class="item">
                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                </div>
                                <div class="item">
                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                </div>
                                <div class="item">
                                    <img src="/images/services/service-02.jpg" alt="" class="img-fluid">
                                </div>
                        </div>
                        <div class="service-details mt-5">
                            <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Overview</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Services Offered</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-book-tab" data-toggle="pill" href="#pills-book" role="tab" aria-controls="pills-book" aria-selected="false">Reviews</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="card service-description">
                                        <div class="card-body">
                                            <h5 class="card-title">Service Details</h5>
                                            <p class="mb-0">Car wash is a facility used to clean the exterior and, in some cases, the interior of motor vehicles. Car washes can be self-serve, fully automated, or full-service with attendants who wash the vehicle.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</template>
<script>
	export default {
		mounted() {
        if($('.service-carousel-view').length > 0) {
        $('.service-carousel-view').slick({
            dots: true,
            autoplay:false,
            infinite: true,
            prevArrow: false,
            nextArrow: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
		},
	}
</script>