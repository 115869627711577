<template>
<div class="card invoices-tabs-card border-0">
                    <div class="card-body card-body pt-0 pb-0">
                        <div class="invoices-main-tabs">
                            <div class="row align-items-center">
                                <div class="col-lg-8 col-md-8">
                                    <div class="invoices-tabs">
                                        <ul>
                                            <li><router-link to="/admin/invoices">All Invoice</router-link></li>
                                            <li><router-link to="/admin/invoices-paid">Paid</router-link></li>
                                            <li><router-link to="/admin/invoices-overdue">Overdue</router-link></li>
                                            <li><router-link to="/admin/invoices-draft">Draft</router-link></li>
                                            <li><router-link to="/admin/invoices-recurring">Recurring</router-link></li>
                                            <li><router-link to="/admin/invoices-cancelled">Cancelled</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="invoices-settings-btn">
                                        <router-link to="/admin/invoices-settings" class="invoices-settings-icon">
                                            <i class="feather feather-settings"></i>
                                        </router-link>
                                        <router-link to="/admin/add-invoice" class="btn">
                                            <i class="feather feather-plus-circle"></i> New Invoice
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>
<script>
    export default {
        computed: {
            currentPath() {
                return this.$route.name;
            }
        },
    mounted() {
      },
    data() {
        return {
          activeClass: 'active',
        };
                //  isactive : true
      },
    }
    </script>
    <style>
    .sidebar-menu ul li a.active {
        background-color: #f58220;
        color: #fff;
    }
    </style>
